import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); 
  const [userId, setUserId] = useState("");

  const handleSendOtp = async () => {
    setError("");
    if (!email) {
      setError("Email is required");
      return;
    }
  
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        ApiUrls.USER_API + `/generate/otp?email=${encodeURIComponent(email)}`
      );
  
      // Assuming `response.data` contains the user ID
      const userIdFromResponse = response.data?.user_id;
      if (userIdFromResponse) {
        setUserId(userIdFromResponse); // Set the userId from the response
      } else {
        setError("User ID not found in response");
        return;
      }
  
      setStep(2);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to send OTP");
    } finally {
      setLoading(false);
    }
  };
  
  const handleChangePassword = async () => {
    let errors = {};
  
    if (!otp) {
      errors.otp = "OTP is required";
    }
    if (!password) {
      errors.password = "Password is required";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    }
  
    if (Object.keys(errors).length === 4) {
      setError("All fields are required");
      return;
    }
  
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
  
    if (password && password.length < 6) {
      errors.password = "Password must be at least 6 characters.";
    }
  
    if (otp && otp.length !== 6) {
      errors.otp = "OTP must be 6 digits.";
    }
  
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }
  
    setError({});
    setLoading(true);
  
    try {
      // First API call to update the password
      await axiosInstance.put(ApiUrls.USER_API + "/withotp/email/updatepassword", {
        primary_email: email,
        otp: otp,
        new_password: password,
      });
  
      // Second API call to update `password_lastupdated_at`
      if (userId) {
        await axiosInstance.put(ApiUrls.USER_API +"/" + userId, {
          password_lastupdated_at: new Date(),
        });
      } else {
        setError("User ID is not available for the second update");
        return;
      }
  
      navigate("/");
    } catch (err) {
      setError(err.response?.data?.message || "Failed to change password");
    } finally {
      setLoading(false);
    }
  };
  



  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <h1 style={styles.mainHeading}>Welcome to</h1>
        <h2 style={styles.subHeading}>Policy Management Software</h2>
        <p style={styles.description}>
          A secure repository to manage policies and procedures across their
          entire lifecycle. Develop, review, approve, distribute, and track
          every policy with confidence, knowing that only one published version
          exists.
        </p>
      </div>

      <div style={styles.rightSection}>
        <h2 style={styles.formTitle}>Policy Manager</h2>
        <div style={styles.form}>
          {step === 1 && (
            <>
              <div style={styles.inputWrapper}>
                <input
                  type="email"
                  placeholder="Email"
                  style={styles.inputField}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && <p style={styles.errorText}>{error}</p>}
              </div>
              <button
                type="button"
                style={styles.loginButton}
                onClick={handleSendOtp}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send OTP"}
              </button>
            </>
          )}

          {step === 2 && (
            <>
              <div style={styles.inputWrapper}>
                <input
                  type="text"
                  placeholder="OTP"
                  style={styles.inputField}
                  value={otp}
                  onChange={(e) =>
                    setOtp(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                {error.otp && <p style={styles.errorText}>{error.otp}</p>}
              </div>

              <div style={styles.inputWrapper}>
                <div style={styles.passwordContainer}>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    style={styles.inputField}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    style={styles.eyeIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={`fa ${
                        showPassword ?  "fa-eye" :"fa-eye-slash" 
                      }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
                {error.password && (
                  <p style={styles.errorText}>{error.password}</p>
                )}
              </div>

              <div style={styles.inputWrapper}>
                <div style={styles.passwordContainer}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    style={styles.inputField}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span
                    style={styles.eyeIcon}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <i
                      className={`fa ${
                        showConfirmPassword ?  "fa-eye" : "fa-eye-slash" 
                      }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
                {error.confirmPassword && (
                  <p style={styles.errorText}>{error.confirmPassword}</p>
                )}
              </div>

              {error && typeof error === "string" && (
                <p style={styles.errorText}>{error}</p>
              )}

              <button
                type="button"
                style={styles.loginButton}
                onClick={handleChangePassword}
                disabled={loading}
              >
                {loading ? "Changing..." : "Change Password"}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    display: "flex",
    height: "100vh",
    fontFamily:"figtree",
    color: "#335c67",
    backgroundColor: "white",
  },
  leftSection: {
    flex: 1,
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily:"figtree"
  },
  mainHeading: {
    fontSize: "1.8em",
    fontWeight: "bold",
    marginBottom: "10px",
    fontFamily:"figtree"
  },
  subHeading: {
    fontSize: "3em",
    marginBottom: "20px",
    fontFamily:"figtree"
  },
  description: {
    fontSize: "1.2em",
    color: "#66858a",
    fontFamily:"figtree"
  },
  rightSection: {
    flex: 1,
    padding: "50px",
    backgroundColor: "#e3f2fd",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "40px",
    marginRight: "120px",
    marginTop: "90px",
    height: "70%",
    maxWidth: "30%",
    border: "1px solid #rgb(193 217 228)",
    fontFamily:"figtree"
  },
  formTitle: {
    fontSize: "2em",
    color: "#335c67",
    marginBottom: "20px",
    textAlign: "center", 
    fontFamily:"figtree"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px", 
    fontFamily:"figtree"
  },
  passwordContainer: {
    position: "relative",
    width: "100%",
    fontFamily:"figtree"
  },
  inputField: {
    width: "100%",
    padding: "15px",
    fontSize: "1.2em",
    borderRadius: "8px",
    border: "1px solid #ccc",
    fontFamily:"figtree"
  },
  eyeIcon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  loginButton: {
    width: "100%",
    padding: "15px",
    fontSize: "1.2em",
    backgroundColor: "#65909a",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontFamily:"figtree"
    // maxWidth: "350px",
  },
  errorText: {
    color: "#65909a",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "left",
    margin: "9px", 
    fontFamily:"figtree"
  },
  inputWrapper: {
    width: "100%",
    marginBottom: "15px", 
    fontFamily:"figtree"
  },
};

export default ForgotPassword;
