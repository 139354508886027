import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {
  Card,
  Typography,
  CardActions,
  Button,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const FavouritesList = () => {
  useAuthHeaders();
  const [favourites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   axiosInstance
  //     .get(ApiUrls.FAVOURITE_API + "/By/UserId")
  //     .then((response) => {
  //       setFavorites(response.data);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setError("An error occurred while fetching the Favourites.");
  //       setLoading(false);
  //     });
  // }, []);
  useEffect(() => {
    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/By/UserId")
      .then((response) => {
        const filteredFavourites = response.data.filter(
          (item) => item.is_favourite === 1
        );
        setFavorites(filteredFavourites);
        setLoading(false);
      })
      .catch(() => {
        setError("An error occurred while fetching your Favorites.");
        setLoading(false);
      });
  }, []);

  const toggleStar = (assignmentId) => {
    axiosInstance
      .put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, {
        is_favourite: false,
      })
      .then(() => {
        setFavorites((prevFavorites) =>
          prevFavorites.filter((item) => item.assignment_id !== assignmentId)
        );
      })
      .catch(() => {
        setError("Failed to remove the favourite. Please try again.");
      });
  };

  const renderFileIcon = (fileType) => {
    // if (fileType === "pdf") {
    //   return <PictureAsPdfIcon sx={{ fontSize: 40, color: "#d32f2f" }} />;
    // } else if (fileType === "doc") {
    //   return <InsertDriveFileIcon sx={{ fontSize: 40, color: "#1976d2" }} />;
    // } else {
    //   return <InsertDriveFileIcon sx={{ fontSize: 40, color: "#9e9e9e" }} />;
    // }
    return <PictureAsPdfIcon sx={{ fontSize: 40, color: "#d32f2f" }} />;
  };

  const handleCardClick = (policy) => {
    const policyId = policy.policy_id;
    const assignmentId = policy.assignment_id;
    axiosInstance
      .put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, {
        last_visited: new Date(),
      })
      .then(() => {
        const data = { routeName: "Favorites", routeUrl: "favourites", nextRouteName:"Policy Details" };
        navigate( `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`, { state: data });
      })
      .catch(() => {
        const data = { routeName: "Favorites", routeUrl: "favourites", nextRouteName:"Policy Details" };
        navigate( `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`, { state: data });
      });
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px 13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree" }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily:"figtree"
            }}
          >
            Favorite
          </li>
        </ol>
      </div>
      <Typography
        variant="h4"
        sx={{
          color: "#3f6677",
          mb: 4,
          ml: 4,
          // fontWeight: "bold",
          fontSize: { xs: "24px", sm: "28px", md: "32px" },
          textAlign: { xs: "center", md: "left" },
          fontFamily: "figtree",
        }}
      >
        Favorites
      </Typography>

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {error && !loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            color: "error.main", 
            textAlign: "center",
            fontFamily: "figtree",
          }}
        >
          <Typography variant="h6" sx={{ fontFamily: "figtree" }}>
            {error}
          </Typography>
        </Box>
      )}

      {!loading && !error && favourites.length === 0 && (
        <Box
          sx={{
            display: "flex",
            direction:"row",
            justifyContent:"center",
            minHeight: "200px",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontFamily: "figtree", color:"#1D3557" }}>
            No favorites added.
          </Typography>
        </Box>
      )}

      {!loading && !error && favourites.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            padding: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {favourites.map((policy) => (
            <Card
              key={policy.assignment_id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                fontFamily: "figtree",
              }}
              onClick={() => handleCardClick(policy)}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                }}
              >
                {renderFileIcon(policy.file_type)}
              </Box>

              {/* Content Section */}
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#1D3557",
                    marginBottom: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  {policy.policy_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="#1D3557"
                  sx={{
                    marginBottom: "8px",
                    width: "82%",
                    fontFamily: "figtree",
                  }}
                >
                  {policy.policy_description}
                </Typography>
                <Typography variant="body2" color="#1D3557">
                  <strong>Expiration Date:</strong>{" "}
                  {new Date(policy.expiration_date).toLocaleDateString(
                    "en-US",
                    {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    }
                  )}
                </Typography>
              </Box>

              {/* Star Icon Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  marginLeft: "16px",
                }}
              >
                {/* <Tooltip title="Remove from Favourite">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleStar(policy.assignment_id);
                    }}
                  >
                    <StarIcon sx={{ color: "#fdd835" }} />
                  </IconButton>
                </Tooltip> */}
                <CardActions sx={{ padding: 0 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ fontFamily: "figtree" }}
                  >
                    View Details
                  </Button>
                  <Button
                    variant="contained"
                    color={policy.is_favourite === 1 ? "success" : "error"} // Color changes based on favorite status
                    sx={{
                      fontFamily: "figtree",
                      backgroundColor:
                        policy.is_favourite === 1 ? "#5a8a9a33" : "#1D3557", // Override default colors
                      color: policy.is_favourite === 1 ? "#1D3557" : "#fff",
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      toggleStar(policy.assignment_id); // Use assignment_id for the toggle function
                    }}
                  >
                    {policy.is_favourite === 1
                      ? "Unmark Favorite"
                      : "Mark as Favorite"}{" "}
                    {/* Dynamic text */}
                  </Button>
                </CardActions>
              </Box>
            </Card>
          ))}
        </Box>
      )}
    </div>
  );
};

export default FavouritesList;
