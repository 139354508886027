// import React, { useEffect, useRef, useState } from 'react';
// import WebViewer from '@pdftron/webviewer';
// import { axiosInstance } from '../../Utils/axiosInstance';
// import ApiUrls from '../../Configurations/ConfigurationsApiUrls';
// import { useLocation } from 'react-router-dom';

// const PDFCompareViewer = () => {
//   const viewerRef = useRef(null);
//   const [pdfUrls, setPdfUrls] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [instance, setInstance] = useState(null);

//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const policyId = searchParams.get("policy_id");

//   // Helper function to check if URL is a PDF
//   const isPdfUrl = (url) => {
//     return url.toLowerCase().includes('.pdf');
//   };

//   // Fetch and filter PDF URLs
//   useEffect(() => {
//     setIsLoading(true);
//     axiosInstance.get(ApiUrls.FILESTORAGE_API + "/policydocument/paths/" + policyId)
//       .then((response) => {
//         // Filter to only include PDF files
//         const pdfFiles = response.data.filter(url => isPdfUrl(url));
//         setPdfUrls(pdfFiles);
        
//         if (pdfFiles.length === 0) {
//           setError("No PDF versions found to compare");
//         }
//       })
//       .catch((err) => {
//         setError("Error while fetching Files. Please try again");
//         setIsLoading(false);
//       });
//   }, [policyId]);

//   // Initialize WebViewer
//   useEffect(() => {
//     const initializeViewer = async () => {
//       if (!viewerRef.current || pdfUrls.length === 0) return;

//       try {
//         const webViewerInstance = await WebViewer(
//           {
//             path: '/webviewer/lib',
//             licenseKey: 'demo:1736170268669:7eb0a8a103000000005cfb27c73bf63d4ab6b6c938f0cc3915907c1ee9',
//             fullAPI: true,
//             initialDoc: pdfUrls[0],
//           },
//           viewerRef.current
//         );

//         setInstance(webViewerInstance);
//       } catch (error) {
//         console.error('Error initializing WebViewer:', error);
//         setError('Failed to initialize PDF viewer');
//         setIsLoading(false);
//       }
//     };

//     initializeViewer();

//     return () => {
//       if (instance) {
//         instance.UI.closeDocument();
//       }
//     };
//   }, [pdfUrls]);

//   // Handle document loading and comparison
//   useEffect(() => {
//     const setupViewer = async () => {
//       if (!instance || !pdfUrls.length) return;

//       const { UI, Core } = instance;

//       try {
//         if (pdfUrls.length >= 2) {
//           // Enable comparison features
//           UI.enableFeatures([UI.Feature.MultiViewerMode]);
//           UI.enableFeatures([UI.Feature.SideBySideView]);
//           UI.enableFeatures([UI.Feature.ComparePages]);

//           // Enter multi-viewer mode
//           UI.enterMultiViewerMode();

//           // Get document viewers after entering multi-viewer mode
//           const viewers = Core.getDocumentViewers();
//           const [leftViewer, rightViewer] = viewers;

//           if (leftViewer && rightViewer) {
//             // Get the last two PDF URLs
//             const lastTwoPdfUrls = pdfUrls.slice(-2);
            
//             try {
//               // Load documents
//               await Promise.all([
//                 leftViewer.loadDocument(lastTwoPdfUrls[0]),
//                 rightViewer.loadDocument(lastTwoPdfUrls[1])
//               ]);

//               // Start comparison
//               await leftViewer.startSemanticDiff(rightViewer);
//               console.log('Comparison started successfully');
//             } catch (loadError) {
//               console.error('Error loading documents:', loadError);
//               setError('Error loading documents for comparison');
//             }
//           }
//         } else {
//           // Single document view
//           const documentViewer = Core.getDocumentViewer();
//           await documentViewer.loadDocument(pdfUrls[0]);
//         }

//         setIsLoading(false);
//       } catch (error) {
//         console.error('Error setting up viewer:', error);
//         setError('Error setting up document viewer');
//         setIsLoading(false);
//       }
//     };

//     setupViewer();
//   }, [instance, pdfUrls]);

//   if (pdfUrls.length === 0 && !isLoading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <p className="text-lg text-gray-600">No PDF versions available to compare.</p>
//       </div>
//     );
//   }

//   return (
//     <div className="MyComponent relative">
//       {pdfUrls.length === 1 && (
//         <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
//           Only one PDF version available. Showing the current version.
//         </div>
//       )}
//       <div className="webviewer" ref={viewerRef} style={{ height: "90vh" }}></div>
//       {isLoading && (
//         <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-4 rounded-lg shadow-lg text-center">
//             <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500 mx-auto mb-2"></div>
//             <p className="text-gray-700">Loading {pdfUrls.length > 1 ? 'documents' : 'document'}, please wait...</p>
//           </div>
//         </div>
//       )}
//       {error && (
//         <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-4 rounded-lg shadow-lg text-center">
//             <p className="text-red-600">{error}</p>
//             <button 
//               onClick={() => setError(null)} 
//               className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//             >
//               Dismiss
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PDFCompareViewer;

import React, { useEffect, useRef, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import { useLocation } from 'react-router-dom';
import { axiosInstance } from '../../Utils/axiosInstance';
import ApiUrls from '../../Configurations/ConfigurationsApiUrls';

const PDFCompareViewer = () => {
  const viewerRef = useRef(null);
  const [pdfUrls, setPdfUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policy_id");

  // useEffect(() => {
  //   let isMounted = true;
  //   setIsLoading(true);
  //   axiosInstance.get(ApiUrls.FILESTORAGE_API +"/policydocument/paths/"+ 155)
  //     .then((response) => {
  //       // Filter to only include PDF files
  //       const pdfFiles = response.data;
  //       setPdfUrls(response.data);
  //       const pdfsAre = pdfUrls.slice(-2)
  //       console.log(JSON.stringify(pdfsAre[0]),"pdf link 1 is")
  //       console.log(JSON.stringify(pdfsAre[1]),"pdf link 2 is")
  //       if (pdfFiles.length === 0) {
  //         setError("No PDF versions found to compare");
  //       }
  //     })
  //     .catch((err) => {
  //       setError("Error while fetching Files. Please try again");
  //       setIsLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    

    const fetchPdfUrls = async () => {
      try {
        const response = await axiosInstance.get(
          `${ApiUrls.FILESTORAGE_API}/submiturls/policydocument/paths/${policyId}`
        );
        const pdfFiles = response.data;

        // Filter only the last two URLs
        const lastTwoUrls = pdfFiles.slice(-2);
        if (lastTwoUrls.length < 2) {
         setError("Not enough PDF versions to compare.");
        }

        initializeViewer(lastTwoUrls);
      } catch (err) {
        console.error('Error fetching PDF URLs:', err);
        setError('Error while fetching files. Please try again.');
        setIsLoading(false);
      }
    };


    const initializeViewer = async (urls) => {
      if (!viewerRef.current || !isMounted) return;

      try {
        const instance = await WebViewer(
          {
            path: '/webviewer/lib',
            licenseKey: 'demo:1736170268669:7eb0a8a103000000005cfb27c73bf63d4ab6b6c938f0cc3915907c1ee9',
            fullAPI: true,
          },
          viewerRef.current
        );

        const { UI, Core } = instance;

        // Enable Multi-Viewer Mode
        // UI.enableFeatures([UI.Feature.SideBySideView]);
        UI.enableFeatures([UI.Feature.MultiViewerMode]);
        UI.enableFeatures([UI.Feature.ComparePages]);

        // Enter Multi-Viewer Mode
        UI.enterMultiViewerMode();

        // Listen for when the multi-viewer is ready
        UI.addEventListener(UI.Events.MULTI_VIEWER_READY, async () => {
          try {
            const [documentViewerOne, documentViewerTwo] = Core.getDocumentViewers();
            const lastTwoPdfUrls = pdfUrls.slice(-2);
            // Add error listeners to both viewers
            documentViewerOne.addEventListener('documentLoadFailure', (error) => {
              console.error('Document 1 load failure:', error);
              if (isMounted) {
                setError('Failed to load first document');
                setIsLoading(false);
              }
            });

            documentViewerTwo.addEventListener('documentLoadFailure', (error) => {
              console.error('Document 2 load failure:', error);
              if (isMounted) {
                setError('Failed to load second document');
                setIsLoading(false);
              }
            });

            // Create a promise for loading both documents
            const loadDoc1Promise = documentViewerOne
              .loadDocument (urls[0])
              .catch(error => {
                setError('Failed to load first document');
              });

            const loadDoc2Promise = documentViewerTwo
              .loadDocument(urls[1])
              .catch(error => {
               setError('Failed to load second document');
              });

            await Promise.all([loadDoc1Promise, loadDoc2Promise]);

            if (isMounted) {
              // Start the comparison after both documents are loaded
              await documentViewerOne.startSemanticDiff(documentViewerTwo);
              console.log('Comparison started between the two PDFs');
              setIsLoading(false);
            }
          } catch (error) {
            console.error('Error in MULTI_VIEWER_READY handler:', error);
            if (isMounted) {
              setError('Error while loading file, Please try again');
              setIsLoading(false);
            }
          }
        });
      } catch (error) {
        console.error('Error initializing WebViewer:', error);
        if (isMounted) {
          setError('Failed to initialize PDF viewer');
          setIsLoading(false);
        }
      }
    };

    fetchPdfUrls();

    return () => {
      isMounted = false;
    };
  }, []);



  return (
    <div className="MyComponent relative">
      <div className="header">
       
        {!error && isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg text-center">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500 mx-auto mb-2"></div>
              <p className="text-gray-700">Loading documents, please wait...</p>
            </div>
          </div>
        )}

        {error && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg text-center">
              <p className="text-red-600">{error}</p>
            </div>
          </div>
        )}
      </div>

      { !error && (
        <div className="webviewer" ref={viewerRef} style={{ height: "95vh" }}></div>
      )}
    </div>
  );
};

export default PDFCompareViewer;