// import React, { useEffect, useState } from "react";
// import Header from "../../Components/Header/Header";
// import Navigation from "../../Components/NavigationBar/Navigation";
// import {
//   Box,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
// } from "@mui/material";
// import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
// import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

// // Sample report data
// const reportData = [
//   {
//     id: 1,
//     policyName: "Data Privacy Policy",
//     acknowledgments: 120,
//     completionRate: 90,
//     competencies: 80,
//     complianceStatus: "On Track",
//     dueDate: "2024-10-31",
//   },
//   {
//     id: 2,
//     policyName: "Code of Conduct",
//     acknowledgments: 150,
//     completionRate: 75,
//     competencies: 85,
//     complianceStatus: "Needs Attention",
//     dueDate: "2024-11-15",
//   },
//   {
//     id: 3,
//     policyName: "Anti-Bribery Policy",
//     acknowledgments: 100,
//     completionRate: 95,
//     competencies: 90,
//     complianceStatus: "Compliant",
//     dueDate: "2024-12-01",
//   },
// ];

// const formatDate = (date) => {
//   if (!date) return "-";
//   const parsedDate = new Date(date);
//   const day = String(parsedDate.getDate()).padStart(2, "0");
//   const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
//   const year = parsedDate.getFullYear();
//   return `${month}/${day}/${year}`;
// };

// const Reports = () => {
//   useAuthHeaders();
//   const [policy, setPolicy] = useState([]);

//   useEffect(() => {
//     axiosInstance
//       .get(ApiUrls.ASSIGNMENT_API + "/report/completed/assignment")
//       .then((response) => setPolicy(response.data))
//       .catch((error) => console.error("Error fetching recents:", error));
//   }, []);

//   return (
//     <div>
//       <Typography variant="h4" sx={{ fontWeight: "bold" }}>
//         Reports
//       </Typography>

//       <Paper
//         sx={{
//           borderRadius: "25px",
//           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
//           mt: 5,
//         }}
//       >

//         <TableContainer component={Box}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell sx={{ fontWeight: "bold" }}>Policy</TableCell>

//                 <TableCell sx={{ fontWeight: "bold" }} align="center">
//                   Completion Rate
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }} align="center">
//                   Policy Start Date
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold" }} align="center">
//                   Policy End Date
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {policy.map((policy) => (
//                 <TableRow key={policy.id}>
//                   <TableCell>{policy.policy_name}</TableCell>

//                   <TableCell align="center">
//                     <Box
//                       sx={{
//                         position: "relative",
//                         display: "inline-flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                       }}
//                     >

//                       <CircularProgress
//                         variant="determinate"
//                         value={100}
//                         size={45}
//                         thickness={5}
//                         sx={{
//                           color: "#e0e0e0",
//                           position: "absolute",
//                         }}
//                       />

//                       {/* Foreground Circle */}
//                       <CircularProgress
//                         variant="determinate"
//                         value={policy.completedPercentage}
//                         size={45}
//                         thickness={5}
//                         sx={{
//                           color: "green",
//                         }}
//                       />

//                       {/* Centered Percentage */}
//                       <Box
//                         sx={{
//                           position: "absolute",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                         }}
//                       >
//                         <Typography
//                           variant="caption"
//                           component="div"
//                           sx={{ fontSize: "10px", color: "#000" }}
//                         >
//                           {`${policy.completedPercentage}`}
//                         </Typography>
//                       </Box>
//                     </Box>
//                   </TableCell>

//                   <TableCell align="center">
//                     {formatDate(policy.effective_date)}
//                   </TableCell>
//                   <TableCell align="center">
//                     {formatDate(policy.target_date)}
//                   </TableCell>

//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Paper>
//     </div>
//   );
// };

// export default Reports;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  Alert,
} from "@mui/material";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { NotificationsActive } from "@mui/icons-material";
import { Link } from "react-router-dom";

const formatDate = (date) => {
  if (!date) return "-";
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${month}/${day}/${year}`;
};

const Reports = () => {
  useAuthHeaders();
  const [policy, setPolicy] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedOption, setSelectedOption] = useState("report");
  const [activeButton, setActiveButton] = useState("");
  const [hold, setHold] = useState(0);
  const [approved, setApproved] = useState(0);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const statusMapping = {
    Director: {
      onHold: ["Assigned to Director", "Approved by Quality Director"],
      approved: [
        "Assigned to Senior Leader",
        "Assigned to Quality Director",
        "Published",
      ],
    },
    SeniorLeader: {
      onHold: ["Assigned to Senior Leader"],
      approved: ["Assigned to Quality Director", "Published"],
    },
    QualityDirector: {
      onHold: ["Assigned to Quality Director"],
      approved: ["Approved by Quality Director", "Published"],
    },
  };

  useEffect(() => {
    const fetchPolicies = () => {
      const apiUrl =
        selectedOption === "report"
          ? ApiUrls.ASSIGNMENT_API + "/report/completed/assignment"
          : ApiUrls.POLICY_API + "/role/report/status/" + selectedOption;

      axiosInstance
        .get(apiUrl)
        .then((response) => {
          if (selectedOption === "report") {
            setPolicy(Array.isArray(response.data) ? response.data : []);
          } else {
            setHold(response.data.holdpolicies_count);
            setApproved(response.data.approved_count);
            setPolicies(
              Array.isArray(response.data.Totalpolicies)
                ? response.data.Totalpolicies
                : []
            );
          }
        })
        .catch((error) => console.error("Error fetching reports:", error));
    };

    fetchPolicies();
  }, [selectedOption]);

  const handleFilterwithStatus = (statusArray, buttonType) => {
    setActiveButton(buttonType);
    axiosInstance.get(ApiUrls.POLICY_API + "/by/admin/statuses", {
        params: { statuses: statusArray, },
      })
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => console.error("Error fetching reports:", error));
  };

  const roleIdMapping = {
    Director: 2,
    SeniorLeader: 3,
    QualityDirector: 4,
  };

  const handleNotificationClick = (item) => {
    const roleId = roleIdMapping[selectedOption];
    const policyId = item.policy_id;

    if (policyId && roleId) {
      axiosInstance
        .post(ApiUrls.POLICY_API + "/onhold/sendmails", { policyId, roleId })
        .then((response) => {
          setAlert({
            show: true,
            message: "Notifications Send Successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error Sendin Notifications",
            severity: "error",
          });
        });
    } else {
      console.error("Invalid policyId or roleId");
    }
  };

  const renderRows = (item, index) => {
    // const serialNumber = index + 1;

    if (selectedOption === "report") {
      return (
        <TableRow key={item.policy_id || item.id}>
          {/* <TableCell align="center">{serialNumber}</TableCell> */}
          <TableCell >{item.policy_name}</TableCell>
          <TableCell >{item.department_name}</TableCell>
          <TableCell >
            {formatDate(item.effective_date)}
          </TableCell>
          <TableCell >{formatDate(item.target_date)}</TableCell> 
          <TableCell >
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center", 
                marginLeft:"40px"
              }}
            >
              <CircularProgress
                variant="determinate"
                value={100}
                size={45}
                thickness={5}
                sx={{
                  color: "#e0e0e0",
                  position: "absolute",
                }}
              />
              <CircularProgress
                variant="determinate"
                value={item.completedPercentage || 0}
                size={45}
                thickness={5}
                sx={{
                  color: "green",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  sx={{ fontSize: "10px", color: "#000" }}
                >
                  {`${item.completedPercentage || 0}%`}
                </Typography>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow key={item.policy_id || item.id}>
        {/* <TableCell align="center">{serialNumber}</TableCell> */}
        <TableCell >{item.policy_name}</TableCell>
        <TableCell >{item.department_name}</TableCell>
        <TableCell >
          {formatDate(item.director_assign_timestamp)}
        </TableCell>
        <TableCell >{item.policy_status || "-"}</TableCell>
        {activeButton === "onHold" ? (
          <> 
          
            <TableCell > {item.days_on_hold || "N/A"} Days In Hold </TableCell>
            <TableCell>
              <Tooltip title="Send Remainder Alert" arrow>
                <NotificationsActive
                  sx={{ fontSize: 25, marginLeft: 4, color: "#333" }}
                  onClick={() => handleNotificationClick(item)}
                />
              </Tooltip>
            </TableCell>
          </>
        ) : (
          <TableCell >
            {formatDate(item.director_approve_timestamp)}
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div>
        <div
          style={{
            backgroundColor: "#f3f6f9",
            padding: "0px 20px",
          }}
        >
          <ol
            style={{
              display: "flex",
              listStyle: "none",
              padding: 0,
              margin: "2px 0 5px -13px",
              fontSize: "14px",
              color: "#6c757d",
            }}
          >
            <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "Figtree" }}>
              <Link
                to="/home"
                style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
                onMouseOver={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              >
                Home
              </Link>
            </li>
            <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "Figtree" }}>/</li>
            <li
              style={{
                display: "inline-block",
                color: "#3f6677",
                fontWeight: "bold",
                fontFamily: "Figtree" 
              }}
            >
              Reports
            </li>
          </ol>
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: "#3f6677", fontFamily: "Figtree" }}
          >
            Reports
          </Typography>

          <FormControl sx={{ minWidth: 200 , fontFamily: "Figtree" }}>
            <InputLabel id="select-report-label">Select Report</InputLabel>
            <Select
              labelId="select-report-label"
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setActiveButton("");
              }}
              label="Select Report"
              sx={{ fontFamily: "Figtree" }} 
            >
              <MenuItem value="report" sx={{ fontFamily: "Figtree" }}>Published</MenuItem>
              <MenuItem value="Director" sx={{ fontFamily: "Figtree" }}>Director</MenuItem>
              <MenuItem value="SeniorLeader" sx={{ fontFamily: "Figtree" }}>Senior Leader</MenuItem>
              <MenuItem value="QualityDirector" sx={{ fontFamily: "Figtree" }}>Quality Director</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 3,
          }}
        >
          {selectedOption !== "report" && (
            <>
              <div
                style={{
                  height: "63px",
                  width: "300px",
                  background: activeButton === "onHold" ?  "#5a8a9a33" : "#fff",
                  border: "1px solid #ddd",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  paddingTop: "25px",
                  borderRadius: "6px",
                  fontFamily: "Figtree"
                }}
                onClick={() =>
                  handleFilterwithStatus(
                    statusMapping[selectedOption].onHold,
                    "onHold"
                  )
                }
              >
                <h2 style={{ marginTop: -1, fontSize: "16px", fontFamily: "Figtree" , color: "#1D3557", }}>
                  Policies On Hold {hold}
                </h2>
              </div>
              <div
                style={{
                  height: "60px",
                  width: "300px",
                  background: activeButton === "approved" ? "#5a8a9a33" : "#fff",
                  border: "1px solid #ddd",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  paddingTop: "25px",
                  borderRadius: "6px",
                  fontFamily: "Figtree"
                }}
                onClick={() =>
                  handleFilterwithStatus(
                    statusMapping[selectedOption].approved,
                    "approved"
                  )
                }
              >
                <h2 style={{ marginTop: -1, fontSize: "16px",   fontFamily: "Figtree" , color: "#1D3557", }}>
                  Policies Approved {approved}
                </h2>
              </div>
            </>
          )}
        </Box>

        <Paper
          sx={{
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            mt: 4,
          }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" , fontFamily: "Figtree",color:"#1D3557",  backgroundColor: "#5a8a9a33",  fontSize:"16px"}} >
                    Policy Name
                  </TableCell>
                  
                  {selectedOption === "report" ? (
                    <>
                      <TableCell sx={{ fontWeight: "bold" , fontFamily: "Figtree",color:"#1D3557",  backgroundColor: "#5a8a9a33",  fontSize:"16px"}} >
                    Department Name
                  </TableCell>
                      <TableCell sx={{ fontWeight: "bold", fontFamily: "Figtree", color:"#1D3557",  backgroundColor: "#5a8a9a33",fontSize:"16px" }} >
                        Policy Start Date
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", fontFamily: "Figtree", color:"#1D3557",  backgroundColor: "#5a8a9a33",fontSize:"16px" }} >
                        Policy End Date
                      </TableCell> 
                      <TableCell sx={{ fontWeight: "bold" , fontFamily: "Figtree", color:"#1D3557",  backgroundColor: "#5a8a9a33", fontSize:"16px"}} >
                        Completion Rate
                      </TableCell>
                    </>
                  ) : (
                    <> 
                     <TableCell sx={{ fontWeight: "bold" , fontFamily: "Figtree",color:"#1D3557",  backgroundColor: "#5a8a9a33",  fontSize:"16px"}} >
                    Department Name
                  </TableCell>
                      <TableCell sx={{ fontWeight: "bold", fontFamily: "Figtree", color:"#1D3557",  backgroundColor: "#5a8a9a33",fontSize:"16px" }} >
                        Assigned Date
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", fontFamily: "Figtree", color:"#1D3557",  backgroundColor: "#5a8a9a33",fontSize:"16px" }} >
                        Policy Status
                      </TableCell>
                      {activeButton === "onHold" ? (
                        <>
                          <TableCell sx={{ fontWeight: "bold", fontFamily: "Figtree", color:"#1D3557", backgroundColor: "#5a8a9a33",fontSize:"16px" }} >
                            Days in Hold
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold", fontFamily: "Figtree", color:"#1D3557", backgroundColor: "#5a8a9a33", fontSize:"16px"}}>
                            Actions
                          </TableCell>
                        </>
                      ) : (
                        <TableCell sx={{ fontWeight: "bold", fontFamily: "Figtree", color:"#1D3557" ,  backgroundColor: "#5a8a9a33",fontSize:"16px"}} >
                          Approved Date
                        </TableCell>
                      )}
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(selectedOption === "report" ? policy : policies).map(
                  renderRows
                )}
                {(selectedOption === "report" ? policy : policies).length ===
                  0 && (
                  <TableRow>
                    <TableCell colSpan={5}  sx={{ fontFamily: "Figtree", color:"#1D3557" }}>
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default Reports;
