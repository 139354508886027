import React, { useEffect, useState } from "react";
import {  Box,  Typography,  Paper,  TextField,  MenuItem,  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Select,
  InputLabel,
  FormControl,
  Chip,
  OutlinedInput,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import PolicyImport from "./PolicyBulk";

const formatDate = (date) => {
  if (!date) return "-";
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${month}/${day}/${year}`;
};

const AssignCompetencies = () => {
  useAuthHeaders();
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedCompetency, setSelectedCompetency] = useState("");
  const [user, setUser] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [assignment, setAssignments] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.USER_API)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    axiosInstance
      .get(ApiUrls.POLICY_API)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API)
      .then((response) => {
        setAssignments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleAssign = () => {
    if (!selectedEmployees || !selectedCompetency) {
      setAlert({
        message: "Please select both  employee and a competency.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
      return;
    }

    const requestBody = {
      policy_id: selectedCompetency.policy_id,
      userids: selectedEmployees,
      type_id: selectedCompetency.type_id,
      location_id: selectedCompetency.location_id,
      department_id: selectedCompetency.department_id,
      assigned_date: new Date(),
      target_date: selectedCompetency.expiration_date,
      effective_date: selectedCompetency.effective_date,
      policy_description: selectedCompetency.policy_description,
      created_at: new Date(),
      created_by: sessionStorage.getItem("loginUserId"),
      assessment_status: "NotStarted",
      assignment_status: "Assigned",
    };

    axiosInstance
      .post(ApiUrls.ASSIGNMENT_API + "/multiple/create", requestBody)
      .then((response) => {
        // const assignedEmployee = user.find(
        //   (emp) => emp.userids === selectedEmployees
        // );
        // const newAssignment = {
        //   userids: selectedEmployees,
        //   first_name: assignedEmployee?.first_name || "Unknown",
        //   policy_name: selectedCompetency.policy_name,
        //   assignment_status: "Assigned",
        // };

        setAssignments(response.data);
        setAlert({
          message: "Assign Competence successfully.",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      })
      .catch((error) => {
        setAlert({
          message: "Assign Competence Failed.",
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      });
  };   

  const handleDelete = (assignmentId) => {
    axiosInstance
      .delete(ApiUrls.ASSIGNMENT_API+ "/" +assignmentId)
      .then(() => {
        setAssignments((prev) => prev.filter((item) => item.assignment_id !== assignmentId));
        setAlert({ message: "Unassigned successfully.", severity: "success", show: true });
      })
      .catch(() => setAlert({ message: "Unassign failed.", severity: "error", show: true }));
  };
  
  

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleFilterModelChange = debounce((newModel) => {
    setFilterModel(newModel);
  }, 100);

  const columns = [
    {
      field: "first_name",
      headerName: <strong> Employee Name</strong>,
      width: 150,
    },
    {
      field: "policy_name",
      headerName: <strong> Assigned Policy</strong>,
      width: 250,
    },
    {
      field: "assigned_date",
      headerName: <strong> Assigned date</strong>,
      width: 150, 
      valueGetter: (params) => {
        const assignedDate = formatDate(params);
        if (!assignedDate) return "-";
        
        const formattedDate = formatDate(assignedDate);
        const assignDate = new Date(assignedDate);
    
        if (isNaN(assignDate)) return "-";
    
        
        // Format the output as "mm/dd/yyyy (days)"
        return `${formattedDate} `;
      }, 
    },
    {
      field: "target_date",
      headerName: <strong>Target Date</strong>,
      width: 150, 
      valueGetter: (params) => {
        const targetedDate = formatDate(params);
        if (!targetedDate) return "-";
        
        const formattedDate = formatDate(targetedDate);
        const targetDate = new Date(targetedDate);
    
        if (isNaN(targetDate)) return "-";
    
        
        // Format the output as "mm/dd/yyyy (days)"
        return `${formattedDate} `;
      }, 
    },
    {
      field: "completed_date",
      headerName: <strong> Completed Date</strong>,
      width: 150, 
      valueGetter: (params) => {
        const completedDate = formatDate(params);
        if (!completedDate) return "-";
        
        const formattedDate = formatDate(completedDate);
        const completDate = new Date(completedDate);
    
        if (isNaN(completDate)) return "-";
    
        
        // Format the output as "mm/dd/yyyy (days)"
        return `${formattedDate} `;
      }, 
    },
    {
      field: "assessment_status",
      headerName: <strong>Assessment Status</strong>,
      width: 200,
    }, 
    {
      field: "actions",
      headerName: <strong>UnAssign</strong>,
      width: 200,
      renderCell: (params) => (
        <div>
          <Button 
          onClick={() => handleDelete(params.row.assignment_id)}
            sx={{ 
              marginTop: "10px",
              fontSize: "12px",
              marginRight: "9px",
              marginBottom: "10px",
              width: "100px",
              backgroundColor: "#5a8a9a33",
              color: "#1D3557",
              fontFamily: "figtree",
              fontWeight: "bold",
              border: "2px solid transparent",
              '&:hover': {
                backgroundColor: "#5a8a9a66",
                border: "2px solid #1D3557",
              },
            }}
          >
            UnAssign
          </Button>
        </div>
      ),
    }
    
  ];

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
            fontFamily: "Figtree",
          }}
        >
          {alert.message}
        </Alert>
      )}

      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -17px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px" }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
            }}
          >
            Assign Policy
          </li>
        </ol>
      </div>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          sx={{ color: "#3f6677", fontFamily: "Figtree" }}
        >
          Assign Policy
        </Typography>
      </Box>

      <Paper
        sx={{
          borderRadius: "8px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ mb: 4, display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel
              id="select-employees-label"
              sx={{ fontFamily: "Figtree" }}
            >
              Select Employees
            </InputLabel>
            <Select
              labelId="select-employees-label"
              id="employees"
              multiple
              value={selectedEmployees}
              onChange={(e) => setSelectedEmployees(e.target.value)}
              input={<OutlinedInput label="Select Employees" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8, // Adjust dropdown height if needed
                    width: 250,
                    fontFamily: "Figtree",
                  },
                },
              }}
            >
              {user.map((employee) => (
                <MenuItem key={employee.user_id} value={employee.user_id}>
                  {employee.first_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            select
            label="Select Policy"
            value={selectedCompetency}
            onChange={(e) => setSelectedCompetency(e.target.value)}
            fullWidth
            sx={{ fontFamily: "Figtree" }}
          >
            {policies.length > 0 ? (
              policies.map((competency) => (
                <MenuItem key={competency.policy_id} value={competency}>
                  {competency.policy_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No policy available</MenuItem>
            )}
          </TextField>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleAssign}
            startIcon={<PersonAddIcon />}
            sx={{
              fontFamily: "Figtree",
              backgroundColor: "#3f6677",
              color: "#fff",
            }}
          >
            Assign Policy
          </Button>
        </div>
      </Paper>

      <div
        style={{
          marginTop: "60px",
          marginBottom: "10px",
          paddingBottom: "30px",
        }}
      >
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "30px",
            fontSize: "32px",

            fontFamily: "figtree",
            marginLeft: "13px",
          }}
        >
          Assign Policy In Bulk
        </h4>
        <PolicyImport />
      </div>

      <Box sx={{ mb: 4, mt: 5 }}>
        <Typography
          variant="h4"
          sx={{ color: "#3f6677", fontFamily: "Figtree" }}
        >
          Assigned Policy
        </Typography>
      </Box>
      {/* <Paper
        sx={{
          borderRadius: "15px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      > */}
      <DataGrid
        rows={assignment}
        columns={columns}
        initialState={{
          ...policies.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
        filterModel={filterModel} 
        onFilterModelChange={handleFilterModelChange}
        // slots={{ toolbar: GridToolbarQuickFilter }}
        getRowId={(row) => row.assignment_id}
        classes={{
          overlayWrapper: "customOverlayWrapper",
        }}
        sx={{
          "& .customOverlayWrapper ": {
            height: "63px !important",
            fontFamily: "Figtree",
          },
          fontSize: "14px",
          fontFamily: "Figtree",
          borderRadius: "8px",
          boxShadow: 2,
          color: "#1D3557",
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: "#5a8a9a33",
            color: "#1D3557",
            fontFamily: "Figtree",
            fontWeight: "bold",
            fontSize: "16px",
          },
          "& .MuiDataGrid-cell": {
            backgroundColor: "white",
            color: "#1D3557",
            fontFamily: "Figtree",
            fontSize: "14px",
          },
        }}
        components={{
          Toolbar: () => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <GridToolbarQuickFilter
                classes={{
                  toolbarQuickFilter: "customtoolbarQuickFilter",
                }}
                sx={{
                  position: "absolute",
                  right: 300,
                  top: "-30px",
                  width: "35%",
                }}
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
              />
            </div>
          ),

          NoRowsOverlay: () => (
            <div
              style={{
                position: "sticky",
                top: "50%",
                transform: "translate(-50%, 0%)",
                textAlign: "center",
                marginLeft: "45%",
              }}
            >
              No data found
            </div>
          ),
        }}
      />
      {/* </Paper> */}
    </div>
  );
};

export default AssignCompetencies;
