import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Button, Input, Link, MenuItem, TextField } from "@mui/material";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { routers } from "../../Configurations/configurationUI";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const PolicyImport = () => {
  useAuthHeaders();
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [policies, setPolicies] = useState([]); 
  const [selectedCompetency, setSelectedCompetency] = useState("");
  const key = "excelbulkupload/Assignment.csv";

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_API)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []); 


  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDownloadSampleFile = () => {
    axiosInstance
      .post(ApiUrls.FILESTORAGE_API + "/download/file", {
        key: key,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sample-User.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        setResponseMessage("Error downloading sample file. Please try again.");
      });
  };

  const handleFileUpload = (event) => {
    const formData = new FormData();
    formData.append("file", file);
    axiosInstance
    //   .post(ApiUrls.ASSIGNMENT_API + "/bulk/upload" + selectedCompetency.policy_id, formData)
    .post(`${ApiUrls.ASSIGNMENT_API}/bulk/upload/${selectedCompetency.policy_id}`, formData)
      .then((response) => {
        setResponseMessage(response.data.message);
        setTimeout(() => {
          
        }, 3000);
      })
      .catch((error) => {
        setResponseMessage("Error adding Policy to Users. Please try again.");
      });
  };

  return (
    <div style={{ flex: 1, fontFamily: "Figtree" }}>
      <Paper
        sx={{
          width: "100%",
          height: "auto",
          padding: "20px",
          margin: "65px 0 3px 10px",
          backgroundColor: "#5a8a9a33",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        

        <Typography
          sx={{
            fontSize: "18px",
            color: "#1D3557",
            fontFamily: "figtree",
            mb: 2,
          }}
        >
          Instructions
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#1D3557", fontFamily: "figtree" }}
        >
          1. Only CSV files are allowed.
          <br />
          2. The first row should be headers with column names like{" "}
          <em> User Id , User Name, User Email . </em>
          <br />
          3. Ensure all required fields are present in the CSV file.
          <br />
          4. Upload the file in the correct format to avoid errors.
          <br />
          5.{" "}
          <Link href="#" underline="hover" onClick={handleDownloadSampleFile}>
            Download Sample File
          </Link>
        </Typography>

        <div
          style={{
            width: "80%",
            height: "auto",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            textAlign: "center",
            margin: "80px 0 20px 60px",
          }}
        > 
        
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "figtree",
              color: "#1D3557",
              mb: 2,
            }}
          >
            Assign Policy In Bulk
          </Typography>  
          <Box sx={{ mb: 4,  ml:24, mr:24}}>
          <TextField
            select
            label="Select Policy"
            value={selectedCompetency}
            onChange={(e) => setSelectedCompetency(e.target.value)}
            fullWidth
            sx={{ fontFamily: "Figtree" }}
          >
            {policies.length > 0 ? (
              policies.map((competency) => (
                <MenuItem key={competency.policy_id} value={competency}>
                  {competency.policy_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No policy available</MenuItem>
            )}
          </TextField> 
          </Box>
          <div>
            <Input
              type="file"
              accept=".csv"
              name="select_file"
              data-allowed-file-extensions="csv"
              required=""
              inputProps={{ accept: ".csv" }}
              onChange={(event) => setFile(event.target.files[0])}
              fileName={file.name}
              sx={{ marginBottom: "16px", fontFamily: "figtree" }}
            />
            <Button
              type="submit"
              variant="contained"
              // color="primary"
              disabled={!file}
              style={{
                backgroundColor: "#3f6677",
                color: "#fff",
                fontFamily: "figtree",
                padding: "10px 20px",
                fontSize: "16px",
                marginLeft: "5px",
              }}
              onClick={handleFileUpload}
            >
              Upload
            </Button>
            {responseMessage && (
              <Typography
                variant="body2"
                sx={{ fontFamily: "figtree", color: "#1D3557" }}
              >
                {responseMessage}
              </Typography>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default PolicyImport;
