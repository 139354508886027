import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, TextField, Alert,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PdfViewer from "../../Components/PDFViewer/PdfViewer";
import ViewQuestions from "../../Components/ViewQuestions/ViewQuestions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // for approve
import HistoryIcon from "@mui/icons-material/History";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";

const PolicyDetails = () => {
  useAuthHeaders();
  const [policy, setPolicy] = useState({});
  const [assignment, setAssignment] = useState({});
  const [questions, setQuestions] = useState([]);
  const [lastAttemptedInfo, setLastAttemptedInfo] = useState([]);
  const [showSection, setShowSection] = useState("watch");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [approveDialogeOpen, setApproveDialogOpen] = useState(false);
  const [revisionRequestDialogOpen, setRevisionRequestDialogOpen] =
    useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [comments, setComments] = useState("");
  const [isReserved, setIsReserved] = useState(false);
   const [alert, setAlert] = useState({
      message: "",
      severity: "success",
      show: false,
    });

  const navigate = useNavigate();
  const location = useLocation();
  const routeData = location.state;
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policy_id");
  const assignmentId = searchParams.get("assignment_id");
  const userId = sessionStorage.getItem("loggedinUserId");
  const userRole = sessionStorage.getItem("loggedinUserRole");

  useEffect(() => {
    if (policyId) {
      setLoading(true);
      axiosInstance
        .get(ApiUrls.POLICY_API + "/" + policyId)
        .then((response) => {
          setPolicy(response.data);
          const assesmentInfo = JSON.parse(response.data.assessment_info);
          if (Array.isArray(assesmentInfo)) {
            setQuestions(assesmentInfo);
          } else {
            setQuestions([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError("An error occurred while fetching the policies.");
          setLoading(false);
        });
    }
    if (assignmentId) {
      setLoading(true);
      axiosInstance
        .get(ApiUrls.ASSIGNMENT_API + "/" + assignmentId)
        .then((response) => {
          setAssignment(response.data);
          if (response.data.assessment_taken === true) {
            setLastAttemptedInfo(response.data.last_attempted_info);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError("An error occurred while fetching the assignment details.");
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (policy?.reserved_by) {
      setIsReserved(policy.reserved_by === parseInt(userId));
    }
  }, [policy]);

  const toggleStar = async (assignment) => {
    try {
      if (!assignment?.is_favourite == true) {
        const requestBody1 = {
          is_favourite: true,
        };
        const response = await axiosInstance.put(
          ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
          requestBody1
        );
        setAssignment((prevAssignment) => ({
          ...prevAssignment,
          is_favourite: true,
        }));
      } else {
        const requestBody2 = {
          is_favourite: false,
        };
        await axiosInstance.put(
          ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
          requestBody2
        );
        setAssignment((prevAssignment) => ({
          ...prevAssignment,
          is_favourite: false,
        }));
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleConfirmComplete = () => {
    setConfirmDialogOpen(false);
    axiosInstance
      .put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, {
        assignment_status: "Completed",
        completed_date: new Date(),
      })
      .then(() => {
        setAssignment((prevAssignment) => ({
          ...prevAssignment,
          assignment_status: "Completed",
        }));
        setDialogMessage("Your assignment was marked completed successfully.");
        setMessageDialogOpen(true);
      })
      .catch(() => {
        setDialogMessage(
          "An error occurred while changing status of your assignment. Please try again."
        );
        setMessageDialogOpen(true);
      });
  };

  const handleApprovePolicy = (newStatus) => {
    if (!comments || comments.trim() === "") {
      setApproveDialogOpen(false);
      setAlert({
        message:"Please add a comment before sending.",
        severity: "error",
        show: true,
      });
      setTimeout(
        () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
        3000
      );
      return;
    }
      axiosInstance
        .put(`${ApiUrls.POLICY_API}/${policyId}`,
           {
             policy_status: newStatus, 
             role_substitute:false, 
             comment:comments, 
             reserved_by:-1 
            })
        .then(() => {
         setApproveDialogOpen(false);
         navigate(`/approvalReview`);
        })
        .catch((error) => {
          console.error("Error updating policy status:", error);
        });
  }

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const closeApproveDialog = () => {
    setApproveDialogOpen(false);
  };

  const closeMessageDialog = () => {
    setMessageDialogOpen(false);
  };

  const closeRequestRevisionDialog = () => {
    setRevisionRequestDialogOpen(false);
  };

  const getNextStatus = (currentStatus, policy) => {
    if (policy.role_substitute) {
      switch (currentStatus) {
        case "Assigned to Senior Leader":
          return "Assigned to Quality Director"; // Skip Senior Leader
        case "Assigned to Quality Director":
          return "Approved by Quality Director"; // Skip Quality Director
        default:
          return currentStatus;
      }
    } else {
      switch (currentStatus) {
        case "Assigned to Director":
          return "Assigned to Senior Leader";
        case "Assigned to Senior Leader":
          return "Assigned to Quality Director";
        case "Assigned to Quality Director":
          return "Approved by Quality Director";
        default:
          return currentStatus;
      }
    }
  };

  const handleCommentChange = (value) => {
    setComments(value);
  };

  const handleRevisionRequest = () => {
    if (!comments || comments.trim() === "") {
      setRevisionRequestDialogOpen(false);
      setAlert({
        message:"Please add a comment before sending.",
        severity: "error",
        show: true,
      });
      setTimeout(
        () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
        3000
      );
      return;
    }

    const requestBody = {
      policy_status: "Revision Requested",
      comment: comments,
      reserved_by:-1,
    };

    axiosInstance
      .put(ApiUrls.POLICY_API + "/" + policyId, requestBody)
      .then(() => {
        setRevisionRequestDialogOpen(false);
        navigate(`/approvalReview`);
      })
      .catch((error) => {
        console.error("Error sending comment:", error);
      });
  };

  const canApprove = (policy) => {
    const hasCorrectRole = (() => {
      switch (userRole) {
        case "Director":
          return policy.policy_status === "Assigned to Director" || 
          (policy.role_substitute && policy.policy_status === "Assigned to Senior Leader");
        case "Senior Leader":
          return policy.policy_status === "Assigned to Senior Leader" || 
          (policy.role_substitute && policy.policy_status === "Assigned to Quality Director");
        case "Quality Director":
          return policy.policy_status === "Assigned to Quality Director";
        default:
          return false;
      }
    })();
    return hasCorrectRole && (isReserved || policy.reserved_by === -1);
  };

  const canRequestRevision = (policy) => {
    return policy.policy_status === `Assigned to ${userRole}` && 
           (isReserved || policy.reserved_by === -1);
  };

  const handleApprove = (policy) => {
    const nextStatus = getNextStatus(policy.policy_status, policy);
    handleApprovePolicy(nextStatus);
  };

  const canPerformActions = (policy) => {
    if (!policy) return false;
    return policy.reserved_by === parseInt(userId);
  };

  const handleReservation = async () => {
    try {
      const newReservedBy = isReserved ? -1 : parseInt(userId);
      
      await axiosInstance.put(`${ApiUrls.POLICY_API}/${policyId}`, {
        reserved_by: newReservedBy
      });

      setPolicy(prev => ({
        ...prev,
        reserved_by: newReservedBy
      }));
      
      setIsReserved(!isReserved);
    } catch (error) {
      setAlert({
        message:
         "Operation can only be done by the reserved person",
        severity: "error",
        show: true,
      });
      setTimeout(
        () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
        3000
      );
    }
  };

  return (
    <Box>
       {alert?.show && (
                <Alert
                  variant="filled"
                  severity={alert.severity}
                  onClose={() => setAlert({ ...alert, show: false })}
                  style={{
                    position: "fixed",
                    top: "25%",
                    left: "55%",
                    transform: "translateX(-50%)",
                    maxWidth: "400px",
                    zIndex: 100000,
                  }}
                >
                  {alert.message}
                </Alert>
              )}
       {/* <div
              style={{
                backgroundColor: "#f3f6f9",
                padding: "0px 20px",
              }}
            >
              <ol
                style={{
                  display: "flex",
                  listStyle: "none",
                  padding: 0,
                  margin: "2px 0 5px -13px",
                  fontSize: "14px",
                  color: "#6c757d",
                }}
              >
                <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree", cursor: "pointer", marginTop:"-10px" }} 
                               onClick={() => navigate(-1)}
                                >
                               <IconButton  style={{ color: "#1D3557" }}>
                                  <ArrowBackIcon />
                                </IconButton>
                                <span  style={{ color: "#1D3557" }}  onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                         onMouseOut={(e) => (e.target.style.textDecoration = "none")} >Back</span>
                               </li>
                         <li style={{ display: "inline-block", marginRight: "8px",fontFamily:"figtree" }}>/</li>
                <li
                  style={{
                    display: "inline-block",
                    color: "#3f6677",
                    fontWeight: "bold",
                    fontFamily: "figtree"
                  }}
                >
                  Policy Details
                </li>
              </ol>
              <h4
                style={{
                  color: "#3f6677",
                  marginBottom: "8px",
                  fontSize: "32px",
                  fontWeight: "bold",
                  fontFamily: "figtree",
                  marginLeft: "-13px"
                }}
              >
               Policy Details
              </h4>
      </div> */}
       <Breadcrumb routes= {routeData}/>
      <Box
        sx={{
          backgroundColor: "#5a8a9a33",
          borderRadius: "8px",
          px: 3,
          py: 2,
          textAlign: "center",
          position: "relative",
          color: "#1d3557",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 1,
            color: "#1d3557",
            fontFamily: "figtree",
            fontSize: "14px",
          }}
        >
          {policy.policy_type}
        </Typography>
        <Typography
          sx={{
            color: "#1d3557",
            fontWeight: "bold",
            fontFamily: "figtree",
            mb: 2,
            fontSize: { xs: "20px", sm: "22px", md: "30px" },
          }}
        >
          {policy.policy_name}
        </Typography>
        <Typography
          sx={{
            mb: 4,
            fontSize: { xs: "14px", sm: "16px" },
            color: "#1d3557",
            fontFamily: "figtree",
          }}
        >
          {policy.policy_description}
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "12px", sm: "14px" },
            opacity: 0.8,
            color: "#1d3557",
            fontFamily: "figtree",
          }}
        >
          Status:{" "}
          {assignmentId ? assignment.assignment_status : policy.policy_status}
        </Typography>

        {assignmentId && (
          <Box
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color={assignment.is_favourite ? "success" : "error"} // Change color dynamically
              sx={{
                fontFamily: "figtree", // Custom font
                backgroundColor: assignment.is_favourite
                  ? "#fff"
                  : "#1D3557", // Override default background colors
                color: assignment.is_favourite ? "#1D3557" : "#fff", // Text color based on status
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event propagation
                toggleStar(assignment); // Call the toggle function
              }}
            >
              {assignment.is_favourite ? "Unmark Favorite" : "Mark as Favorite"}{" "}
              {/* Dynamic text */}
            </Button>
            {/* <IconButton sx={{ color: "white" }}>
                <MoreVertIcon />
          </IconButton> */}
          </Box>
        )}
      </Box>

      <Card
        sx={{
          flex: 1,
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          padding: 1,
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          onClick={() => setShowSection("watch")}
          startIcon={
            <img
              src="https://img.icons8.com/ios-filled/20/000000/read.png"
              alt="Watch Icon"
            />
          }
          sx={{ fontWeight: "bold", color: "black" }}
        >
          Policy
          {showSection === "watch" && (
            <Box
              sx={{
                position: "absolute",
                bottom: -10,
                left: 0,
                width: "100%",
                height: "6px",
                backgroundColor: "#6b9bca",
              }}
            />
          )}
        </Button>

        {policyId && assignment?.assignment_status === "Assigned" && (
          <>
            {assignment?.is_assessment_held === true ? (
              <Button
                onClick={() => setShowSection("startAssignment")}
                startIcon={
                  <img
                    src="https://img.icons8.com/ios-filled/20/000000/test.png"
                    alt="Watch Icon"
                  />
                }
                sx={{ fontWeight: "bold", color: "black" }}
              >
                Assessment Details
                {showSection === "startAssignment" && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -10,
                      left: 0,
                      width: "100%",
                      height: "6px",
                      backgroundColor: "#6b9bca",
                    }}
                  />
                )}
              </Button>
            ) : (
              <Button
                startIcon={
                  <img
                    src="https://img.icons8.com/ios-filled/20/000000/start.png"
                    alt="Watch Icon"
                  />
                }
                sx={{ fontWeight: "bold", color: "black" }}
                onClick={() => {
                  setConfirmDialogOpen(true);
                  setShowSection("Mark as Completed");
                  setDialogMessage(
                    "Your assessment was completed successfully. Do you want to mark this policy as complete?"
                  );
                }}
              >
                Mark as Completed
                {showSection === "Mark as Completed" && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -10,
                      left: 0,
                      width: "100%",
                      height: "6px",
                      backgroundColor: "#6b9bca",
                    }}
                  />
                )}
              </Button>
            )}
          </>
        )}
      </Card>

      <Box
        sx={{ mt: 3, mb: 1, display: "flex", alignItems: "flex-start", gap: 2 }}
      >
        <Card
          sx={{
            flex: 1,
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <CardContent>
            {showSection === "watch" && <PdfViewer policyId={policyId} />}
            {showSection === "startAssignment" && (
              <ViewQuestions
                questions={questions}
                lastAttemptedAssessmentInfo={lastAttemptedInfo}
                loading={loading}
                assignmentId={assignmentId}
                setAssignment={setAssignment}
              />
            )}
          </CardContent>
        </Card>
      </Box>
      
      {policy?.reserved_by !== -1 && policy?.reserved_by !== parseInt(userId) && (
        <Alert 
          severity="info"
          icon={<InfoIcon />}
          sx={{ 
            mb: 2,
            backgroundColor: "#e3f2fd",
            "& .MuiAlert-icon": {
              color: "#1d3557"
            },
            "& .MuiAlert-message": {
              color: "#1d3557",
              fontFamily: "figtree"
            }
          }}
        >
          This policy is currently reserved. To perform any actions, please contact your administrator.
        </Alert>
      )}

      {(canApprove(policy) || canRequestRevision(policy)) && (
        <Box
          sx={{
            position: "relative",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
            padding: 2,
            display: "flex",
            justifyContent: "center",
            gap: 2,
            zIndex: 1000,
          }}
          // sx={{
          //   position: "fixed",
          //   bottom: 0,
          //   left: 0,
          //   right: 0,
          //   backgroundColor: "white",
          //   boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
          //   padding: 2,
          //   display: "flex",
          //   justifyContent: "center",
          //   gap: 2,
          //   zIndex: 1000,
          // }}
        >
          <Button
          startIcon={isReserved ? <LockOpenIcon /> : <LockIcon />}
          sx={{ 
            fontWeight: "bold", 
            color: "#1d3557", 
            backgroundColor: isReserved ? "#e0e0e0" : "#5a8a9a33"
          }}
          onClick={handleReservation}
          disabled={policy.reserved_by !== -1 && !isReserved}
        >
          {isReserved ? "Unreserve" : "Reserve"}
        </Button>

        {canRequestRevision(policy) && (
              <Button
              startIcon={<YoutubeSearchedForIcon />}
                sx={{ fontWeight: "bold", color: "#1d3557", backgroundColor:"#5a8a9a33",   opacity: !canPerformActions(policy) ? 0.5 : 1 }}
                onClick={() => {
                  setRevisionRequestDialogOpen(true);
                  setDialogMessage("Are you sure you want to submit a request for the team to review this policy?");
                }}
                disabled={!canPerformActions(policy)}
              >
                Request Revision
              </Button>
         )}
          {canApprove(policy) && (
            <Button
              startIcon={<CheckCircleIcon />}
                sx={{ fontWeight: "bold", color: "#fff",backgroundColor:"#3f6677",   opacity: !canPerformActions(policy) ? 0.5 : 1  }}
                onClick={() => {
                  setApproveDialogOpen(true);
                  setDialogMessage(
                    "Please confirm if you wish to approve this policy to proceed to the next stage."
                  );
                }}
                disabled={!canPerformActions(policy)}
              >
                Approve
              </Button>
        )}
          </Box>
        )}

      <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Policy Completion Acknowledgement</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#7da1b2", color: "white" }}
            onClick={closeConfirmDialog}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#34425a", color: "white" }}
            onClick={() => handleConfirmComplete()}
            variant="contained"
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={approveDialogeOpen} onClose={closeApproveDialog}>
        <DialogTitle>Policy Approval Acknowledgment</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
          <Box
            sx={{
              margin: 2,
              padding: 2,
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <TextField
              label="Add Comment"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              sx={{ mt: 2 }}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#7da1b2", color: "white" }}
            onClick={closeApproveDialog}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#34425a", color: "white" }}
            onClick={() => handleApprove(policy)}
            variant="contained"
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={revisionRequestDialogOpen}
        onClose={closeRequestRevisionDialog}
      >
        <DialogTitle>Policy Revision Request</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
          <Box
            sx={{
              margin: 2,
              padding: 2,
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <TextField
              label="Add Comment"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              sx={{ mt: 2 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#7da1b2", color: "white" }}
            onClick={closeRequestRevisionDialog}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#34425a", color: "white" }}
            onClick={() => handleRevisionRequest()}
            variant="contained"
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={messageDialogOpen} onClose={closeMessageDialog}>
        {/* <DialogTitle>Information</DialogTitle> */}
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#7da1b2", color: "white" }}
            onClick={closeMessageDialog}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default PolicyDetails;
