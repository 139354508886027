import { routers } from "../Configurations/configurationUI";

export const getNotificationTitle = (notification) => {
    switch (notification.user_type) {
      case "PolicyCreated": return "A New Policy has been created"
      case "PolicyStatusUpdated": return "Policy has been updated" 
      case "PolicyAssigned": return "A Policy has been assigned for you." 
      case "PolicyApproval": return "A Policy has been assigned for Awaiting Your Approval." 
      case "RequestRevision": return "Action Required, A Policy has been assigned for revision." 
      default: return "";
    }
  };


  export const getnotificationNavigation = (notification) => {
    switch (notification.user_type) {
      case "PolicyCreated": return [routers.approvalReview];
      case "PolicyStatusUpdated": return [routers.monitorProcess];
      case "PolicyAssigned": return [routers.competencies];
      case "PolicyApproval": return [routers.approvalReview];
      case "RequestRevision": return [routers.approvalReview];
      default: return "";
    }
  };