import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Alert,
} from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  useAuthHeaders();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [rows, setRows] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    borderRadius: "10px",
    padding: "15px",
  };

  const containerStyle = {
    display: "flex",
  };
  const colonStyle = {
    textAlign: "center",
    width: "10px",
    fontWeight: "bold",
  };
  const strongStyle = {
    display: "inline-block",
    width: "150px",
    paddingRight: "10px",
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  useEffect(() => {
    const handleFilterChange = debounce(() => {}, 300);
    handleFilterChange();

    return () => {
      clearTimeout(handleFilterChange);
    };
  }, [filterModel]);

  const fetchUsersData = () => {
    setLoading(true);
    axiosInstance
      .get(ApiUrls.USER_API)
      .then((succResp) => {
        const usersWithFormattedDates = succResp.data.map((el, index) => ({
          ...el,
          id: index + 1,
          dob: formatDate(el.dob),
        }));
        setRows(usersWithFormattedDates);
        setLoading(false);
      })
      .catch((errorResp) => {
        console.error(JSON.stringify(errorResp));
        setError("Error while fetching user details. Please try again.");
        setLoading(false);
      });
  };

  const confirmDelete = (userId) => {
    setUserToDelete(userId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteUser = (userId) => {
    setLoading(true);
    axiosInstance
      .delete(ApiUrls.USER_API + "/" + userId)
      .then((succResp) => {
        setRows(rows.filter((user) => user.user_id !== userId));
        setDeleteDialogOpen(false);
        setLoading(false);
        setAlert({
          message: "User deleted successfully.",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      })
      .catch((errorresp) => {
        setDeleteDialogOpen(false);
        setAlert({
          message: "Error while deleting User. Please try again.",
          severity: "warning",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
        setLoading(false);
      });
  };

  const sortedData = [...rows].sort((a, b) => b.id - a.id);
  const columns = [
    { field: "user_id", headerName: <strong>User Id</strong>, width: 80 },
    {
      field: "name",
      headerName: (
        <div style={{ textAlign: 'center' }}>
          <div><strong>Name</strong></div>
          
        </div>
      ),
      width: 220,
      renderCell: (params) => (
        <>
          <div> {params.row.first_name} {params.row.last_name} </div>
          
        </>
      ),
    },

    
    
    {
      field: "primary_email",
      headerName: <strong> Email </strong>,
      width: 200,
    },
    {
      field: "primary_phone",
      headerName: <strong> Phone </strong>,
      width: 100,
    },
    // { field: "dob", headerName: <strong>Dob</strong>, width: 150 },
    {
      field: "location_name",
      headerName: <strong>Location</strong>,
      width: 150,
    },
    {
      field: "department_name",
      headerName: <strong>Department</strong>,
      width: 150,
    },
    { field: "role_name", headerName: <strong>Role</strong>, width: 150 },
    {
      field: "action",
      headerName: <strong> Action</strong>,
      width: 200,
      renderCell: (params) => (
        <div>
          {/* {canDelete || canEdit || canView ? ( */}

          <div>
            {/* {canEdit && ( */}
            <Link to={`/edituser?user_id=${params.row.user_id}`}>
              <FaEdit style={{ color: "#1D3557" }} />
            </Link>

            {/* )} */}

            {/* {canDelete && ( */}
            <button
              className="btn btn-normal btn-addon"
              title="Delete"
              style={{
                background: "none",
                border: "none",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => confirmDelete(params.row.user_id)}
            >
              <FaTrash style={{ color: "#1D3557" }} />
            </button>
            {/* )} */}

            {/* {canView && ( */}
            {/* <button
              className="btn btn-normal btn-addon"
              title="Information"
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            // onClick={() => handleOpenModal(params.row.u_user_id)}
            >
              <FaEye style={{ color: 'black' }} />
            </button> */}
            {/* )} */}
          </div>
          {/* ) : null} */}
        </div>
      ),
    },
  ];

  return (
    <div style={{fontFamily: "Figtree"}}>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}

      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -17px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "Figtree",
            }}
          >
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "Figtree",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "inline-block",
              color: "#1D3557",
              fontWeight: "bold",
              fontFamily: "Figtree",
            }}
          >
            User list
          </li>
        </ol>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{  fontWeight: "bold", color: "#3f6677", fontFamily: "Figtree" }}
        >
          User list
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/adduser")}
          startIcon={<AddIcon />} 
          sx={{fontFamily: "Figtree", 
            backgroundColor: "#3f6677",
            color: "#fff",
          }}
        >
          Add New User
        </Button>
      </Box>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          fontFamily: "Figtree",
          
        }}
      >
        {error && (
          <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>
            {error}
          </p>
        )}
        {loading && (
          <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>
        )}
        {!loading && !error && (
          <DataGrid
            rows={sortedData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[10, 20, 30]}
            loading={loading}
            classes={{
              overlayWrapper: "customOverlayWrapper",
            }}
            sx={{
              "& .customOverlayWrapper ": {
              height: "63px !important",
              fontFamily: "Figtree", 
             },
              fontSize: "14px",
              fontFamily: "Figtree",
              borderRadius: "8px",
              boxShadow: 2,
              color:"#1D3557",
              "& .MuiDataGrid-container--top [role=row]": {
              backgroundColor: "#5a8a9a33",
              color: "#1D3557",               
              fontFamily: "Figtree",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "white",
              color: "#1D3557",            
              fontFamily: "Figtree",
              fontSize: "14px",
            },
            }}
            components={{
              Toolbar: () => (
                <GridToolbarQuickFilter
                  classes={{
                    toolbarQuickFilter: "customtoolbarQuickFilter",
                  }}
                  sx={{
                    width: "35% !important",
                    position: "absolute !important",
                    right: 300,
                    top: "-30px !important",
                  }}
                  filterModel={filterModel}
                  onFilterModelChange={(newModel) => setFilterModel(newModel)}
                />
              ),
              NoRowsOverlay: () => (
                <div
                  style={{
                    position: "sticky",
                    top: "50%",
                    transform: "translate(-50%, 0%)",
                    textAlign: "center",
                    marginLeft: "45%",
                  }}
                >
                  No data found
                </div>
              ),
            }}
          />
        )}
      </Box>

      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Delete Profile</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily:"figtree"}}>
            Are you sure you want to delete this profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#7da1b2", color: "white" , fontFamily:"figtree" }}
            onClick={closeDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#34425a", color: "white", fontFamily:"figtree"  }}
            onClick={() => handleDeleteUser(userToDelete)}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UserList;
