import React, { useEffect, useState } from 'react';
import PDFCompareViewer from '../../Components/PDFComparisonViewer/PDFComparisonViewer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../Utils/axiosInstance';
import ApiUrls from '../../Configurations/ConfigurationsApiUrls';
import { Box, Typography, Card, CardContent, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';



const PDFCompareViewerScreen = () => {
  const [policy, setPolicy] = useState({});


  const location = useLocation();
   const routeData = location.state;
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policy_id");

  useEffect(() => {
    if (policyId) {
      axiosInstance.get(ApiUrls.POLICY_API + "/" + policyId)
        .then((response) => {
          setPolicy(response.data);
        })
        .catch((error) => {
        });
    }
  }, []);

  return (
    <Box>
      {/* <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>/</li>
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>
            <Link
              to="/approvalReview"
              style={{ textDecoration: "none", color: "#1D3557" }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Approval & Review
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "figtree"
            }}
          >
            Version Compare
          </li>
        </ol>
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "8px",
            fontSize: "32px",
            fontWeight: "bold",
            fontFamily: "figtree",
            marginLeft: "-13px"
          }}
        >
          Version Compare
        </h4>
      </div> */}
       <Breadcrumb routes= {routeData}/>
      <Box
        sx={{
          backgroundColor: "#5a8a9a33",
          borderRadius: "8px",
          px: 3,
          py: 3,
          textAlign: "center",
          position: "relative",
          color: "#1d3557",
        }}
      >
        <Typography sx={{ fontWeight: "bold", mb: 1, color: "#1d3557", fontFamily: "figtree", fontSize: "14px" }}>
          {" "}
          {policy.policy_type}{" "}
        </Typography>
        <Typography
          sx={{
            color: "#1d3557", fontWeight: "bold", fontFamily: "figtree", mb: 2, fontSize: { xs: "20px", sm: "22px", md: "30px" },
          }}
        >
          {policy.policy_name}
        </Typography>
        <Typography
          sx={{ mb: 4, fontSize: { xs: "14px", sm: "16px" }, color: "#1d3557", fontFamily: "figtree", }}
        >
          {policy.policy_description}
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "12px", sm: "14px" }, opacity: 0.8, color: "#1d3557", fontFamily: "figtree", }}
        >
          Status :{policy.policy_status}
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <PDFCompareViewer />
        </CardContent>
      </Card>
    </Box>
  );
};

export default PDFCompareViewerScreen;
