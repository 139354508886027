import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  Tabs,
  Tab,
  Autocomplete,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import BookIcon from "@mui/icons-material/Book";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import Chip from "@mui/material/Chip";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';


const formatDate = (date) => {
  if (!date) return "-";
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${month}/${day}/${year}`;
};

const HomePage = () => {
  useAuthHeaders();

  const [activeTab, setActiveTab] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [recentPolicy, setRecentPolicy] = useState([]);
  const [recent, setRecent] = useState([]);

  const [assesment, setAssesment] = useState([]);

  const navigate = useNavigate();
  const userInfo = JSON.parse(sessionStorage.getItem("loggedinUser"));
  const user = sessionStorage.getItem("loggedinUserId");
  const [policies, setPolicies] = useState([]); 
  const [searchOptions, setSearchOptions] = useState([]);
  
  useEffect(() => {
    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/By/userId" )
      .then((response) => setRecentPolicy(response.data))
      .catch((error) =>
        console.error("Error fetching recentspolicies:", error)
      );

    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/threedaysbefore/notcompletedstatus")
      .then((response) => setRecent(response.data))
      .catch((error) => console.error("Error fetching recents:", error));

    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/notcompleted/assessment")
      .then((response) => setAssesment(response.data))
      .catch((error) => console.error("Error fetching Assesments:", error));
  }, []);  


  useEffect(() => { 
    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/By/userId" )
      .then((response) => {
        setPolicies(response.data);
        const policyNames = response.data.map(policy => policy.policy_name);
        setSearchOptions(policyNames);
      })
      .catch((error) => console.error("Error fetching Policies:", error)); 
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleSearch = () => {
    if (keyword.trim()) {
      navigate(`/searchresults?q=${encodeURIComponent(keyword.trim())}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleCardClick = (policyId, assignmentId) => {
    
    axiosInstance
      .put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, {
        last_visited: new Date(),
      })
      .then(() => {
        const data = { routeName: "", routeUrl: "", nextRouteName:"Policy Details" };
        navigate( `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`, { state: data });
      })
      .catch(() => {
        const data = { routeName: "", routeUrl: "", nextRouteName:"Policy Details" };
        navigate( `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`, { state: data });
      });
  };  
  const handleAssessmentClick = (policy) => {
    const policyId = policy.policy_id;
    const assignmentId = policy.assignment_id;
    axiosInstance
      .put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, {
        last_visited: new Date(),
      })
      .then(() => {
        navigate(
          `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`
        );
      })
      .catch(() => {
        navigate(
          `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`
        );
      });
  };
  return (
    <Box sx={{ height:"100%"  }}>
      <Box
        sx={{
          backgroundColor: "#5a8a9a33", 
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "8px",
          padding: "20px",
          textAlign: "center",
          color: "white",
          // maxWidth: "1200px",
          margin: "auto",
          height:"200px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)", 
        }}
      >  
       <Typography
          variant="h4"
          sx={{ fontWeight:400, marginBottom: "10px",color: "#1D3557", fontFamily:"figtree" }}
        >
           Greetings,{userInfo.first_name}! Explore, Updated Policies.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            // fontWeight: "bold",
            // fontWeight:400,
            marginBottom: "10px",
            color: "#1D3557", 
            fontFamily:"figtree"
          }}
        >
          What would you like to learn today?
        </Typography>  
       
        <Autocomplete
          freeSolo
          options={searchOptions}
          value={keyword}
          onInputChange={(event, newValue) => setKeyword(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="     Search..."
              onKeyDown={handleKeyDown}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                    {/* <IconButton onClick={toggleFilterDrawer}>
                      <FilterListIcon />
                    </IconButton> */}
                  </>
                ),
                style: {
                  height: "60px",
                  width: "70%",
                  backgroundColor: "#fff",
                  borderRadius: "30px",
                  marginLeft: "15%",
                  marginRight: "15%",
                },
              }}
            />
          )}
        />
      </Box>

      {/* <Box
        sx={{
          textAlign: "center",
          marginTop: "20px",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
       
      </Box> */}

      {/* Tab Section */}
      <Card
        sx={{
          // maxWidth: "1200px",
          margin: "20px auto",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)", 
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          // indicatorColor="primary"
          // textColor="primary" 
            textColor="inherit"
          variant="fullWidth"
          sx={{
            marginBottom: "20px",
            fontFamily: "figtree",
            "& .MuiTabs-indicator": {
              backgroundColor: "#1D3557", // Custom indicator color
            },
            "& .MuiTab-root": {
              color: "#1D3557", // Custom text color
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#1D3557", // Custom text color for selected tab
            },
          }}
        >
          <Tab label="Recent Policies" sx={{ fontFamily: "figtree", fontSize: "15px", fontWeight: 600 }} />
          <Tab label="Recent Learning" sx={{ fontFamily: "figtree", fontSize: "15px", fontWeight: 600 }} />
          <Tab label="My Assessments"  sx={{ fontFamily: "figtree", fontSize: "15px", fontWeight: 600 }}/>
        </Tabs>
        <Box>
          {activeTab === 0 && (
            <List>
              {recentPolicy.slice(-4).reverse().map((policy, index) => (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: "#fbfbfd",
                    height: "60px",
                    margin: "5px 0",  
                    color: "#1D3557",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#5a8a9a33",
                      cursor: "pointer", 
                      
                    },
                  }} 
                  onClick={() => handleCardClick(policy.policy_id, policy.assignment_id)}
                >
                  <ListItemIcon>
                    <BookIcon sx={{ color: "#5a8a9a9e" }} />
                  </ListItemIcon>
                  <ListItemText primary={policy.policy_name}
                   primaryTypographyProps={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "figtree",
                  }}
                  />
                  <Chip
                    label={formatDate(policy.effective_date)}
                    sx={{
                      backgroundColor: policy.effective_date
                        ? "#5a8a9a33"
                        : "#f8d7da",
                      color:  "#1D3557" ,
                      marginRight: "20px", 
                      fontFamily: "figtree",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {activeTab === 1 && (
            <List>
              {recent.map((policy, index) => (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: "#fbfbfd",
                    height: "60px", 
                    margin: "5px 0",
                    borderRadius: "5px", 
                    color: "#1D3557",
                    "&:hover": {
                      backgroundColor: "5a8a9a33",
                      cursor: "pointer",
                    },
                  }} 
                  onClick={() => handleCardClick(policy.policy_id, policy.assignment_id)}
                >
                  <ListItemIcon>
                    <BookIcon sx={{ color: "#5a8a9a9e" }} />
                  </ListItemIcon>
                  <ListItemText primary={policy.policy_name}
                   primaryTypographyProps={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "figtree",
                  }}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {activeTab === 2 && (
            <List>
              {assesment.map((exam, index) => (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: "#fbfbfd",
                    height: "60px",
                    margin: "5px 0", 
                    color: "#1D3557",
                    borderRadius: "5px", 
                    "&:hover": {
                      backgroundColor: "#5a8a9a33",
                      cursor: "pointer",
                    },
                  }}  
                  onClick={() => handleCardClick(exam.policy_id, exam.assignment_id)}
                >
                  <ListItemIcon>
                    <AssignmentIcon sx={{ color: "#5a8a9a9e" }} />
                  </ListItemIcon>
                  <ListItemText primary={exam.policy_name}
                   primaryTypographyProps={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "figtree",
                  }}
                  />
                  <Chip
                    label={
                      exam.assessment_status === "NotStarted"
                        ? "Not Started"
                        : exam.assessment_status
                    }
                    sx={{
                      backgroundColor:
                        exam.assessment_status === "NotStarted"
                         ? "#5a8a9a33"
                          : "#ffcccc",
                      color:
                        exam.assessment_status === "NotStarted"
                          ? "#1D3557"
                          : "#cc0000",
                      marginRight: "20px",
                      fontFamily: "figtree",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default HomePage;
