// import React, { useEffect, useState, version } from "react";
// import { Box, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
// import ApprovalIcon from "@mui/icons-material/Approval";
// import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
// import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
// import { Link, useNavigate } from "react-router-dom";
// import newStyled from "@emotion/styled";

// const Monitorpolicy = () => {
//   useAuthHeaders();
//   const [policies, setPolicies] = useState([]); 

//   const userrole = sessionStorage.getItem("loggedinUserRole");

//   const navigate = useNavigate();

//   useEffect(() => {

//     axiosInstance.get(ApiUrls.POLICY_API)
//       .then((response) => {
//         setPolicies(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching policies:", error);
//       }); 

//   }, []);

//   const handleViewClick = (Id) => {
//     navigate(`/policydetails?policy_id=${Id}`);
//   };

//   const handleEdit = (policyId) => {
//     navigate(`/editPolicies?policy_id=${policyId}`);
//   };

//   const handleViewFlow = (Id) => {
//     navigate(`/policyflow?policy_id=${Id}`);
//   };  

//   const handleReset = (policyId) => {   
//     const policyPayload = {
//       policy_status: "Assigned to Director",  
//       last_updated_at: new Date(),
//       updated_by: parseInt(sessionStorage.getItem('loggedinUserId')), 
//       approved_by_director: false,
//       approved_by_seniorleader: false,
//       approved_by_qualitydirector: false,
//       policy_published: false, 
//       policy_action:"[]"
//     };
//     axiosInstance 
//     .put(ApiUrls.POLICY_API + "/" + policyId, policyPayload) 
//     .then((response) => {
//       console.log("Policy reset successfully:", response.data);
//     })
//     .catch((error) => {
//       console.error("Error resetting policy:", error);
//     });
//   };

//   const handleMakeSubstitue  = (policyId, currentSubstituteStatus) => { 
//     const updatedSubstituteStatus = !currentSubstituteStatus; 
//     axiosInstance .put(ApiUrls.POLICY_API + "/" + policyId, { role_substitute: updatedSubstituteStatus,}) 
//     .then((response) => {
//       setPolicies((prevPolicies) => 
//         prevPolicies.map((policy) => 
//           policy.policy_id === policyId ? { ...policy, role_substitute: updatedSubstituteStatus } : policy
//         )
//       );
//     })
//     .catch((error) => {
//       console.error("Error resetting policy:", error);
//     });

//   } 

//   return (
//     <div>

//         <div
//                    style={{
//                      backgroundColor: "#f3f6f9",
//                      padding: "0px 20px",
//                    }}
//                  >
//                    <ol
//                      style={{
//                        display: "flex",
//                        listStyle: "none",
//                        padding: 0,
//                        margin: "2px 0 5px -13px",
//                        fontSize: "14px",
//                        color: "#6c757d",
//                      }}
//                    >
//                      <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree" }}>
//                        <Link
//                          to="/home"
//                          style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
//                          onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
//                          onMouseOut={(e) => (e.target.style.textDecoration = "none")}
//                        >
//                          Home
//                        </Link>
//                      </li>
//                      <li style={{ display: "inline-block", marginRight: "8px",fontFamily:"figtree" }}>/</li>
//                      <li
//                        style={{
//                          display: "inline-block",
//                          color: "#3f6677",
//                          fontWeight: "bold",
//                          fontFamily:"figtree"
//                        }}
//                      >
//                         Monitor Process
//                      </li>
//                    </ol>
//                    <h4
//                style={{
//                  color: "#3f6677",
//                  marginBottom: "8px",
//                  fontSize: "32px",
//                  fontWeight: "bold",
//                  fontFamily: "figtree",
//                  marginLeft:"-13px"
//                }}
//              >
//               Monitor Process
//              </h4>
//         </div>

//         <TableContainer
//           component={Paper}
//           sx={{ marginTop: "20px", width: "100%", borderRadius: "8px" }}
//         >
//           <Table>
//             <TableHead>
//               <TableRow
//                 sx={{
//                   borderBottom: "2px solid #ddd", 
//                   backgroundColor:"#5a8a9a33"
//                 }}
//               >
//                 <TableCell sx={{ fontWeight: "bold", color: "#1D3557", fontFamily:"figtree", fontSize:"16px" }}>
//                   Policy Name
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: "#1D3557" , fontFamily:"figtree", fontSize:"16px"}}>
//                   Primary Department
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: "#1D3557", fontFamily:"figtree", fontSize:"16px" }}>
//                   Departments Involved
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: "#1D3557", fontFamily:"figtree", fontSize:"16px" }}>
//                   Status
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "bold", color: "#1D3557", fontFamily:"figtree", fontSize:"16px" }} align="center">
//                   Actions
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {policies.map((policy) => (
//                 <TableRow
//                   key={policy.id}
//                   sx={{
//                     borderBottom: "1px solid #ddd", // Add a bottom border for each row
//                   }}
//                 >
//                   <TableCell sx={{  color: "#1D3557", fontFamily:"figtree", fontSize:"14px" }}>{policy.policy_name}</TableCell>
//                   <TableCell sx={{  color: "#1D3557", fontFamily:"figtree", fontSize:"14px" }}>{policy.primary_department_name}</TableCell>
//                   <TableCell  sx={{  color: "#1D3557", fontFamily:"figtree", fontSize:"14px" }} >
//                     {policy.department_names &&
//                     policy.department_names.length > 0
//                       ? policy.department_names.join(", ")
//                       : "N/A"}
//                   </TableCell>
//                   <TableCell sx={{  color: "#1D3557", fontFamily:"figtree", fontSize:"14px" }}>{policy.policy_status} </TableCell> 
//                   <div style={{display:"flex"}}>
//                   {/* <TableCell > */}
//                     {/* <IconButton
//                       onClick={() => handleViewClick(policy.policy_id)}
//                       aria-label="view"
//                       color="primary"
//                       sx={{ fontSize: 20 }}
//                     >
//                       <VisibilityIcon sx={{ fontSize: 20 }} />
//                     </IconButton> */}
//                     {/* {(userrole === "Director" || userrole === "Administrator") && (
//                       <IconButton
//                         onClick={() => handleEdit(policy.policy_id)}
//                         aria-label="edit"
//                         color="secondary"
//                         sx={{ fontSize: 16 }}
//                       >
//                         <EditIcon sx={{ fontSize: 16 }} />
//                       </IconButton>
//                     )} */}
//                   {/* </TableCell> */}

//                   <Button
//                     onClick={() => handleViewClick(policy.policy_id)}
//                     sx={{
//                       // height: "40px",
//                       marginTop: "10px",
//                       fontSize: "12px",
//                       marginRight: "9px",
//                       marginBottom:"10px" ,
//                       width:"100px",
//                       backgroundColor:"#5a8a9a33",
//                       color:"#1D3557",
//                       fontFamily:"figtree",
//                       fontWeight:"bold",
//                       border: "2px solid transparent",
//                       '&:hover': {
//                         backgroundColor: "#5a8a9a66",
//                         border: "2px solid #1D3557",
//                       },
//                     }}
//                   >
//                     View Policy
//                   </Button>  

//                   <Button
//                     onClick={() => handleViewFlow(policy.policy_id)}
//                     sx={{
//                       // height: "40px",
//                       marginTop: "10px",
//                       fontSize: "12px",
//                       marginRight: "9px",
//                       marginBottom:"10px" ,
//                       width:"95px",
//                       backgroundColor:"#5a8a9a33",
//                       color:"#1D3557",
//                       fontFamily:"figtree",
//                       fontWeight:"bold",
//                       border: "2px solid transparent",
//                       '&:hover': {
//                         backgroundColor: "#5a8a9a66",
//                         border: "2px solid #1D3557",
//                       },
//                     }}
//                   >
//                     View Flow
//                   </Button>    

//                   {userrole === 'Administrator' && (
//                      policy.policy_status !== 'Published' && policy.policy_status !== 'Expired' && policy.policy_status !== 'Assigned to Director' && policy.policy_status !=='Revision Requested'  && (
//                     <>

//                      <Button
//                      onClick={() => handleMakeSubstitue(policy.policy_id, policy.role_substitute)}
//                      sx={{
//                        // height: "40px",
//                        marginTop: "10px",
//                        fontSize: "12px",
//                        marginRight: "9px",
//                        marginBottom:"10px" ,
//                        width:"95px",
//                        backgroundColor:"#5a8a9a33",
//                       color:"#1D3557",
//                       fontFamily:"figtree",
//                       fontWeight:"bold",
//                       border: "2px solid transparent",
//                       '&:hover': {
//                         backgroundColor: "#5a8a9a66",
//                         border: "2px solid #1D3557",
//                       },
//                      }}
//                    >
//                     {policy.role_substitute ? "Unsubstitute" : "Make Substitute"}
//                    </Button> 

//                   <Button
//                     onClick={() => handleReset(policy.policy_id)}
//                     sx={{
//                       // height: "40px",
//                       marginTop: "10px",
//                       fontSize: "12px",
//                       marginRight: "9px",
//                       marginBottom:"10px" ,
//                       width:"95px",
//                       backgroundColor:"#5a8a9a33",
//                       color:"#1D3557",
//                       fontFamily:"figtree",
//                       fontWeight:"bold",
//                       border: "2px solid transparent",
//                       '&:hover': {
//                         backgroundColor: "#5a8a9a66",
//                         border: "2px solid #1D3557",
//                       },
//                     }}
//                   >
//                     Reset 
//                   </Button>  
//                   </>
//                    )
//                   )}
//                   </div>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//     </div>
//   );
// };

// export default Monitorpolicy;

import React, { useEffect, useState, version } from "react";
import { Box, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, CircularProgress, DialogActions, TextField, } from "@mui/material";
import ApprovalIcon from "@mui/icons-material/Approval";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from '@mui/x-data-grid';


const Monitorpolicy = () => {
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [substituteUser, setSubstituteUser] = useState("");
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [reason, setReason] = useState("Substitution due to unavailable");


  const loggedinUser = JSON.parse(sessionStorage.getItem('loggedinUser'));
  const roleId = loggedinUser.role_id;
  const departmentId = loggedinUser.department_id
  const userrole = sessionStorage.getItem("loggedinUserRole");
  const navigate = useNavigate();

  const status = [
            "Created",
            "Revision Requested",
            "Assigned to Director",
            "Assigned to Senior Leader",
             "Assigned to Quality Director",
             "Approved by Quality Director",
          ];

  useEffect(() => {
      const statusParam = status.map(encodeURIComponent).join("&statuses=");
      const endpoint = userrole === "Administrator" 
      ? `${ApiUrls.POLICY_API}/by/admin/statuses?statuses=${statusParam}`
      : `${ApiUrls.POLICY_API}/by/statuses/${departmentId}?statuses=${statusParam}`;
    axiosInstance.get(endpoint)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching policies:", error);
      });
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleFilterModelChange = debounce((newModel) => {
    setFilterModel(newModel);
  }, 100);

  const handleViewClick = (Id) => {
    const data = { routeName: "Monitor Process", routeUrl: "monitorProcess", nextRouteName:"Policy Details" };
    navigate(`/policydetails?policy_id=${Id}`, { state: data });
  };

  const handleViewFlow = (Id) => {
    const data = { routeName: "Monitor Process", routeUrl: "monitorProcess", nextRouteName:"Policy Flow" };
    navigate(`/policyflow?policy_id=${Id}`,{ state: data });
  };

  const handleUnReserve = (policyId) => {
    const policyPayload = {
     reserved_by:-1,
    };
    axiosInstance.put(ApiUrls.POLICY_API + "/" + policyId, policyPayload)
      .then((response) => {
        setPolicies((prevPolicies) =>
          prevPolicies.map((policy) =>
            policy.policy_id === policyId ? { ...policy, reserved_by: -1 } : policy
          )
        );
      })
      .catch((error) => {
        console.error("Error resetting policy:", error);
      });
  };

  const fetchUsers = (roleId, departmentId) => {
    setLoadingUsers(true);
    axiosInstance.get(ApiUrls.USER_API + "/byrole/substitute/beforeroles/" + roleId + "/department/" + departmentId)
      .then((response) => {
        setUsers(response.data);
        setLoadingUsers(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoadingUsers(false);
      });
  };

  const handleDialogOpen = (policy) => {
    setSelectedPolicyId(policy.policy_id);
    setSelectedPolicy(policy); 
    setOpenDialog(true);
  
    let roleId = 0;
    switch (policy.policy_status) {
      case "Assigned to Director":
        roleId = 2;
        break;
      case "Assigned to Senior Leader":
        roleId = 3;
        break;
      case "Assigned to Quality Director":
        roleId = 4;
        break;
      default:
        roleId = 1; 
        break;
    }
    const departmentId = policy.department_id;
    fetchUsers(roleId, departmentId);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSubstituteUser(""); 
  };


  const handleMakeSubstitue = () => {
    const policy = selectedPolicy; 
    const policyId = policy.policy_id
    const currentDate = new Date().toISOString();

    const payload = {
      original_assignee_department_id: policy.department_id,
      substitute_assignee_department_id: substituteUser.department_id, 
      // original_assignee_user_id: 207,
      // original_assignee_role_id: 10,
      substitute_assignee_role_id: substituteUser.role_id,
      substitute_assignee_user_id: substituteUser.user_id,
      substitute_at: currentDate,
      substitute_by: loggedinUser.user_id,
      reason: reason, 
      policy_status: policy.policy_status,
    };
    axiosInstance.post(ApiUrls.POLICY_API + "/substitute/assign/"+ policyId, payload)
      .then((response) => {
        setOpenDialog(false);
        setPolicies((prevPolicies) =>
          prevPolicies.map((policy) =>
            policy.policy_id === policyId ? { ...policy, role_substitute: 1} : policy
          )
        );
      })
      .catch((error) => {
        console.error("Error resetting policy:", error);
      });

  }

  const columns = [
    {
      field: "policy_name",
      headerName: <strong> Policy Name</strong>,
      width: 200,
      filterable: true,
    },
    {
      field: "department_name",
      headerName: <strong> Primary Department</strong>,
      width: 150,
      filterable: true,
    },
    {
      field: "all_department_names",
      headerName: <strong> Departments Involved</strong>,
      width: 200,
      filterable: true,
    },
    {
      field: "policy_status",
      headerName: <strong>Status</strong>,
      width: 150,
      filterable: true,
    },
    {
      field: "action",
      headerName: <strong> Action</strong>,
      width: 500,
      headerAlign: "center",
      renderCell: (params) => (
        <div>

          <Button
            onClick={() => handleViewClick(params.row.policy_id)}
            sx={{
              // height: "40px",
              marginTop: "10px",
              fontSize: "12px",
              marginRight: "9px",
              marginBottom: "10px",
              width: "100px",
              backgroundColor: "#5a8a9a33",
              color: "#1D3557",
              fontFamily: "figtree",
              fontWeight: "bold",
              border: "2px solid transparent",
              '&:hover': {
                backgroundColor: "#5a8a9a66",
                border: "2px solid #1D3557",
              },
            }}
          >
            View Policy
          </Button>

          <Button
            onClick={() => handleViewFlow(params.row.policy_id)}
            sx={{
              // height: "40px",
              marginTop: "10px",
              fontSize: "12px",
              marginRight: "9px",
              marginBottom: "10px",
              width: "95px",
              backgroundColor: "#5a8a9a33",
              color: "#1D3557",
              fontFamily: "figtree",
              fontWeight: "bold",
              border: "2px solid transparent",
              '&:hover': {
                backgroundColor: "#5a8a9a66",
                border: "2px solid #1D3557",
              },
            }}
          >
            View Flow
          </Button>

          {userrole === 'Administrator' && (
            params.row.policy_status !== 'Published' && params.row.policy_status !== 'Expired' && params.row.policy_status !=='Approved by Quality Director' && (
              <>
                {params.row.role_substitute === 1 ? (
                   <Button
                   sx={{
                     marginTop: "10px",
                     fontSize: "12px",
                     marginRight: "9px",
                     marginBottom: "10px",
                     width: "140px",
                     backgroundColor: "#5a8a9a33",
                     color: "#1D3557",
                     fontFamily: "figtree",
                     fontWeight: "bold",
                     border: "2px solid transparent",
                     '&:hover': {
                       backgroundColor: "#5a8a9a66",
                       border: "2px solid #1D3557",
                     },
                   }}
                   disabled
                 >
                   Substituted
                 </Button>
                  ) : (
                    <Button
                      onClick={() => handleDialogOpen(params.row)}
                      sx={{
                        marginTop: "10px",
                        fontSize: "12px",
                        marginRight: "9px",
                        marginBottom: "10px",
                        width: "140px",
                        backgroundColor: "#5a8a9a33",
                        color: "#1D3557",
                        fontFamily: "figtree",
                        fontWeight: "bold",
                        border: "2px solid transparent",
                        '&:hover': {
                          backgroundColor: "#5a8a9a66",
                          border: "2px solid #1D3557",
                        },
                      }}
                    >
                      Assign Substitute
                    </Button>
                  )}
               

                {params.row.reserved_by !== -1 && (
                  <Button 
                  onClick={() => handleUnReserve(params.row.policy_id)}
                  sx={{
                    // height: "40px",
                    marginTop: "10px",
                    fontSize: "12px",
                    marginRight: "9px",
                    marginBottom: "10px",
                    width: "95px",
                    backgroundColor: "#5a8a9a33",
                    color: "#1D3557",
                    fontFamily: "figtree",
                    fontWeight: "bold",
                    border: "2px solid transparent",
                    '&:hover': {
                      backgroundColor: "#5a8a9a66",
                      border: "2px solid #1D3557",
                    },
                  }}
                >
                  Unreserve
                </Button>
                )}

                {/* <Button
                    onClick={handleOpenUserDialog(params.row.policy_id)}
                  sx={{
                    // height: "40px",
                    marginTop: "10px",
                    fontSize: "12px",
                    marginRight: "9px",
                    marginBottom: "10px",
                    width: "95px",
                    backgroundColor: "#5a8a9a33",
                    color: "#1D3557",
                    fontFamily: "figtree",
                    fontWeight: "bold",
                    border: "2px solid transparent",
                    '&:hover': {
                      backgroundColor: "#5a8a9a66",
                      border: "2px solid #1D3557",
                    },
                  }}
                >
                  Edit Assignement
                </Button> */}
              </>
            )
          )}
        </div>
      ),
    },

  ];

  return (
    <div>

      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "figtree"
            }}
          >
            Monitor Process
          </li>
        </ol>
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "8px",
            fontSize: "32px",
            fontWeight: "bold",
            fontFamily: "figtree",
            marginLeft: "-13px"
          }}
        >
          Monitor Process
        </h4>
      </div>

      <DataGrid
        rows={policies}
        columns={columns}
        initialState={{
          ...policies.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        slots={{ toolbar: GridToolbarQuickFilter }}
        getRowId={(row) => row.policy_id}
        classes={{
          overlayWrapper: "customOverlayWrapper",
        }}
        sx={{
          "& .customOverlayWrapper ": {
            height: "63px !important",
            fontFamily: "Figtree",
          },
          fontSize: "14px",
          fontFamily: "Figtree",
          borderRadius: "8px",
          boxShadow: 2,
          color: "#1D3557",
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: "#5a8a9a33",
            color: "#1D3557",
            fontFamily: "Figtree",
            fontWeight: "bold",
            fontSize: "16px",
          },
          "& .MuiDataGrid-cell": {
            backgroundColor: "white",
            color: "#1D3557",
            fontFamily: "Figtree",
            fontSize: "14px",
          },
          "& .css-1ka3pnz-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter":{
            width:"32%",
            left:"67%",
            top:"1px",
          }
        }}
        components={{
          Toolbar: () => (
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              <GridToolbarQuickFilter
                classes={{
                  toolbarQuickFilter: "customtoolbarQuickFilter",
                }}
                sx={{
                  position: "absolute",
                  right: 300,
                  top: "-30px",
                  width: "35%",
                }}
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
              />
            </div>
          ),

          NoRowsOverlay: () => (
            <div
              style={{
                position: "sticky",
                top: "50%",
                transform: "translate(-50%, 0%)",
                textAlign: "center",
                marginLeft: "45%",
              }}
            >
              No data found
            </div>
          ),
        }}
      />
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle sx={{ fontFamily:"figtree" }}>Make Substitute</DialogTitle>
        <DialogContent>
          <FormControl fullWidth  sx={{ mt:2}}>
            <InputLabel>User</InputLabel>
            <Select
              value={substituteUser}
              onChange={(e) => setSubstituteUser(e.target.value)}
              label="User"
              disabled={loadingUsers}
            >
              {loadingUsers ? (
                <MenuItem value="">
                  <CircularProgress size={24} />
                </MenuItem>
              ) : (
                users?.map((user) => (
                  <MenuItem key={user.user_id} value={user}>
                    {`${user?.first_name} ${user?.last_name} - ${user?.role_name} (${user?.department_name})`}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
            <Box
                      sx={{
                        margin: 2,
                        padding: 2,
                       
                        borderRadius: "8px",
                        position: "relative",
                      }}
                    >
                      <TextField
                        label="Add Reason here"
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Box>
          <Typography  sx={{ marginTop: "1px", fontFamily:"figtree" }}>
            Disclaimer: If this policy is currently reserved, it will be automatically unreserved when assigning a substitute.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleMakeSubstitue}
            color="primary"
            disabled={!substituteUser} // Disable if no user is selected
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Monitorpolicy;

