// import React, { useEffect, useState } from "react";
// import { Box, Typography, TextField, Button, Stack, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
// import { AccessTime, CalendarMonth as CalendarMonthIcon, Map, Person, } from "@mui/icons-material";
// import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
// import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

// const AuditScreen = () => {
//   useAuthHeaders();
//   const [auditData, setAuditData] = useState([]);
//   const [filterProps, setFilterProps] = useState({
//     loginUserRole: "",
//     fromActionDate: "",
//     toActionDate: "",
//     eventInfo: "",
//     entityName: "",
//     loginUsername:"",
//   });
//   const [error, setError] = useState("");

//   useEffect(() => {
//     axiosInstance
//       .get(ApiUrls.AUDIT_API)
//       .then((response) => {
//         setAuditData(response.data);
//       })
//       .catch(() => {
//         setError("Error while fetching Audit data, Please try again")
//       });
//   }, []);

//   const handleSearch = () => {
//     axiosInstance.post(ApiUrls.AUDIT_API + "/filters", filterProps)
//       .then((response) => {
//         setAuditData(response.data);
//       })
//       .catch(() => {
//         setError("Error while applaying filters, Please try again")
//       });
//   };

//   const handleResetFilters = () => {
//     setFilterProps({
//       loginUserId: 0,
//       loginUserRole: '',
//       fromActionDate: '',
//       toActionDate: '',
//       eventInfo: '',
//       entityName: '',
//       loginUsername:'',
//     });

//     axiosInstance.get(ApiUrls.AUDIT_API)
//       .then((response) => {
//         setAuditData(response.data);
//       })
//       .catch(() => {
//         setError("Error while fetching Audit data, Please try again")
//       });
//   };

//   const highlightText = (text, keyword) => {
//     if (!keyword) return text;

//     const regex = new RegExp(`(${keyword})`, 'gi');
//     const parts = text.split(regex);
//     return parts.map((part, index) =>
//       part.toLowerCase() === keyword.toLowerCase() ? (
//         <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };

//   const formatDate = (date) => new Date(date).toLocaleString();
//   const formatTime = (date) => new Date(date).toLocaleTimeString();

//   const groupEventsByDate = (data) => {
//     return data.reduce((acc, event) => {
//       const eventDate = formatDate(event.action_time).split(",")[0];
//       if (!acc[eventDate]) {
//         acc[eventDate] = [];
//       }
//       acc[eventDate].push(event);
//       return acc;
//     }, {});
//   };

//   const renderEventInfo = (method, event_info, entityName) => {
//     const renderObjectFields = (obj) => {
//       if(obj)
//         return Object.keys(obj).map((key) => {
//           const value = obj[key];
//           return (
//             <Typography variant="body2" color="textSecondary" key={key}>
//               {key} - <strong>{typeof value === 'object' ? JSON.stringify(value) : value}</strong>.
//             </Typography>
//           );
//         });
//         else return null;
//     };

//     switch (method) {
//       case "POST":
//         const newFields = renderObjectFields(event_info.responseBody);
//         return (
//           <Box>
//             <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
//               Added New {entityName}
//             </Typography>
//             {newFields}
//           </Box>
//         );

//       case "PUT":
//         const changes = Object.keys(event_info.before_update || {}).map((key) => {
//           const beforeValue = event_info.before_update[key];
//           const afterValue = event_info.after_update[key];
//           return (
//             <Typography variant="body2" color="textSecondary" key={key}>
//               {key} was changed from <strong>{typeof beforeValue === 'object' ? JSON.stringify(beforeValue) : beforeValue}</strong> to <strong>{typeof afterValue === 'object' ? JSON.stringify(afterValue) : afterValue}</strong>.
//             </Typography>
//           );
//         });
//         return <Box>{changes}</Box>;

//       case "DELETE":
//         const deletedFields = renderObjectFields(event_info.responseBody);
//         return (
//           <Box>
//             <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
//               Deleted {entityName}
//             </Typography>
//             {deletedFields}
//           </Box>
//         );

//       default:
//         return <Typography variant="body2" color="textSecondary">Event details not available.</Typography>;
//     }
//   };

//   const groupedData = groupEventsByDate(auditData);

//   return (
//     <div>
//       {error ? (
//          <Box sx={{ color: 'red', padding: 2 ,display:"flex", justifyContent:"center" }}>
//           <Typography variant="body1" color="error">{error}</Typography>
//         </Box>
//       ) : (
//         <>
//       <Typography variant="h5" sx={{ marginBottom: 3, color: "#3f6677" }}>
//         Audit
//       </Typography>

//       {/* Filter Section */}
//       <Box sx={{ marginBottom: 3 }}>
//         <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ flexWrap: 'wrap', }}>
//           <Box sx={{ width: { xs: '100%', sm: '48%', md: '20%' } }}>
//             <TextField
//               label="Action By Roles"
//               variant="outlined"
//               size="small"
//               value={filterProps.loginUsername}
//               onChange={(e) => setFilterProps({ ...filterProps, loginUsername: e.target.value })}
//               fullWidth
//             />
//           </Box>

//           <Box sx={{ width: { xs: '100%', sm: '48%', md: '24%' } }}>
//             <TextField
//               label="Action On "
//               variant="outlined"
//               size="small"
//               value={filterProps.entityName}
//               onChange={(e) => setFilterProps({ ...filterProps, entityName: e.target.value })}
//               fullWidth
//             />
//           </Box>
//           <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', sm: '48%', md: '24%' } }}>
//             <TextField
//               label="From Date"
//               type="date"
//               size="small"
//               sx={{ marginRight: 1 }}
//               fullWidth
//               InputLabelProps={{ shrink: true }}
//               value={filterProps.fromActionDate}
//               onChange={(e) => setFilterProps({ ...filterProps, fromActionDate: e.target.value })}
//             />
//           </Box>
//           <Box sx={{ width: { xs: '100%', sm: '48%', md: '24%' } }}>
//             <TextField
//               label="To Date"
//               type="date"
//               size="small"
//               fullWidth
//               InputLabelProps={{ shrink: true }}
//               value={filterProps.toActionDate}
//               onChange={(e) => setFilterProps({ ...filterProps, toActionDate: e.target.value })}
//             />
//           </Box>
//         </Stack>

//         <Stack direction={{ sm: 'row' }} spacing={2} sx={{ marginTop: 2 }} sm={{ marginTop: 2, flexWrap: 'wrap', }}>
//           <Button variant="outlined" sx={{ color: '#7da1b2', }} onClick={handleResetFilters} >Reset Filter</Button>
//           <Button variant="contained" sx={{ backgroundColor: '#7da1b2', }} onClick={handleSearch} >Search</Button>
//         </Stack>
//       </Box>

//       {/* Main Content */}
//       <div>
//         <Box sx={{ backgroundColor: "white", }} >
//         {auditData.length === 0 ? (
//             <Box sx={{ padding: 2 ,display:"flex", justifyContent:"center"}}>
//               <Typography variant="h6" color="textSecondary">No Data Found</Typography>
//             </Box>
//           ) : (
//           Object.keys(groupedData).map((date, index) => (
//             <div key={index}>
//               <div style={{ width: "30%", backgroundColor: "rgb(186 189 190)", padding: "8px", borderRadius: "4px", textAlign: "center", color: "white", fontWeight: "bold" }}>
//                 {date}
//               </div>
//               {groupedData[date].map((section, idx) => (
//                 <div style={{ display: "flex", flexDirection: "row" }}>

//                   {/* audit logs */}
//                   <Box sx={{ width: { xs: '60%', sm: '50%', md: '30%' }, display: "flex", justifyContent: "center", padding: "8px", borderRight: "3px solid #b8b4b4", marginBottom: 1, marginTop: 1, flexDirection: "column" }}>
//                     <div style={{ display: "flex", alignItems: "center", padding: "8px", marginBottom: 1, alignSelf: "flex-start", }}>
//                       <Person fontSize="small" sx={{ marginRight: "8px" }} />
//                       <Typography variant="body1">{highlightText(section?.login_username, filterProps.loginUsername)}</Typography>
//                     </div>
//                     <div style={{ display: "flex", alignItems: "center", padding: "8px", marginBottom: 1, alignSelf: "flex-start", }}>
//                       <AccessTime fontSize="small" sx={{ marginRight: "8px" }} />
//                       <Typography variant="body2">{formatTime(section?.action_time)}</Typography>
//                     </div>
//                     <div style={{ display: "flex", alignItems: "center", padding: "8px", marginBottom: 1, alignSelf: "flex-start", }}>
//                       <Map fontSize="small" sx={{ marginRight: "8px" }} />
//                       <Typography variant="body3">{highlightText(section?.entityName, filterProps.entityName)}</Typography>
//                     </div>
//                   </Box>

//                   {/* audit Content */}
//                   <Box sx={{ width: { xs: '40%', sm: '50%', md: '70%' }, padding: "16px", display: "flex", alignItems: "center", boxSizing: "border-box" }}>
//                     {renderEventInfo(JSON.parse(section?.event_info)?.method, JSON.parse(section?.event_info), section?.entityName)}
//                   </Box>
//                 </div>
//               ))}
//             </div>
//           ))
//         )}
//         </Box>
//       </div>
//       </>
//       )}
//     </div>
//   );
// };

// export default AuditScreen;

import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Stack, MenuItem, FormControl, InputLabel, Select,} from "@mui/material";
import { AccessTime, CalendarMonth as CalendarMonthIcon, Map, Person,} from "@mui/icons-material";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

const AuditScreen = () => {
  useAuthHeaders();
  const [auditData, setAuditData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterProps, setFilterProps] = useState({
    loginUserRole: "",
    fromActionDate: "",
    toActionDate: "",
    eventInfo: "",
    entityName: "",
    loginUsername: "",
    policy_id: 0,
    location_id: 0,
    department_id: 0,
  });
  const [actionDates, setActionDates] = useState({
    fromActionDate: "",
    toActionDate: "",
  });
  // console.log(filterProps,"filterProps");

  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState("");
  const [subDropdownVisible, setSubDropdownVisible] = React.useState(false);
  const [mainDropdownVisible, setMainDropdownVisible] = React.useState(true);

  useEffect(() => {
    fetchAuditData();
  }, []);

  const fetchAuditData = () => {
    axiosInstance
      .get(ApiUrls.AUDIT_API)
      .then((response) => {
        setAuditData(response.data);
        setFilteredData(response.data);
      })
      .catch(() => {
        setError("Error while fetching Audit data, Please try again");
      });
  };

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_API)
      .then((response) => setPolicies(response.data))
      .catch(() => setError("Error while fetching policies"));
  }, []);

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.DEPARTMENT_API)
      .then((response) => setDepartments(response.data))
      .catch(() => setError("Error while fetching departments"));
  }, []);

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.LOCATION_API)
      .then((response) => setLocations(response.data))
      .catch(() => setError("Error while fetching locations"));
  }, []);

  const handleSearch = () => {
    axiosInstance
      .post(ApiUrls.AUDIT_API + "/filters", filterProps)
      .then((response) => {
        setFilteredData(response.data);
        if (response.data.length === 0) {
          setError("No results found for the applied filters.");
        } else {
          setError("");
        }
      })
      .catch(() => {
        setError("Error while applying filters, Please try again");
      });
  };

  const handleResetFilters = () => {
    setFilterProps({
      loginUserId: 0,
      loginUserRole: "",
      fromActionDate: "",
      toActionDate: "",
      eventInfo: "",
      entityName: "",
      loginUsername: "",
    });
    setSubDropdownVisible(false);
    setMainDropdownVisible(true);
    fetchAuditData();
  };

  const groupEventsByDate = (data) => {
    return data.reduce((acc, event) => {
      const eventDate = new Date(event.action_time).toLocaleDateString();
      if (!acc[eventDate]) {
        acc[eventDate] = [];
      }
      acc[eventDate].push(event);
      return acc;
    }, {});
  };

  const groupedData = groupEventsByDate(filteredData);

  const renderEventInfo = (method, event_info, entityName) => {
    const renderObjectFields = (obj) => {
      if (obj) {
        return Object.keys(obj).map((key) => {
          const value = obj[key];
          return (
            <Typography variant="body2" color="textSecondary" sx={{wordBreak:"break-word",overflowWrap:"break-word"}} key={key}>
              {highlightText(key, filterProps.eventInfo)} -{" "}
              <strong>
                {typeof value === "object"
                  ? JSON.stringify(value)
                  : highlightText(value.toString(), filterProps.eventInfo)}
              </strong>
              .
            </Typography>
          );
        });
      } else return null;
    };

    switch (method) {
      case "POST":
        const newFields = renderObjectFields(event_info.responseBody);
        return (
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              Added New {highlightText(entityName, filterProps.entityName)}
            </Typography>
            {newFields}
          </Box>
        );

      case "PUT":
        const changes = Object.keys(event_info.before_update || {}).map(
          (key) => {
            const beforeValue = event_info.before_update[key];
            const afterValue = event_info.after_update[key];
            return (
              <Typography variant="body2" color="textSecondary" key={key}>
                {highlightText(key, filterProps.eventInfo)} was changed from{" "}
                <strong>
                  {highlightText(
                    typeof beforeValue === "object"
                      ? JSON.stringify(beforeValue)
                      : beforeValue,
                    filterProps.eventInfo
                  )}
                </strong>{" "}
                to{" "}
                <strong>
                  {highlightText(
                    typeof afterValue === "object"
                      ? JSON.stringify(afterValue)
                      : afterValue,
                    filterProps.eventInfo
                  )}
                </strong>
                .
              </Typography>
            );
          }
        );
        return <Box>{changes}</Box>;

      case "DELETE":
        const deletedFields = renderObjectFields(event_info.responseBody);
        return (
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              Deleted {highlightText(entityName, filterProps.entityName)}
            </Typography>
            {deletedFields}
          </Box>
        );

      default:
        return (
          <Typography variant="body2" color="textSecondary">
            Event details not available.
          </Typography>
        );
    }
  };

  const highlightText = (text, keyword) => {
    if (!keyword || !text) return text;

    const regex = new RegExp(`(${keyword})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === keyword.toLowerCase() ? (
        <span
          key={index}
          style={{ backgroundColor: "yellow", fontWeight: "bold" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return  (
    <div
      style={{ padding: "5px", width: "100vw",   maxWidth: "100%", 
        overflowX: "hidden",
        boxSizing: "border-box"  }}
    >
      {error && (
        <Box
          sx={{
            color: "red",
            padding: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        </Box>
      )}
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -17px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "Figtree",
            }}
          >
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "Figtree",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "inline-block",
              color: "#1D3557",
              fontWeight: "bold",
              fontFamily: "Figtree",
            }}
          >
            Audit
          </li>
        </ol>
      </div>
      <Typography variant="h4" sx={{ color: "#3f6677", fontFamily: "Figtree" }}>
        Audit
      </Typography>

      {/* Filter Section */}
      <Box sx={{ marginBottom: 3 , marginTop:2}}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ flexWrap: "wrap" }}
        >
          {/* Action By Roles Dropdown */}
          <FormControl
            fullWidth
            size="small"
            sx={{ width: { xs: "100%", sm: "48%", md: "20%" } }}
          >
            <InputLabel id="action-by-roles-label">Action By Roles</InputLabel>
            <Select
              labelId="action-by-roles-label"
              value={filterProps.loginUserRole}
              onChange={(e) =>
                setFilterProps({
                  ...filterProps,
                  loginUserRole: e.target.value,
                })
              }
              label="Action By Roles"
            >
              <MenuItem value="administrator">Administrator</MenuItem>
              <MenuItem value="director">Director</MenuItem>
              <MenuItem value="senior leader">Senior Leader</MenuItem>
              <MenuItem value="quality director">Quality Director</MenuItem>
            </Select>
          </FormControl>

          {/* Action On Dropdown */}
          {mainDropdownVisible && (
            <FormControl
              fullWidth
              size="small"
              sx={{ width: { xs: "100%", sm: "48%", md: "20%" } }}
            >
              <InputLabel id="action-on">Action On</InputLabel>
              <Select
                labelId="action-on"
                value={filterProps.entityName}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setFilterProps({
                    ...filterProps,
                    entityName: selectedValue,
                    selectedEntity: "",
                  });
                  setSubDropdownVisible(true);
                  setMainDropdownVisible(false);
                }}
                label="Action On"
              >
                <MenuItem value="policy">Policy</MenuItem>
                <MenuItem value="department">Department</MenuItem>
                <MenuItem value="location">Location</MenuItem>
              </Select>
            </FormControl>
          )}

          {subDropdownVisible && filterProps.entityName && (
            <FormControl
              fullWidth
              size="small"
              sx={{ width: { xs: "100%", sm: "48%", md: "20%" } }}
            >
              <InputLabel id="dynamic-dropdown-label">{`Select ${filterProps.entityName}`}</InputLabel>
              <Select
                labelId="dynamic-dropdown-label"
                value={filterProps.selectedEntity}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setFilterProps({
                    ...filterProps,
                    selectedEntity: selectedValue, // Track selected entity
                    // Set the corresponding entity ID based on selection
                    ...(filterProps.entityName === "policy" && {
                      policy_id: selectedValue,
                    }),
                    ...(filterProps.entityName === "department" && {
                      department_id: selectedValue,
                    }),
                    ...(filterProps.entityName === "location" && {
                      location_id: selectedValue,
                    }),
                  });
                  console.log(
                    `${filterProps.entityName} Selected:`,
                    selectedValue
                  );
                }}
                label={`Select ${filterProps.entityName}`} // Explicitly set the label
              >
                {filterProps.entityName === "policy" &&
                  policies.map((policy) => (
                    <MenuItem key={policy.id} value={policy.policy_id}>
                      {policy.policy_name}
                    </MenuItem>
                  ))}
                {filterProps.entityName === "department" &&
                  departments.map((department) => (
                    <MenuItem
                      key={department.id}
                      value={department.department_id}
                    >
                      {department.department_name}
                    </MenuItem>
                  ))}
                {filterProps.entityName === "location" &&
                  locations.map((location) => (
                    <MenuItem key={location.id} value={location.location_id}>
                      {location.location_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          {/* Date Fields */}
          {/* <TextField
            label="From Date"
            type="date"
            size="small"
            sx={{ marginRight: 1, width: { xs: "100%", sm: "48%", md: "24%" } }}
            InputLabelProps={{ shrink: true }}
            value={filterProps.fromActionDate}
            onChange={(e) =>
              setFilterProps({ ...filterProps, fromActionDate: e.target.value })
            }
          />   */}

          <Box>
            {/* <Typography> From Date</Typography> */}
            <DatePicker
              selected={filterProps.fromActionDate} // Ensure this is a Date object, not a string
              onChange={(date) => {
                setFilterProps({ ...filterProps, fromActionDate: date }); // Handle Date object
              }}
              dateFormat="MM/dd/yyyy"
              placeholderText="From Date"
              className="datepicker"
              maxDate={new Date()} 
              showYearDropdown
              showMonthDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={50}
            />
          </Box>
          {/* <TextField
            label="To Date"
            type="date"
            size="small"
            sx={{ width: { xs: "100%", sm: "48%", md: "24%" } }}
            InputLabelProps={{ shrink: true }}
            value={filterProps.toActionDate}
            onChange={(e) =>
              setFilterProps({ ...filterProps, toActionDate: e.target.value })
            }
          /> */}

          <DatePicker
            selected={filterProps.toActionDate}
            onChange={(date) =>
              setFilterProps({ ...filterProps, toActionDate: date })
            }
            dateFormat="MM/dd/yyyy"
            placeholderText="To Date"
            className="datepicker"
            maxDate={new Date()} 
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
          />
        </Stack>

        <Stack
          direction={{ sm: "row" }}
          spacing={2}
          sx={{ marginTop: 2, flexWrap: "wrap" }}
        >
          <Button
            variant="outlined"
            sx={{ color: "#7da1b2" }}
            onClick={handleResetFilters}
          >
            Reset Filter
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#7da1b2" }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Stack>
      </Box>

      {/* Main Content */}
      <Box sx={{ backgroundColor: "white" }}>
        {filteredData.length === 0 ? (
          <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
            <Typography variant="h6" color="textSecondary">
              No Data Found
            </Typography>
          </Box>
        ) : (
          Object.keys(groupedData).map((date, index) => (
            <div key={index}>
              <div
                style={{
                  width: "30%",
                  backgroundColor: "rgb(186 189 190)",
                  padding: "8px",
                  borderRadius: "4px",
                  textAlign: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {date}
              </div>
              {groupedData[date].map((section, idx) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={idx}
                >
                  {/* Audit Logs */}
                  <Box
                    sx={{
                      width: { xs: "60%", sm: "50%", md: "30%" },
                      display: "flex",
                      justifyContent: "center",
                      padding: "8px",
                      borderRight: "3px solid #b8b4b4",
                      marginBottom: 1,
                      marginTop: 1,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px",
                        marginBottom: 1,
                      }}
                    >
                      <Person fontSize="small" sx={{ marginRight: "8px" }} />
                      <Typography variant="body1">
                        {highlightText(
                          section?.login_username,
                          filterProps.loginUserRole // Use loginUserRole for highlighting
                        )}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px",
                        marginBottom: 1,
                      }}
                    >
                      <AccessTime
                        fontSize="small"
                        sx={{ marginRight: "8px" }}
                      />
                      <Typography variant="body2">
                        {new Date(section?.action_time).toLocaleTimeString()}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px",
                        marginBottom: 1,
                      }}
                    >
                      <Map fontSize="small" sx={{ marginRight: "8px" }} />
                      <Typography variant="body3">
                        {highlightText(
                          section?.entityName,
                          filterProps.entityName
                        )}
                      </Typography>
                    </div>
                  </Box>
                  {/* Audit Content */}
                  <Box
                    sx={{
                      width: { xs: "40%", sm: "50%", md: "70%" },
                      padding: "16px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    {renderEventInfo(
                      JSON.parse(section?.event_info)?.method,
                      JSON.parse(section?.event_info),
                      section?.entityName
                    )}
                  </Box>
                </div>
              ))}
            </div>
          ))
        )}
      </Box>
      <style jsx>{`
        .datepicker {
          width: 250px;
          height: 40px;
          padding: 30px;
          font-size: 16px;
          border: 10px solid black;
          border-radius: 30px;
        }
      `}</style>
    </div>
  );
};

export default AuditScreen;
