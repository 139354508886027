import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {
  Card,
  Typography,
  CardActions,
  Button,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { red } from "@mui/material/colors";

const PolicyList = () => {
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [favorites, setFavorites] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const typeId = searchParams.get("type_id");

  const userId = sessionStorage.getItem("loggedinUserId");

  useEffect(() => {
    if (typeId) {
      axiosInstance
        .get(ApiUrls.ASSIGNMENT_API + "/userId/" + typeId)
        .then((response) => {
          const policiesData = response.data;
          setPolicies(policiesData);

          // Update favorites state based on is_favourite from the response
          // const initialFavorites = {};
          // policiesData.forEach((policy) => {
          //   initialFavorites[policy.policy_id] = policy.is_favourite;
          // });
          // setFavorites(initialFavorites);

          setLoading(false);
        })
        .catch(() => {
          setError("An error occurred while fetching the policies.");
          setLoading(true);
        });
    }
  }, [typeId]);

  const toggleStar = async (policy) => {
    try {
      if (!policy?.is_favourite == 1) {
        const requestBody1 = {
          // user_id: userId,
          // policy_id:policy?.policy_id,
          // created_by: userId,
          // created_at: new Date(),
          is_favourite: true,
        };
        const response = await axiosInstance.put(
          ApiUrls.ASSIGNMENT_API + "/" + policy.assignment_id,
          requestBody1
        );
        setPolicies((prevPolicies) =>
          prevPolicies.map((p) =>
            p.assignment_id === policy.assignment_id
              ? { ...p, is_favourite: 1 }
              : p
          )
        );
      } else {
        const requestBody2 = {
          // user_id: userId,
          // policy_id:policy?.policy_id,
          // created_by: userId,
          // created_at: new Date(),
          is_favourite: false,
        };
        await axiosInstance.put(
          ApiUrls.ASSIGNMENT_API + "/" + policy.assignment_id,
          requestBody2
        );
        setPolicies((prevPolicies) =>
          prevPolicies.map((p) =>
            p.assignment_id === policy.assignment_id
              ? { ...p, is_favourite: 0 }
              : p
          )
        );
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const renderFileIcon = (fileType) => (
    <PictureAsPdfIcon sx={{ fontSize: 40, color: "#d32f2f" }} />
  );

  const handleCardClick = (policy) => {
    const policyId = policy.policy_id;
    const assignmentId = policy.assignment_id;
    axiosInstance
      .put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, {
        last_visited: new Date(),
      })
      .then(() => {
        const data = { routeName: "Policy List", routeUrl: "policylist", nextRouteName:"Policy Details" };
        navigate( `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`, { state: data });
       })
      .catch(() => {
        const data = { routeName: "Policy List", routeUrl: "policylist", nextRouteName:"Policy Details" };
        navigate( `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`, { state: data });
      });
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px 3px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree" }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>/</li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>
            <Link
              to="/browsemanuals"
              style={{ textDecoration: "none", color: "#1D3557" , fontFamily:"figtree"}}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Browse Manuals
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold", 
              fontFamily:"figtree"
            }}
          >
            Policy List
          </li>
        </ol>
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "8px",
            fontSize: "32px",
            fontFamily: "figtree",
          }}
        >
          Policy List
        </h4>
      </div>

      {/* <h2>{policies[0]?.type_name}</h2> */}

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}

      {error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h5" color="error">
            {error}
          </Typography>
        </Box>
      )}

      {!loading && !error && policies.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h5" color="textSecondary">
            No policies are assigned from this type.
          </Typography>
        </Box>
      )}

      {!loading && !error && policies.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            padding: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {policies.map((policy) => (
            <Card
              key={policy.assignment_id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(policy)}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                }}
              >
                {renderFileIcon(policy.file_type)}
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#1D3557",
                    marginBottom: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  {policy.policy_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="#1D3557"
                  sx={{
                    marginBottom: "8px",
                    width: "82%",
                    fontFamily: "figtree",
                  }}
                >
                  {policy.policy_description}
                </Typography>
                <Typography
                  variant="body2"
                  color="#1D3557"
                  sx={{ fontFamily: "figtree" }}
                >
                  <strong>Expiration Date:</strong>{" "}
                  {new Date(policy.expiration_date).toLocaleDateString(
                    "en-US",
                    {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    }
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  padding: "20px",
                  // marginLeft: "16px",
                }}
              >
                <Tooltip
                  // title={
                  //   policy.is_favourite === 1
                  //     ? "Remove from Favourite"
                  //     : "Add to Favourite"
                  // }
                >
                  {/* <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleStar(policy);
                  }}
                >
                  {policy.is_favourite === 1 ? (
                    <StarIcon sx={{ color: "#fdd835" }} />
                  ) : (
                    <StarBorderIcon sx={{ color: "#fdd835" }} />
                  )}
                </IconButton> */}
                </Tooltip>
                <CardActions sx={{ padding: 0 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ fontFamily: "figtree" }}
                  >
                    View
                  </Button>
                  <Button
                    variant="contained"
                    color={policy.is_favourite === 1 ? "success" : "error"} // Color changes based on favorite status
                    sx={{
                      fontFamily: "figtree",
                      backgroundColor:
                        policy.is_favourite === 1 ? "#5a8a9a33" : "#1D3557", // Override default colors
                      color: policy.is_favourite === 1 ? "#1D3557":"#fff"
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event propagation
                      toggleStar(policy); // Trigger the toggle function
                    }}
                  >
                    {policy.is_favourite === 1
                      ? "Unmark Favorite"
                      : "Mark as Favorite"}{" "}
                    {/* Dynamic text */}
                  </Button>
                </CardActions>
              </Box>
            </Card>
          ))}
        </Box>
      )}
    </div>
  );
};

export default PolicyList;
