import React, { useState } from "react";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Link, useNavigate } from "react-router-dom";
import { routers } from "../../Configurations/configurationUI";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import Temp from "../../Templats/Template";
import LocationsImport from "./LocationsImport";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const AddLocations = () => {
  useAuthHeaders();
  const userId = parseInt(sessionStorage.getItem("loggedinUserId"));
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    location_name: "",
    city: "",
    zip_code: "",
    state: "",
    country: "",
    address: "",
    location_status: "",
    created_at: new Date(),
    last_updated_at: "",
    created_by: userId,
    last_updated_by: 0,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const handlePlaceSelect = async (place) => {
    const placeId = place.value.place_id;

    try {
      const results = await geocodeByPlaceId(placeId);
      const addressComponents = results[0].address_components;

      let street = "";
      let city = "";
      let state = "";
      let zip_code = "";
      let country = "";

      addressComponents.forEach((component) => {
        const types = component.types;

        if (types.includes("street_number")) {
          street = `${component.long_name}`;
        }
        if (types.includes("route")) {
          street += ` ${component.long_name}`;
        }
        if (types.includes("locality")) {
          city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (types.includes("postal_code")) {
          zip_code = component.long_name;
        }
        if (types.includes("country")) {
          country = component.long_name;
        }
      });

      setFormData({
        ...formData,
        location_name: street.trim(),
        city,
        state,
        zip_code,
        country,
        address: results[0].formatted_address,
      });
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.location_name)
      newErrors.location_name = "Location name is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.zip_code) newErrors.zip_code = "Zipcode  is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.address) newErrors.address = "Address is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      axiosInstance
        .post(ApiUrls.LOCATION_API, formData)
        .then((response) => {
          setAlert({
            show: true,
            message: "Location added successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.locationslist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error adding user",
            severity: "warning",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
          }, 2000);
          setLoading(false);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
      setLoading(false);
    }
  };

  const handleClear = () => {
    setFormData({
      location_name: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      address: "",
    });
    setErrors({});
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "500px",
            zIndex: 100000000000,
          }}
        >
          {alert.message}
        </Alert>
      )}  
      <div
              style={{
                backgroundColor: "#f3f6f9",
                padding: "0px 20px",
              }}
            >
              <ol
                style={{
                  display: "flex",
                  listStyle: "none",
                  padding: 0,
                  margin: "2px 0 5px -13px",
                  fontSize: "14px",
                  color: "#6c757d",
                }}
              >
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  <Link
                    to="/home"
                    style={{ textDecoration: "none", color: "#1D3557",  fontFamily: "figtree", }} 
                    onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                    onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                  >
                    Home
                  </Link>
                </li> 
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  /
                </li>  
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                <Link
                    to="/locationslist"
                    style={{ textDecoration: "none", color: "#1D3557",  fontFamily: "figtree", }} 
                    onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                    onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                  >
                    Location list
                  </Link> 
                  </li>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  /
                </li>
                <li
                  style={{
                    display: "inline-block",
                    color: "#3f6677",
                    fontWeight: "bold",
                    fontFamily: "figtree",
                  }}
                >
                   Add Location
                </li> 
              </ol>
              <h4
                style={{
                  color: "#3f6677",
                  marginBottom: "30px",
                  fontSize: "32px",
                  // fontWeight: "bold",
                  fontFamily: "figtree",
                  marginLeft: "-13px",
                }}
              >
                Add Location
              </h4>
            </div>
      <div
        style={{
          // backgroundColor: "#E5F2FF", 
          backgroundColor: "#5a8a9a33",
          borderRadius: "15px",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          <h2
            style={{ fontWeight: "bold", color: "#1D3557", fontSize: "31px", fontFamily:"figtree"}}
          >
            Adding Locations is Essential.
          </h2>
          <p
            style={{
              backgroundColor: "white",
              borderRadius: "50px",
              padding: "1rem 2rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              marginTop: "1rem",
              fontSize: "16px",
              lineHeight: "1.5",
               fontFamily:"figtree",
              color: "#1D3557"
            }}
          >
            Ensure to add locations to streamline the process of managing
            Departments and Locations. This will help in organizing resources
            and managing Departments and Users.
          </p>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img
            src="https://www.lifewire.com/thmb/YBQuRMKxxhx3Zb3uJ1x-QOT6VsM=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Maplocation_-5a492a4e482c52003601ea25.jpg"
            alt="Location Image"
            style={{
              width: "50%",
              height: "auto",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              objectFit: "cover",
              clipPath: "polygon(19% 0%, 100% 0%, 100% 100%, 0% 100%)",
            }}
          />
        </div>
      </div>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          gap: "18px",
          marginTop: "60px",
        }}
      >
        <Typography  sx={{ mb: 2, fontWeight: 700 , fontSize:"18px", fontFamily:"figtree", color: "#1D3557" }}>
          <i
            className="fa fa-map-marker-alt"
            style={{ fontSize: "20px", color: "#1D3557", paddingRight: "15px" }}
          />
          Add Locations Here
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <TextField
          label="Address *"
          variant="outlined"
          name="address"
          value={formData.address}
          onChange={handleChange}
          multiline
          rows={1}
          error={!!errors.address}
          helperText={errors.address} 
          sx={{ width: "48%" }}
        />   */}

          <Box sx={{ width: "48%" }}>
            <FormControl fullWidth error={!!errors.address}>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                selectProps={{
                  placeholder: "Search Address...",
                  onChange: handlePlaceSelect,
                  styles: {
                    control: (provided) => ({
                      ...provided,
                      minHeight: "40px", // Adjust height
                      height: "30px", // Fixed height 
                      fontFamily:"figtree",
                      borderColor: !!errors.address
                        ? "#d32f2f"
                        : provided.borderColor,
                      "&:hover": {
                        borderColor: !!errors.address
                          ? "#d32f2f"
                          : provided.borderColor,
                      },
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      height: "40px", // Match control height
                      padding: "0 8px", // Adjust padding
                    }),
                    input: (provided) => ({
                      ...provided,
                      margin: "0px",
                      padding: "0px",
                      fontFamily:"figtree",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: "1rem",
                      fontFamily:"figtree", // Optional: adjust placeholder font size
                    }),
                    menu: (provided) => ({
                      ...provided,
                      opacity: 1, // Ensure full visibility
                      zIndex: 9999,
                      backgroundColor: "#ffff",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      fontFamily:"figtree",
                      // transition: "opacity 1s ease-in-out",
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "auto",
                      opacity: 1,
                      fontFamily:"figtree",
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["us"],
                  },
                }}
              />
              {!!errors.address && (
                <FormHelperText sx={{ fontFamily: "figtree", fontSize: "12px" }}>{errors.address}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <TextField
            label="Street *"
            variant="outlined"
            name="location_name"
            value={formData.location_name}
            onChange={handleChange}
            error={!!errors.location_name}
            helperText={errors.location_name}
            sx={{ width: "48%" }} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="City *"
            variant="outlined"
            name="city"
            value={formData.city}
            onChange={handleChange}
            error={!!errors.city}
            helperText={errors.city}
            sx={{ width: "48%" }} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
          <TextField
            label="Zip Code*"
            variant="outlined"
            name="zip_code"
            value={formData.zip_code}
            onChange={handleChange}
            error={!!errors.zip_code}
            helperText={errors.zip_code}
            sx={{ width: "48%" }} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="State *"
            variant="outlined"
            name="state"
            value={formData.state}
            onChange={handleChange}
            error={!!errors.state}
            helperText={errors.state}
            sx={{ width: "48%" }} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
          <TextField
            label="Country *"
            variant="outlined"
            name="country"
            value={formData.country}
            onChange={handleChange}
            error={!!errors.country}
            helperText={errors.country}
            sx={{ width: "48%" }} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained "
            onClick={handleClear}
            sx={{ backgroundColor: "#5a8a9a33", color: "#1D3557",fontFamily: "figtree", width: "48%" }}
          >
            Clear
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{  backgroundColor: "#3f6677",  color: "#fff",  fontFamily: "figtree",  width: "48%",  ml: 3, }}
          >
            Submit
          </Button>
        </div>
      </Box>
      <div
        style={{
          marginTop: "60px",
          marginBottom: "10px",
          paddingBottom: "30px",
        }}
      >
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "30px",
            fontSize: "32px",
            // fontWeight: "bold",
            fontFamily: "figtree",
            marginLeft: "13px",
          }}
        >
          Import Locations In Bulk
          </h4>
        <LocationsImport />
      </div>
    </div>
  );
};

export default AddLocations;

// import React, { useState } from "react";
// import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
// import { useNavigate } from "react-router-dom";
// import { routers } from "../../Configurations/configurationUI";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Alert,
// } from "@mui/material";
// import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
// import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

// const AddLocations = () => {
//   useAuthHeaders();
//   const userId = parseInt(sessionStorage.getItem("loggedinUserId"));
//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     location_name: "",
//     city: "",
//     zip_code: "",
//     state: "",
//     country: "",
//     address: "",
//     created_by: userId,
//   });

//   const [errors, setErrors] = useState({});
//   const [alert, setAlert] = useState({
//     message: "",
//     severity: "success",
//     show: false,
//   });

//   // Extract Address Components
//   const handlePlaceSelect = async (place) => {
//     const placeId = place.value.place_id;

//     try {
//       const results = await geocodeByPlaceId(placeId);
//       const addressComponents = results[0].address_components;

//       let street = "";
//       let city = "";
//       let state = "";
//       let zip_code = "";
//       let country = "";

//       addressComponents.forEach((component) => {
//         const types = component.types;

//         if (types.includes("street_number")) {
//           street = `${component.long_name}`;
//         }
//         if (types.includes("route")) {
//           street += ` ${component.long_name}`;
//         }
//         if (types.includes("locality")) {
//           city = component.long_name;
//         }
//         if (types.includes("administrative_area_level_1")) {
//           state = component.long_name;
//         }
//         if (types.includes("postal_code")) {
//           zip_code = component.long_name;
//         }
//         if (types.includes("country")) {
//           country = component.long_name;
//         }
//       });

//       setFormData({
//         ...formData,
//         location_name: street.trim(),
//         city,
//         state,
//         zip_code,
//         country,
//         address: results[0].formatted_address,
//       });
//     } catch (error) {
//       console.error("Error fetching place details:", error);
//     }
//   };

//   // Handle Form Submission
//   const handleSubmit = () => {
//     console.log("Submitting Form:", formData);
//     if (Object.values(formData).some((val) => val === "")) {
//       setAlert({
//         message: "Please fill out all required fields",
//         severity: "warning",
//         show: true,
//       });
//       return;
//     }

//     axiosInstance
//       .post(ApiUrls.LOCATION_API, formData)
//       .then(() => {
//         setAlert({
//           message: "Location added successfully!",
//           severity: "success",
//           show: true,
//         });
//         setTimeout(() => {
//           navigate(routers.locationslist);
//         }, 2000);
//       })
//       .catch(() => {
//         setAlert({
//           message: "Error adding location.",
//           severity: "error",
//           show: true,
//         });
//       });
//   };

//   return (
//     <div>
//       {alert.show && (
//         <Alert
//           variant="filled"
//           severity={alert.severity}
//           onClose={() => setAlert({ ...alert, show: false })}
//           style={{
//             position: "fixed",
//             top: "25%",
//             left: "50%",
//             transform: "translateX(-50%)",
//             maxWidth: "500px",
//           }}
//         >
//           {alert.message}
//         </Alert>
//       )}

//       <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
//         <Typography variant="h5">Add Location</Typography>

//         <GooglePlacesAutocomplete
//           apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
//           selectProps={{
//             placeholder: "Search Address...",
//             onChange: handlePlaceSelect,
//           }}
//         />

//         <TextField
//           label="Street"
//           name="location_name"
//           value={formData.location_name}
//           disabled
//         />
//         <TextField
//           label="City"
//           name="city"
//           value={formData.city}
//           disabled
//         />
//         <TextField
//           label="State"
//           name="state"
//           value={formData.state}
//           disabled
//         />
//         <TextField
//           label="Zip Code"
//           name="zip_code"
//           value={formData.zip_code}
//           disabled
//         />
//         <TextField
//           label="Country"
//           name="country"
//           value={formData.country}
//           disabled
//         />

//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <Button
//             variant="contained"
//             color="secondary"
//             onClick={() =>
//               setFormData({
//                 location_name: "",
//                 city: "",
//                 zip_code: "",
//                 state: "",
//                 country: "",
//                 address: "",
//               })
//             }
//           >
//             Clear
//           </Button>
//           <Button variant="contained" color="primary" onClick={handleSubmit}>
//             Submit
//           </Button>
//         </div>
//       </Box>
//     </div>
//   );
// };

// export default AddLocations;
