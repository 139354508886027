import { Router } from "./Routes/Routers";
import './App.css';

function App() { 
 
  return (
    <div>
      <Router/>
    </div>
  );
}

export default App;
