import React, { useState, useEffect } from "react";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { routers } from "../../Configurations/configurationUI";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Paper, TextField, Button, MenuItem, InputLabel, Select } from "@mui/material";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import { Alert, FormControl, FormHelperText } from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const EditLocations = () => {
  useAuthHeaders();
  const [formData, setFormData] = useState({
    location_name: "",
    city: "",
    zip_code: "",
    state: "",
    country: "",
    address: "",
    location_status: "",
  });

  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const [isLoading, setIsLoading] = useState(true); // Track loading state

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const locationId = queryParams.get("location_id");

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.LOCATION_API + "/" + locationId)
      .then((response) => {
        const data = response.data;
        setFormData({
          location_name: data.location_name || "",
          city: data.city || "",
          zip_code: data.zip_code || "",
          state: data.state || "",
          country: data.country || "",
          address: data.address || "",
          location_status: data.location_status || "",
        });
        setIsLoading(false); 
      })
      .catch((error) => {
        console.error("Error fetching location details:", error);
        setIsLoading(false);
      });
  }, [locationId]);

  const handlePlaceSelect = async (place) => {
    const placeId = place.value.place_id;

    try {
      const results = await geocodeByPlaceId(placeId);
      const addressComponents = results[0].address_components;

      let street = "";
      let city = "";
      let state = "";
      let zip_code = "";
      let country = "";

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) street = component.long_name;
        if (types.includes("route")) street += ` ${component.long_name}`;
        if (types.includes("locality")) city = component.long_name;
        if (types.includes("administrative_area_level_1"))
          state = component.long_name;
        if (types.includes("postal_code")) zip_code = component.long_name;
        if (types.includes("country")) country = component.long_name;
      });

      setFormData({
        ...formData,
        address: results[0].formatted_address,
        location_name: street.trim(),
        city,
        state,
        zip_code,
        country,
      });
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.location_name)
      newErrors.location_name = "Location name is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.zip_code) newErrors.zip_code = "Zip Code is required";
    if (!formData.location_status)
      newErrors.location_status = "Status is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      axiosInstance
        .put(ApiUrls.LOCATION_API + "/" + locationId, formData)
        .then(() => {
          setAlert({
            show: true,
            message: "Location updated successfully",
            severity: "success",
          });
          setTimeout(() => {
            navigate(routers.locationslist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error updating location",
            severity: "warning",
          });
          console.error("Error updating location:", error);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  // if (isLoading) {
  //   return <Typography>Loading...</Typography>; 
  // }

  return (
    <div style={{fontFamily: "Figtree"}}>  
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          sx={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )} 
      <div
                    style={{
                      backgroundColor: "#f3f6f9",
                      padding: "0px 20px",
                    }}
                  >
                    <ol
                      style={{
                        display: "flex",
                        listStyle: "none",
                        padding: 0,
                        margin: "2px 0 5px -13px",
                        fontSize: "14px",
                        color: "#6c757d",
                      }}
                    >
                      <li
                        style={{
                          display: "inline-block",
                          marginRight: "8px",
                          fontFamily: "figtree",
                        }}
                      >
                        <Link
                          to="/home"
                          style={{ textDecoration: "none", color: "#1D3557",  fontFamily: "figtree", }} 
                          onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                          onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                        >
                          Home
                        </Link>
                      </li> 
                      <li
                        style={{
                          display: "inline-block",
                          marginRight: "8px",
                          fontFamily: "figtree",
                        }}
                      >
                        /
                      </li>  
                      <li
                        style={{
                          display: "inline-block",
                          marginRight: "8px",
                          fontFamily: "figtree",
                        }}
                      >
                      <Link
                          to="/locationslist"
                          style={{ textDecoration: "none", color: "#1D3557",  fontFamily: "figtree", }} 
                          onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                          onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                        >
                          Location list
                        </Link>
                        </li>
                      <li
                        style={{
                          display: "inline-block",
                          marginRight: "8px",
                          fontFamily: "figtree",
                        }}
                      >
                        /
                      </li>
                      <li
                        style={{
                          display: "inline-block",
                          color: "#3f6677",
                          fontWeight: "bold",
                          fontFamily: "figtree",
                        }}
                      >
                         Edit  Location
                      </li> 
                    </ol>
                    <h4
                      style={{
                        color: "#3f6677",
                        marginBottom: "30px",
                        fontSize: "32px",
                        // fontWeight: "bold",
                        fontFamily: "figtree",
                        marginLeft: "-13px",
                      }}
                    >
                      Edit Location
                    </h4>
                  </div>
      
      <Paper sx={{
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <FormControl fullWidth error={!!errors.address}>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
              selectProps={{
                placeholder: "Search Address...",
                onChange: handlePlaceSelect,
                value: formData.address && {
                  label: formData.address,
                  value: formData.address,
                },
                styles: {
                  control: (provided) => ({
                    ...provided,
                    minHeight: "40px",
                    backgroundColor: "#e3f2fd", 
                    borderRadius: "8px", 
                    fontFamily:"figtree",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#e3f2fd", 
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", 
                    opacity: 1, 
                    zIndex: 9999,
                    fontFamily:"figtree",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? "#bbdefb" : "#e3f2fd", 
                    color: "#000", 
                    cursor: "pointer",
                    opacity: 1,
                    fontFamily:"figtree",
                  }),
                },
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["us"],
                },
              }}
            />
            {!!errors.address && (
              <FormHelperText sx={{ fontFamily: "figtree", fontSize: "12px" }}>{errors.address}</FormHelperText>
            )}
          </FormControl>

          <TextField
            label="Location Name"
            name="location_name"
            value={formData.location_name}
            onChange={handleChange}
            error={!!errors.location_name}
            helperText={errors.location_name} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
          <TextField
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
            error={!!errors.city}
            helperText={errors.city} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
          <TextField
            label="Zip Code"
            name="zip_code"
            value={formData.zip_code}
            onChange={handleChange}
            error={!!errors.zip_code}
            helperText={errors.zip_code} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
          <TextField
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
            error={!!errors.state}
            helperText={errors.state}
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            error={!!errors.country}
            helperText={errors.country} 
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />
          {/* <TextField
            label="Location Status"
            name="location_status"
            value={formData.location_status}
            onChange={handleChange}
            error={!!errors.location_status}
            helperText={errors.location_status}
            InputProps={{
              style: {
                height: "40px", 
                fontSize: "0.875rem",
                fontFamily:"figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px", 
                fontFamily:"figtree",
              },
            }}
          />  */} 
          <FormControl 
  variant="outlined" 
  style={{ width: "100%" }}
  error={!!errors.location_status}
>
  <InputLabel
    style={{
      fontSize: "12px",
      fontFamily: "figtree",
    }}
  >
    Location Status
  </InputLabel>
  <Select
    name="location_status"
    value={formData.location_status}
    onChange={handleChange}
    label="Location Status"
    style={{
      height: "40px",
      fontSize: "0.875rem",
      fontFamily: "figtree",
    }}
  >
    <MenuItem value="Active">Active</MenuItem>
    <MenuItem value="Inactive">Inactive</MenuItem>
  </Select>
  {errors.location_status && (
    <FormHelperText style={{ fontFamily: "figtree" }}>
      {errors.location_status}
    </FormHelperText>
  )}
</FormControl>

          <div style={{display:"flex",flexDirection:"row", justifyContent:"flex-end",}}>
          <Button variant="contained" onClick={handleSubmit} sx={{
              backgroundColor: "#3f6677",  color: "#fff",  fontFamily: "figtree",
              width: "20%",
            }}>
            Update Location
          </Button>
          </div>
        </Box>
      </Paper>
    </div>
  );
};

export default EditLocations;
