import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  CircularProgress,
  Alert,
  Button,
} from "@mui/material";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Link, useNavigate } from "react-router-dom";


const formatDate = (date) => {
  if (!date) return "-";
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${month}/${day}/${year}`;
};

const Competencies = () => {
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = sessionStorage.getItem("loggedinUserId"); 

    const navigate = useNavigate();

  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        const response = await axiosInstance.get(
          ApiUrls.ASSIGNMENT_API + "/By/userId"
        );
        setPolicies(response.data);
        setLoading(false);
      } catch (err) {
        setError("An error occurred while fetching the policies.");
        setLoading(false);
      }
    };

    fetchPolicies();
  }, []); 

  const handleCompareFile = (Id) => {
    const data = { routeName: "Competences", routeUrl: "competencies", nextRouteName:"Version Compare" };
    navigate(`/policyversioncontrol?policy_id=${Id}`, { state: data });
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
          color: "error.main",
          textAlign: "center",
          fontFamily: "figtree",
        }}
      >
        <Typography variant="h6" sx={{ fontFamily: "figtree" }}>
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ fontFamily: "figtree" }}>
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -16px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "figtree",
            }}
          >
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "figtree",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "figtree",
            }}
          >
            Competences
          </li>
        </ol>
      </div>
      <Typography
        variant="h4"
        sx={{
          color: "#3f6677",
          mb: 4,

          // fontWeight: "bold",
          fontSize: { xs: "24px", sm: "28px", md: "32px" },
          textAlign: { xs: "center", md: "left" },
          fontFamily: "figtree",
        }}
      >
        Competences
      </Typography>

      <Paper
        sx={{
          borderRadius: "8px",
          overflow: "auto",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          mt: 2,
        }}
      >
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Policy{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Assigned Date{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Effective Date{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Target Date{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Completed Date{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Assignment Status{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Assessment Status{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Figtree",
                    color: "#1D3557",
                    backgroundColor: "#5a8a9a33",
                  }}
                >
                  {" "}
                  Action{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy) => (
                <TableRow key={policy.policy_id}>
                  <TableCell sx={{ fontFamily: "Figtree", color: "#1D3557" }}>
                    {" "}
                    {policy.policy_name}{" "}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Figtree", color: "#1D3557" }}>
                    {formatDate(policy.assigned_date)}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Figtree", color: "#1D3557" }}>
                    {formatDate(policy.effective_date)}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Figtree", color: "#1D3557" }}>
                    {formatDate(policy.target_date)}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Figtree", color: "#1D3557" }}>
                    {formatDate(policy.completed_date)}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={policy.assignment_status}
                      sx={{
                        fontFamily: "Figtree",
                        backgroundColor:
                          policy.assignment_status === "Completed"
                            ? "#d4edda" // Light green for success
                            : policy.assignment_status === "Assigned"
                            ? "#a8dadc"
                            : "#e9ecef", // Light gray for default
                        color:
                          policy.assignment_status === "Completed"
                            ? "#155724" // Dark green text for success
                            : policy.assignment_status === "Assigned"
                            ? "#1D3557"
                            : "#6c757d", // Dark gray text for default
                      }}
                      
                    />
                  </TableCell>
                  <TableCell>
                    
                    <Chip
                      label={
                        !policy.assessment_status ||
                        policy.assessment_status.trim() === ""
                          ? "N/A"
                          : policy.assessment_status === "NotStarted"
                          ? "Not Attempted"
                          : policy.assessment_status
                      }
                      sx={{
                        fontFamily: "Figtree",
                        backgroundColor:
                          !policy.assessment_status ||
                          policy.assessment_status.trim() === ""
                            ? "#e9ecef" // Default light gray background for "N/A"
                            : policy.assessment_status === "NotStarted"
                            ? "#f8d7da" // Light red background
                            : policy.assessment_status === "Completed"
                            ? "#d4edda" // Light green background for success
                            : "#e9ecef", // Default light gray
                        color:
                          !policy.assessment_status ||
                          policy.assessment_status.trim() === ""
                            ? "#1D3557" // Default gray text for "N/A"
                            : policy.assessment_status === "NotStarted"
                            ? "#721c24" // Dark red text color for contrast
                            : policy.assessment_status === "Completed"
                            ? "#155724" // Dark green text color
                            : "#6c757d", // Default gray text
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Figtree", color: "#1D3557" }}>
                    <Button  
                      onClick={() => handleCompareFile(policy.policy_id)}
                      sx={{
                        // height: "40px",
                        marginTop: "10px",
                        fontSize: "12px",
                        marginRight: "9px",
                        marginBottom: "10px",
                        width: "150px",
                        backgroundColor: "#5a8a9a33",
                        color: "#1D3557",
                        fontFamily: "figtree",
                        fontWeight: "bold",
                        border: "2px solid transparent",
                        "&:hover": {
                          backgroundColor: "#5a8a9a66",
                          border: "2px solid #1D3557",
                        },
                      }}
                    >
                      {" "}
                      Version Compare{" "}
                    </Button>{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Competencies;
