
import { routers } from "../Configurations/configurationUI";
import LoginPage from "../Screens/Login/login";
import HomePage from "../Screens/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeSlider from "../Components/HomeSlider/HomeSlider";
import Favourites from "../Screens/Favourites/Favourites";
import RecentDocuments from "../Screens/RecentDocuments/RecentDocuments";
import Reports from "../Screens/Reports.js/Reports";
import Competencies from "../Screens/Competencies/Competencies";
import PolicyLibrary from "../Screens/Policies/PolicyLibrary";
import AddPolicies from "../Screens/Policies/AddPolicies";
import EditPolicies from "../Screens/Policies/EditPolicies";
import MonitorProcess from "../Screens/MonitorProcess/MonitorProcess";
import StayAlert from "../Screens/StayAlert/StayAlert";
import AssignCompetencies from "../Screens/AssignCompetencies/AssignCompetencies";
import ApprovalReview from "../Screens/ApprovalReview/ApprovalReview";
import AddLogo from "../Screens/AddLogo/AddLogo";
import EditConfiguration from "../Screens/EditConfiguration/EditConfiguration";
import AuditScreen from "../Screens/Audit/Audit";
import AddDepartment from "../Screens/Departments/AddDepartments";
import AddLocations from "../Screens/Locations/AddLocations";

import AddUser from "../Screens/User/AddUser";
import DepartmentList from "../Screens/Departments/DepartmentList";
import LocationsList from "../Screens/Locations/Locationslist";
import LocationsImport from "../Screens/Locations/LocationsImport";
import DepartmentImport from "../Screens/Departments/DepartmentImport";
import UserImport from "../Screens/User/UserImport";
import UserList from "../Screens/User/UserList";
import EditUser from "../Screens/User/EditUser";
import EditDepartment from "../Screens/Departments/EditDepartment";
import EditLocations from "../Screens/Locations/EditLocation";
import AuthRouter from "../Auth/auth-router";
import BrowseManuals from "../Screens/BrowseManuals/BrowseManuals";
import Settings from "../Screens/Settings/MySettings";
import FlowActions from "../Screens/FlowActions/FlowActions";
import ChannelsPage from "../Screens/BrowseManuals/policyList";
import AddPolicyTypes from "../Screens/Policies/AddPolicyTypes";
import PolicyTypes from "../Screens/Policies/PolicyTypes";
import PolicyDetails from "../Screens/PolicyDetails/PolicyDetails";
import SearchResults from "../Screens/SearchResults/SearchResults";
import ProfileScreen from "../Screens/ProfileScreen/ProfileScreen";
import EditPolicyTypes from "../Screens/Policies/EditPolicyTypes";
import ForgotPassword from "../Screens/Login/ForgotPassword";
import PDFCompareViewer from "../Components/PDFComparisonViewer/PDFComparisonViewer";
import PDFCompareViewerScreen from "../Screens/PolicyComparision/PolicyComparesion";




export const Router = (props) => {
    return(
        <BrowserRouter>
        <Routes>
            <Route path={"/"} element={<LoginPage/>}/>  
            <Route path={routers.forgotpassword} element={<ForgotPassword/>}/> 
            <Route path={routers.home}  element={<AuthRouter  path ={routers.home}><HomePage/> </AuthRouter>}/>  
            <Route path={routers.browseManuals} element={<AuthRouter  path ={routers.browseManuals}><BrowseManuals/> </AuthRouter>}/>  
            <Route path={routers.favourites} element={<AuthRouter  path ={routers.favourites}> <Favourites/> </AuthRouter> }/>
            <Route path={routers.recentDocuments} element={<RecentDocuments/>}/> 
            <Route path={routers.reports} element={<AuthRouter  path ={routers.reports}> <Reports/> </AuthRouter> }/>   
            <Route path={routers.competencies} element={<AuthRouter  path ={routers.competencies}> <Competencies/> </AuthRouter> }/>
            <Route path={routers.policyLibrary} element={<AuthRouter  path ={routers.policyLibrary}> <PolicyLibrary/> </AuthRouter> }/>  
            <Route path={routers.addPolicies} element={<AuthRouter  path ={routers.addPolicies}> <AddPolicies/> </AuthRouter> }/>
            <Route path={routers.editPolicies} element={<AuthRouter  path ={routers.editPolicies}> <EditPolicies/> </AuthRouter> }/> 
            <Route path={routers.monitorProcess} element={<AuthRouter  path ={routers.monitorProcess}> <MonitorProcess/> </AuthRouter> }/>
            <Route path={routers.stayAlert} element={<AuthRouter  path ={routers.stayAlert}> <StayAlert/> </AuthRouter> }/>
            <Route path={routers.assignCompetencies} element={<AuthRouter  path ={routers.assignCompetencies}> <AssignCompetencies/> </AuthRouter> }/>
            <Route path={routers.approvalReview} element={<AuthRouter  path ={routers.approvalReview}> <ApprovalReview/> </AuthRouter> }/>  
            <Route path={routers.editConfiguration} element={<AuthRouter path ={routers.editConfiguration}><EditConfiguration/></AuthRouter>}/> 
            <Route path={routers.audit} element={<AuthRouter path ={routers.audit}><AuditScreen/></AuthRouter>}/>
            <Route path={routers.adduser} element={<AuthRouter  path ={routers.adduser}><AddUser/> </AuthRouter>} />
            <Route path={routers.adddepartment} element={<AuthRouter  path ={routers.adddepartment}><AddDepartment/> </AuthRouter>} />
            <Route path={routers.addlocations} element={<AuthRouter  path ={routers.addlocations}><AddLocations/> </AuthRouter>} />  
            <Route path={routers.userlist} element={<AuthRouter  path ={routers.userlist}><UserList/> </AuthRouter>} />  
            <Route path={routers.departmentlist} element={<AuthRouter  path ={routers.departmentlist}><DepartmentList/> </AuthRouter>}/> 
            <Route path={routers.locationslist} element={<AuthRouter  path ={routers.locationslist}><LocationsList/> </AuthRouter>}/>
            <Route path={routers.edituser} element={<AuthRouter  path ={routers.edituser}><EditUser/> </AuthRouter>}/>
            <Route path={routers.editdepartment} element={<AuthRouter  path ={routers.editdepartment}><EditDepartment/> </AuthRouter>}/>
            <Route path={routers.editlocation} element={<AuthRouter  path ={routers.editlocation}><EditLocations/> </AuthRouter>}/> 
            <Route path={routers.mysettings} element={<AuthRouter  path ={routers.mysettings}><Settings/> </AuthRouter>}/> 
            <Route path={routers.policyflow} element={<AuthRouter  path ={routers.policyflow}><FlowActions/> </AuthRouter>}/> 
            <Route path={routers.policylist} element={<AuthRouter  path ={routers.policylist}><ChannelsPage/> </AuthRouter>}/> 
            <Route path={routers.addtype} element={<AuthRouter  path ={routers.addtype}><AddPolicyTypes/> </AuthRouter>} /> 
            <Route path={routers.edittype} element={<AuthRouter  path ={routers.edittype}><EditPolicyTypes/> </AuthRouter>} /> 
            <Route path={routers.policytypes} element={<AuthRouter  path ={routers.policytypes}><PolicyTypes/> </AuthRouter>} /> 
            <Route path={routers.policydetails} element={<AuthRouter  path ={routers.policydetails}><PolicyDetails/> </AuthRouter>} /> 
            <Route path={routers.searchdetails} element={<AuthRouter  path ={routers.searchdetails}><SearchResults/> </AuthRouter>} /> 
            <Route path={routers.profilescreen} element={<AuthRouter  path ={routers.profilescreen}><ProfileScreen/> </AuthRouter>} /> 
            <Route path={routers.policycompare} element={<AuthRouter  path ={routers.profilescreen}><PDFCompareViewerScreen/> </AuthRouter>} /> 
        </Routes>
        </BrowserRouter>
    );
};