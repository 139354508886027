import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

const BrowseManuals = () => {
  useAuthHeaders();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("loggedinUser"));
  const DepartmentId = user.department_id;

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_TYPE + "/By/departmentId/" + DepartmentId)
      .then((response) => {
        setTypes(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error Fetching Browse Manuals.");
        setLoading(false);
      });
  }, []);

  const handleBoxClick = (typeId) => {
    navigate(`/policylist?type_id=${typeId}`);
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px 13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree" }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily:"figtree"
            }}
          >
            Browse Manuals
          </li>
        </ol>
        {/* <h4
    style={{
      color: "#3f6677",
      marginBottom: "8px",
      fontSize: "32px",
      // fontWeight: "bold",
      fontFamily: "figtree",
    }}
  >
    Browse Manuals
  </h4> */}
      </div>

      <Typography
        variant="h4"
        sx={{
          color: "#3f6677",
          mb: 4,
          ml: 4,
          // fontWeight: "bold",
          fontSize: { xs: "24px", sm: "28px", md: "32px" },
          textAlign: { xs: "center", md: "left" },
          fontFamily: "figtree",
        }}
      >
        Browse Manuals
      </Typography>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Typography variant="h5" color="error">
            {error}
          </Typography>
        </Box>
      ) : types.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "1fr 1fr",
              md: "repeat(3, 1fr)",
            },
            gap: "20px",
            width: "100%",
            maxWidth: "1100px",
            mx: "auto",
            px: { xs: "20px", sm: "40px", md: "0px" },
          }}
        >
          {types.map((type, index) => (
            <Box
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleBoxClick(type.type_id)}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                borderRadius: "8px",
                backgroundColor: hoveredIndex === index ? "white" : "#5a8a9a",
                boxShadow:
                  hoveredIndex === index
                    ? "0px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "none",
                color: hoveredIndex === index ? "#3f6677" : "white",
                cursor: "pointer",
                transition:
                  "background-color 0.3s, color 0.3s, box-shadow 0.3s",
                minHeight: "100px",
                textAlign: "center",
                width: "100%",
                maxWidth: { xs: "100%", sm: "90%", md: "100%" },
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  // fontWeight: "bold",
                  fontSize: { xs: "18px", sm: "18px", md: "20px" },
                  fontFamily: "figtree",
                }}
              >
                {type.type_name || "Unnamed Type"}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    fontFamily: "figtree",
                  },
                }}
              >
                Manuals
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            fontFamily: "figtree",
          }}
        >
          <Typography variant="h6">No types available.</Typography>
        </Box>
      )}
    </div>
  );
};

export default BrowseManuals;
