import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {
  Card,
  Typography,
  CardActions,
  Button,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const SearchResults = () => { 
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get("q");

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${ApiUrls.SEARCH_API}?keyword=${keyword}`)
      .then((response) => {
        setPolicies(response.data.assignments || []);
        setDepartments(response.data.departments || []);
        setLocations(response.data.locations || []);
        setUsers(response.data.users || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError("An error occurred while searching.");
      });
  }, [keyword]);  


  // const toggleStar = async (policy) => {
  //   try {
  //     if (!policy?.is_favourite == 1) {
  //       const requestBody1 = {
  //         // user_id: userId,
  //         // policy_id:policy?.policy_id,
  //         // created_by: userId,
  //         // created_at: new Date(),
  //         is_favourite: true,
  //       };
  //       const response = await axiosInstance.put(
  //         ApiUrls.ASSIGNMENT_API + "/" + policy.assignment_id,
  //         requestBody1
  //       );
  //       setPolicies((prevPolicies) =>
  //         prevPolicies.map((p) =>
  //           p.assignment_id === policy.assignment_id
  //             ? { ...p, is_favourite: 1 }
  //             : p
  //         )
  //       );
  //     } else {
  //       const requestBody2 = {
  //         // user_id: userId,
  //         // policy_id:policy?.policy_id,
  //         // created_by: userId,
  //         // created_at: new Date(),
  //         is_favourite: false,
  //       };
  //       await axiosInstance.put(
  //         ApiUrls.ASSIGNMENT_API + "/" + policy.assignment_id,
  //         requestBody2
  //       );
  //       setPolicies((prevPolicies) =>
  //         prevPolicies.map((p) =>
  //           p.assignment_id === policy.assignment_id
  //             ? { ...p, is_favourite: 0 }
  //             : p
  //         )
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error updating favorite status:", error);
  //   }
  // };

  const toggleStar = async (policy) => {
    try {
      const updatedFavourite = !policy.is_favourite; // Toggle the favorite status
      const requestBody = { is_favourite: updatedFavourite };
  
      const response = await axiosInstance.put(
        `${ApiUrls.ASSIGNMENT_API}/${policy.assignment_id}`,
        requestBody
      );
  
      // If the API call succeeds, update the state
      if (response?.data?.is_favourite !== undefined) {
        setPolicies((prevPolicies) => {
          const updatedPolicies = prevPolicies.map((p) =>
            p.assignment_id === policy.assignment_id
              ? { ...p, is_favourite: response.data.is_favourite }
              : p
          );
          console.log("Updated Policies:", updatedPolicies); // Debug updated state
          return updatedPolicies;
        });
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  
  const renderFileIcon = (fileType) => (
    <PictureAsPdfIcon sx={{ fontSize: 40, color: "#d32f2f" }} />
  );

  const handleCardClick = (policyId, assignmentId) => {
    // navigate(`/policydetails?policy_id=${policyId}`);
    navigate(
      `/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`
    );
  };

  return (
    <div>
      <h2>Search results for {keyword || "..."}</h2>

      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}

      {/* Error Message */}
      {error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h5" color="error">
            {error}
          </Typography>
        </Box>
      )}

      {/* No Results Found */}
      {!loading &&
        !error &&
        policies.length === 0 &&
        departments.length === 0 &&
        locations.length === 0 &&
        users.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography variant="h5" color="textSecondary">
              No results found for this keyword.
            </Typography>
          </Box>
        )}

      {/* Assignements Section */}
      {!loading && !error && policies.length > 0 && (
        <Box
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            assignments
          </Typography>
          {policies.map((policy) => (
            <Card
              key={policy.policy_id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                cursor: "pointer",
                ":hover": { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" },
                marginBottom: "10px",
              }}
              onClick={() =>
                handleCardClick(policy.policy_id, policy.assignment_id)
              }
            >
              <Box
                sx={{
                  flexShrink: 0,
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                }}
              >
                {renderFileIcon(policy.file_type)}
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#232f3e",
                    marginBottom: "8px",
                  }}
                >
                  {policy.policy_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: "8px",color:"rgb(9 0 0)", width:"82%" }}
                >
                  {policy.policy_description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Expiration Date:</strong>{" "}
                  {new Date(policy.target_date).toLocaleDateString()}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  marginLeft: "16px",
                }}
              >
               <CardActions sx={{ padding: 0 }}>
  <Button
    size="small"
    variant="outlined"
    color="primary"
    sx={{ fontFamily: "figtree" }}
  >
    View
  </Button>
  <Button
    variant="contained"
    color={policy.is_favourite === true ? "success" : "error"} // Color changes based on favorite status
    sx={{
      fontFamily: "figtree",
      backgroundColor:
        policy.is_favourite === true ? "#5a8a9a33" : "#1D3557", // Override default colors
      color: policy.is_favourite === true ? "#1D3557" : "#fff", // Text color
    }}
    onClick={(e) => {
      e.stopPropagation(); // Prevent event propagation
      toggleStar(policy); // Trigger the toggle function
    }}
  >
     {policy.is_favourite ? "UNMARK FAVORITE" : "MARK AS FAVORITE"}
    {/* Dynamic text */}
  </Button>
</CardActions>

              </Box>
            </Card>
          ))}
        </Box>
      )}

      {/* Departments Section */}
      {!loading && !error && departments.length > 0 && (
        <Box>
          <Typography
            variant="h6"
            sx={{ marginBottom: 3, fontWeight: "bold", color: "#37474f" }}
          >
            Departments
          </Typography>
          {departments.map((department) => (
            <Card
              key={department.department_id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "16px",
                borderRadius: 3,
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                ":hover": {
                  backgroundColor: "#f5f5f5",
                  transform: "scale(1.02)",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                },
                marginBottom: "16px",
              }}
              onClick={() =>
                navigate(
                  `/departmentlist?department_id=${department.department_id}`
                )
              }
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "600",
                    color: "#263238",
                    marginBottom: "6px",
                  }}
                >
                  {department.department_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {department.department_description}
                </Typography>
              </Box>
            </Card>
          ))}
        </Box>
      )}

      {/* Locations Section */}
      {!loading && !error && locations.length > 0 && (
        <Box sx={{ marginTop: 4 }}>
          <Typography
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold", color: "#37474f" }}
          >
            Locations
          </Typography>
          {locations.map((location) => (
            <Card
              key={location.location_id}
              sx={{
                padding: "16px",
                borderRadius: 3,
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                ":hover": {
                  transform: "scale(1.02)",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                },
                marginBottom: "16px",
              }}
              onClick={() =>
                navigate(`/locationslist?location_id=${location.location_id}`)
              }
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  color: "#263238",
                  marginBottom: "8px",
                }}
              >
                {location.location_name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#607d8b", marginBottom: "4px" }}
              >
                {location.address}
              </Typography>
              <Typography variant="body2" sx={{ color: "#90a4ae" }}>
                {location.city}, {location.state}, {location.country}
              </Typography>
            </Card>
          ))}
        </Box>
      )}

      {/* User Section */}
      {!loading && !error && users.length > 0 && (
        <Box sx={{ marginTop: 4 }}>
          <Typography
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold", color: "#37474f" }}
          >
            User
          </Typography>
          {users.map((user) => (
            <Card
              key={user.user_id}
              sx={{
                padding: "16px",
                borderRadius: 3,
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                ":hover": {
                  transform: "scale(1.02)",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                },
                marginBottom: "16px",
              }}
              onClick={() => navigate(`/userlist?user_id=${user.user_id}`)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  color: "#263238",
                  marginBottom: "8px",
                }}
              >
                {user.first_name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#607d8b", marginBottom: "4px" }}
              >
                {user.last_name}
              </Typography>
            </Card>
          ))}
        </Box>
      )}
    </div>
  );
};

export default SearchResults;
