import React, { useEffect, useState, version } from "react";
import {  Box,  Typography,  Button,  Paper,  IconButton,  Table,  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ApprovalIcon from "@mui/icons-material/Approval";
import Temp from "../../Templats/Template";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Link, useNavigate } from "react-router-dom";

const PolicyLibrary = () => { 
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_API)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching policies:", error);
      });
  }, []);

  const handleViewPolicy = (policyId) => {
    const data = { routeName: "Policy Library", routeUrl: "policyLibrary", nextRouteName:"Policy Details" };
    navigate(`/policydetails?policy_id=${policyId}`, { state: data });
  };
  const handleEdit = (policyId) => {
    const data = { routeName: "Policy Library", routeUrl: "policyLibrary", nextRouteName:"Edit Policy" };
    navigate(`/editPolicies?policy_id=${policyId}`, { state: data });
  };

  return (
    <Box sx={{ width: "100%" }}> 
    <div
            style={{
              backgroundColor: "#f3f6f9",
              padding: "0px 20px",
            }}
          >
            <ol
              style={{
                display: "flex",
                listStyle: "none",
                padding: 0,
                margin: "2px 0 5px -17px",
                fontSize: "14px",
                color: "#6c757d",
              }}
            >
              <li style={{ display: "inline-block", marginRight: "8px",  fontFamily: "Figtree", }}>
                <Link
                  to="/home"
                  style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
                  onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                  onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                >
                  Home
                </Link>
              </li>
              <li style={{ display: "inline-block", marginRight: "8px" , fontFamily: "Figtree",}}>/</li>
              <li
                style={{
                  display: "inline-block",
                  color: "#3f6677",
                  fontWeight: "bold",
                  fontFamily: "Figtree",
                }}
              >
                Policy Library
              </li>
            </ol>
            
          </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", 
          fontFamily: "Figtree",
        }}
      >  

        <Typography variant="h4" sx={{ color: "#3f6677", fontFamily: "Figtree" }}>
          Policy Library
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", fontFamily: "Figtree", }}>
          {/* <TextField
            placeholder="Search Policies"
            variant="outlined"
            size="small"
            sx={{ marginRight: "10px" }}
            InputProps={{
              endAdornment: <SearchIcon color="action" />,
            }}
          /> */}
          <Button
            onClick={() => navigate("/addPolicies")}
            variant="contained"
            startIcon={<AddIcon />} 
            color="primary"
            sx={{fontFamily: "Figtree", 
              backgroundColor: "#3f6677",
              color: "#fff",
            }}
          >
            Add New Policy
          </Button>
        </Box>
      </Box>

      {/* Policy Table */}
      <TableContainer
        component={Paper}
        sx={{ marginTop: "20px", width: "100%", fontFamily: "Figtree" , borderRadius:"8px"}}
      >
        <Table>
          <TableHead >
            <TableRow  >
              <TableCell  sx={{ fontWeight: "bold", color: "#1D3557" , fontFamily: "Figtree",  backgroundColor: "#5a8a9a33", }}>
                Title
              </TableCell>

              <TableCell  sx={{ fontWeight: "bold", color: "#1D3557", fontFamily: "Figtree", backgroundColor: "#5a8a9a33",  }}>
                Department
              </TableCell>

              <TableCell   sx={{ fontWeight: "bold", color: "#1D3557", fontFamily: "Figtree", backgroundColor: "#5a8a9a33",  }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policies
              .filter((policy) => policy.policy_status === "Published")
              .map((policy) => (
                <TableRow key={policy.policy_id} hover>
                  <TableCell  sx={{fontFamily: "Figtree", color: "#1D3557"}}>{policy.policy_name}</TableCell>
                  <TableCell  sx={{fontFamily: "Figtree", color: "#1D3557"}}>{policy.department_names?.join(", ") || "N/A"}</TableCell>
                  <TableCell  sx={{fontFamily: "Figtree", color: "#1D3557"}} >
                    <IconButton
                      onClick={() => handleViewPolicy(policy.policy_id)}
                      aria-label="view"
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      onClick={(event) => handleEdit(policy.policy_id)}
                      aria-label="edit"
                      color="success"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PolicyLibrary;
