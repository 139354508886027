import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import SearchIcon from "@mui/icons-material/Search";
import ApprovalIcon from "@mui/icons-material/Approval";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

const ApprovalReview = () => {
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [openComments, setOpenComments] = useState({});
  const [comments, setComments] = useState({});
  const [approvalAction, setApprovalAction] = useState({});
  const [status, setStatus] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [], });

  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("loggedinUserRole");
  const user = JSON.parse(sessionStorage.getItem("loggedinUser"));
  const departmentId = user.department_id

  useEffect(() => {
    let newStatus = [];
    switch (userRole) {
      case "Administrator":
        setStatus([
          // "Created",
          "Revision Requested",
          "Assigned to Director",
          "Approved by Quality Director",
        ]);
        break;
      case "Director":
        setStatus([
          "Assigned to Director",
          "Revision Requested",
        ]);
        break;
      case "Senior Leader":
        setStatus(["Assigned to Senior Leader"]);
        break;
      case "Quality Director":
        setStatus([
          "Assigned to Quality Director"
        ]);
        break;

      default:
        setStatus([]);
    }
  }, []);

  useEffect(() => {
    if (status.length > 0) {
      const statusParam = status.map(encodeURIComponent).join("&statuses=");
      const endpoint = userRole === "Administrator" 
      ? `${ApiUrls.POLICY_API}/by/admin/statuses?statuses=${statusParam}`
      : `${ApiUrls.POLICY_API}/by/statuses/${departmentId}?statuses=${statusParam}`;
      
    axiosInstance.get(endpoint)
        .then((response) => {
          setPolicies(response.data);
        })
        .catch((error) => {
          console.error("Error fetching policies:", error);
        });
    }
  }, [status]);

  // useEffect(() => {
  //   const statusParam = ["Revision Requested","Assigned to Director","Assigned to Senior Leader","Assigned to Quality Director","Approved by Quality Director"].map(encodeURIComponent).join("&statuses=");
  //   const fetchPolicies = async () => {
  //     const endpoint = userRole === "Administrator"
  //     ? `${ApiUrls.POLICY_API}/by/admin/statuses?statuses=${statusParam}`
  //     : `${ApiUrls.POLICY_API}/by/statuses/${departmentId}?statuses=${statusParam}`;

  //     try {
  //       const response = await axiosInstance.get(endpoint);
  //       const fetchedPolicies = response.data;

  //       let newStatus = [];

  //       fetchedPolicies.forEach(policy => {
         
  //         if (userRole === "Administrator") {
  //           newStatus = ["Revision Requested", "Assigned to Director", "Approved by Quality Director"];
  //         }

  //         if (userRole === "Director") {
  //           if (policy.role_substitute === 1 && policy.policy_status === "Assigned to Senior Leader") {
  //             newStatus.push("Assigned to Director", "Revision Requested","Assigned to Senior Leader");
  //           } else {
  //             newStatus.push("Assigned to Director", "Revision Requested");
  //           }
  //         }

  //         if (userRole === "Senior Leader") {
  //           if (policy.role_substitute === 1 && policy.policy_status === "Assigned to Quality Director") {
  //             newStatus.push("Assigned to Senior Leader","Assigned to Quality Director");
  //           } else {
  //             newStatus.push("Assigned to Senior Leader");
  //           }
  //         }

  //         if (userRole === "Quality Director") {
  //           newStatus.push("Assigned to Quality Director");
  //         }
  //       });

  //       newStatus = [...new Set(newStatus)];

  //       setStatus(newStatus);
  //     } catch (error) {
  //       console.error("Error fetching policies:", error);
  //     }
  //   };

  //   fetchPolicies();
  // }, [userRole, departmentId]);

  // useEffect(() => {
  //   if (status.length > 0) {
  //     const statusParam = status.map(encodeURIComponent).join("&statuses=");
  //     const endpoint = userRole === "Administrator"
  //       ? `${ApiUrls.POLICY_API}/by/admin/statuses?statuses=${statusParam}`
  //       : `${ApiUrls.POLICY_API}/by/statuses/${departmentId}?statuses=${statusParam}`;

  //     axiosInstance.get(endpoint)
  //       .then((response) => {
  //         setPolicies(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching policies:", error);
  //       });
  //   }
  // }, [status, userRole, departmentId]);

  const handleViewPolicy = (policyId) => {
    const data = { routeName: "Approval & Review", routeUrl: "approvalReview", nextRouteName:"Policy Details" };
    navigate(`/policydetails?policy_id=${policyId}`,{ state: data });
  };

  const handleViewFlow = (Id) => {
    const data = { routeName: "Approval & Review", routeUrl: "approvalReview", nextRouteName:"Policy Flow" };
    navigate(`/policyflow?policy_id=${Id}`,{ state: data });
  };

  const handleCompareFile = (Id) => {
    navigate(`/policyversioncontrol?policy_id=${Id}`);
  };

  const handlePublish = (policy) => {
    updatePolicyStatus(policy.policy_id, "Published");
  };

  const updatePolicyStatus = (id, newStatus) => {
    axiosInstance
      .put(`${ApiUrls.POLICY_API}/${id}`, { policy_status: newStatus })
      .then(() => {
        setPolicies((prevPolicies) =>
          prevPolicies.map((policy) =>
            policy.policy_id === id
              ? { ...policy, policy_status: newStatus }
              : policy
          )
        );
      })
      .catch((error) => {
        console.error("Error updating policy status:", error);
      });
  };

  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case "Assigned to Director":
        return "Assigned to Senior Leader";
      case "Assigned to Senior Leader":
        return "Assigned to Quality Director";
      case "Assigned to Quality Director":
        return "Approved by Quality Director";
      default:
        return currentStatus;
    }
  };

  const canApprove = (policy) => {
    switch (userRole) {
      case "Director":
        return policy.policy_status === "Assigned to Director";
      case "Senior Leader":
        return policy.policy_status === "Assigned to Senior Leader";
      case "Quality Director":
        return policy.policy_status === "Assigned to Quality Director";
      default:
        return false;
    }
  };

  const canRequestRevision = (policy) => {
    return (
      userRole !== "Director" &&
      policy.policy_status === `Assigned to ${userRole}`
    );
  };

  const canPublish = (policy) => {
    return ( userRole === "Administrator" && policy.policy_status === "Approved by Quality Director");
  };

  const versionUpdated = (policy) => {
    return policy.policy_version !== "null" && policy.policy_version !== "V1";
  };

  const handleEdit = (policyId) => {
    const data = { routeName: "Approval & Review", routeUrl: "approvalReview", nextRouteName:"Edit Policy" };
    navigate(`/editPolicies?policy_id=${policyId}`, { state: data });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleFilterModelChange = debounce((newModel) => {
    setFilterModel(newModel);
  }, 100);

  const formatDate = (date) => {
    if (!date) return "-";
    const parsedDate = new Date(date);
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    return `${month}/${day}/${year}`;
  };  

  

  const allColumns = [
    {
      field: "policy_name",
      headerName: <strong> Policy Title</strong>,
      width: 250,
    },
    {
      field: "policy_status",
      headerName: <strong> Policy Status</strong>,
      width: 230,
    },
   
     {
      field: "director_assign_timestamp",
      headerName: <strong>Assigned Date  </strong>,
      width: 200,
      valueGetter: (params) => {
        const assignedDate = formatDate(params);
        if (!assignedDate) return "-";
        
        const formattedDate = formatDate(assignedDate);
        const currentDate = new Date();
        const assignDate = new Date(assignedDate);
    
        if (isNaN(assignDate)) return "-";
    
        // Calculate the difference in days
        const differenceInTime = currentDate.getTime() - assignDate.getTime();
        const holdDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
        
        // Format the output as "mm/dd/yyyy (days)"
        return `${formattedDate} `;
      }, 
      visibleFor: ["Director"],
    },  
    

    {
      field: "director_approve_timestamp",
      headerName: <strong>Assigned Date </strong>,
      width: 200,
      valueGetter: (params) => {
        const assignedDate = formatDate(params);
        if (!assignedDate) return "-";
        
        const formattedDate = formatDate(assignedDate);
        const currentDate = new Date();
        const assignDate = new Date(assignedDate);
    
        if (isNaN(assignDate)) return "-";
    
        // Calculate the difference in days
        const differenceInTime = currentDate.getTime() - assignDate.getTime();
        const holdDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
        
        // Format the output as "mm/dd/yyyy (days)"
        return `${formattedDate} `;
      },
      visibleFor: ["Senior Leader"],
    }, 

    {
      field: "seniorleader_approve_timestamp",
      headerName: <strong>Assigned Date </strong>,
      width: 200,
      valueGetter: (params) => {
        const assignedDate = formatDate(params);
        if (!assignedDate) return "-";
        
        const formattedDate = formatDate(assignedDate);
        const currentDate = new Date();
        const assignDate = new Date(assignedDate);
    
        if (isNaN(assignDate)) return "-";
    
        // Calculate the difference in days
        const differenceInTime = currentDate.getTime() - assignDate.getTime();
        const holdDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
        
        // Format the output as "mm/dd/yyyy (days)"
        return `${formattedDate} `;
      },
      visibleFor: ["Quality Director"],
    },
    
    

    {
      field: "action",
      headerName: <strong> Action</strong>,
      width: 700,
      headerAlign: "center",
      renderCell: (params) => (
        <div>

          <Button
            onClick={() => handleViewPolicy(params.row.policy_id)}
            sx={{
              // height: "40px",
              marginTop: "10px",
              fontSize: "12px",
              marginRight: "9px",
              marginBottom: "10px",
              width: "150px",
              backgroundColor: "#5a8a9a33",
              color: "#1D3557",
              fontFamily: "figtree",
              fontWeight: "bold",
              border: "2px solid transparent",
              '&:hover': {
                backgroundColor: "#5a8a9a66",
                border: "2px solid #1D3557",
              },
            }}
          >
            {(userRole === 'Administrator' || params.row.policy_status === 'Revision Requested') ? 'View' : 'Review And Approve'}
          </Button>

          {(userRole === "Director" ||
            userRole === "Administrator") && (
              <Button
                onClick={() => handleEdit(params.row.policy_id)}
                sx={{
                  // height: "40px",
                  marginTop: "10px",
                  fontSize: "12px",
                  marginRight: "9px",
                  marginBottom: "10px",
                  width: "100px",
                  backgroundColor: "#5a8a9a33",
                  color: "#1D3557",
                  fontFamily: "figtree",
                  fontWeight: "bold",
                  border: "2px solid transparent",
                  '&:hover': {
                    backgroundColor: "#5a8a9a66",
                    border: "2px solid #1D3557",
                  },
                }}
              >
                Edit
              </Button>
            )}

          <Button
            onClick={() => handleViewFlow(params.row.policy_id)}
            sx={{
              // height: "40px",
              marginTop: "10px",
              fontSize: "12px",
              marginRight: "9px",
              marginBottom: "10px",
              width: "100px",
              backgroundColor: "#5a8a9a33",
              color: "#1D3557",
              fontFamily: "figtree",
              fontWeight: "bold",
              border: "2px solid transparent",
              '&:hover': {
                backgroundColor: "#5a8a9a66",
                border: "2px solid #1D3557",
              },
            }}
          >
            View Flow
          </Button>

          {versionUpdated(params.row) && (
            <Button
              onClick={() => handleCompareFile(params.row.policy_id)}
              sx={{
                // height: "40px",
                marginTop: "10px",
                fontSize: "12px",
                marginRight: "9px",
                marginBottom: "10px",
                width: "150px",
                backgroundColor: "#5a8a9a33",
                color: "#1D3557",
                fontFamily: "figtree",
                fontWeight: "bold",
                border: "2px solid transparent",
                '&:hover': {
                  backgroundColor: "#5a8a9a66",
                  border: "2px solid #1D3557",
                },
              }}
            >
              Version Compare
            </Button>
          )}

          {canPublish(params.row) && (
            <Button
              onClick={() => handlePublish(params.row)}
              sx={{
                // height: "40px",
                marginTop: "10px",
                fontSize: "12px",
                marginRight: "9px",
                marginBottom: "10px",
                width: "100px",
                backgroundColor: "#3f6677",
                color: "#fff",
                fontFamily: "figtree",
                fontWeight: "bold",
                border: "2px solid transparent",
                '&:hover': {
                  backgroundColor: "#5a8a9a66",
                  color:"#1D3557",
                  border: "2px solid #1D3557",
                },
              }}
            >
              Publish
            </Button>
          )}

        </div>
      ),
    },

  ]; 

  const columns = allColumns.filter(
    (column) =>
      !column.visibleFor || column.visibleFor.includes(userRole)
  );

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "figtree"
            }}
          >
            Approval & Review
          </li>
        </ol>
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "8px",
            fontSize: "32px",
            fontWeight: "bold",
            fontFamily: "figtree",
            marginLeft: "-13px"
          }}
        >
          Approval & Review
        </h4>
      </div>
      <DataGrid
        rows={policies}
        columns={columns}
        initialState={{
          ...policies.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        slots={{ toolbar: GridToolbarQuickFilter }}
        getRowId={(row) => row.policy_id}
        classes={{
          overlayWrapper: "customOverlayWrapper",
        }}
        sx={{
          "& .customOverlayWrapper ": {
            height: "63px !important",
            fontFamily: "Figtree",
          },
          fontSize: "14px",
          fontFamily: "Figtree",
          borderRadius: "8px",
          boxShadow: 2,
          color: "#1D3557",
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: "#5a8a9a33",
            color: "#1D3557",
            fontFamily: "Figtree",
            fontWeight: "bold",
            fontSize: "16px",
          },
          "& .MuiDataGrid-cell": {
            backgroundColor: "white",
            color: "#1D3557",
            fontFamily: "Figtree",
            fontSize: "14px",
          },
          "& .css-1ka3pnz-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter":{
            width:"32%",
            left:"67%",
            top:"1px",
          }
        }}
        components={{
          Toolbar: () => (
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              <GridToolbarQuickFilter
                classes={{
                  toolbarQuickFilter: "customtoolbarQuickFilter",
                }}
                sx={{
                  position: "absolute",
                  right: 300,
                  top: "-30px",
                  width: "35%",
                }}
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
              />
            </div>
          ),

          NoRowsOverlay: () => (
            <div
              style={{
                position: "sticky",
                top: "50%",
                transform: "translate(-50%, 0%)",
                textAlign: "center",
                marginLeft: "45%",
              }}
            >
              No data found
            </div>
          ),
        }}
      />
    </div>
  );
};

export default ApprovalReview;
