import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import mammoth from "mammoth";
import ConvertApi from "convertapi-js";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import PolicyEditor from "../../Components/TinyMceEditor/TinyMceEditor";
import CreateQuestion from "../../Components/CustomQandA/CustomQandA";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./addpolicy.css";
import { format } from "date-fns";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { routers } from "../../Configurations/configurationUI";

const EditPolicies = () => {
  useAuthHeaders();
  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [policyStartDate, setPolicyStartDate] = useState("");
  const [policyExpireDate, setPolicyExpireDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [file, setFile] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [assesmentEditor, setAssesmentEditor] = useState(false);
  const [documentContent, setDocumentContent] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editAssesmentAIEditor, setEditAssesmentAIEditor] = useState(false);
  const [assesmentInfo, setAssesmentInfo] = useState({});
  const [aiAssessmentData, setAiAssessmentData] = useState([]);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const location = useLocation();
  const routeData = location.state;
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policy_id");
  const userRole = sessionStorage.getItem("loggedinUserRole");
  const editorRef = useRef(null);
  const [policy, setPolicy] = useState({});
  const [error, setError] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    if (policyId) {
      axiosInstance
        .get(ApiUrls.POLICY_API + "/" + policyId)
        .then((response) => {
          const data = response.data;
          setPolicyName(data.policy_name || "");
          setPolicyDescription(data.policy_description || "");
          setPolicyStartDate(formatDate(data.effective_date));
          setPolicyExpireDate(formatDate(data.expiration_date));
          setSelectedDepartment(data.department_id || "");
          setSelectedType(data.type_id || "");
          setLoading(false);
        })
        .catch((error) => {
          setError("An error occurred while fetching the policies.");
          setLoading(false);
        });
      axiosInstance
        .get(ApiUrls.DEPARTMENT_API)
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {});

      axiosInstance
        .get(ApiUrls.FILESTORAGE_API + "/policydocument/paths/" + policyId)
        .then((response) => {
          const lastFileUrl = response.data[response.data.length - 1];
          setFileUrl(lastFileUrl);
        })
        .catch((error) => {
          setError("An error occurred while fetching the policies.");
        });
    }
    axiosInstance
      .get(ApiUrls.POLICY_TYPE)
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {});
  }, [policyId]);

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_TYPE + "/" + selectedType)
      .then((response) => {
        setDepartments(response.data.department_names || []);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
        setDepartments([]);
      });
  }, [selectedType]);

  useEffect(() => {
    const processPdfToHtml = async () => {
      if (!fileUrl) return;

      setLoading(true);
      try {
        // Step 1: Fetch the PDF file
        const response = await fetch(fileUrl);
        const pdfBlob = await response.blob();
        // Step 2: Construct FormData for the API request
        const formData = new FormData();
        formData.append("File", pdfBlob, "document.pdf");
        // Step 3: Send POST request to ConvertApi
        const convertApiResponse = await fetch(
          "https://v2.convertapi.com/convert/pdf/to/docx?storefile=true",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer secret_G1U69MTab18sNFnM`,
            },
            body: formData,
          }
        );
        const result = await convertApiResponse.json();
        const wordFileUrl = result.Files?.[0]?.Url;
        // Step 4: Fetch and process the Word file
        const wordResponse = await fetch(wordFileUrl);
        const wordBlob = await wordResponse.blob();
        const wordFile = new File([wordBlob], "converted.docx", {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // Step 5: Convert Word document to HTML
        const arrayBuffer = await wordFile.arrayBuffer();
        const resultHtml = await mammoth.convertToHtml({ arrayBuffer });
        setDocumentContent(resultHtml.value);
        setShowEditor(true);
      } catch (error) {
        setAlert({
          message: "Failed to process the PDF file. Please try another file.",
          severity: "error",
          show: true,
        });
        setTimeout(() => setAlert((prev) => ({ ...prev, show: false })), 2000);
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    processPdfToHtml();
  }, [fileUrl]);

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!policyName || policyName.trim() === "") {
      newErrors.policyName = "Policy name is required";
    }
    if (!policyStartDate) {
      newErrors.policyStartDate = "Start date is required";
    }
    if (!policyExpireDate) {
      newErrors.policyExpireDate = "Expiration date is required";
    }

    if (policyStartDate && policyExpireDate) {
      const startDate = new Date(policyStartDate);
      const expireDate = new Date(policyExpireDate);

      if (expireDate <= startDate) {
        newErrors.policyExpireDate =
          "Expiration date must be after the start date";
      }
    }

    return newErrors;
  };

  const validateDate = (date) => {
    if (!date) {
      return "This field is required";
    }
    return "";
  };  

  const handleSavefornow = async () => {
    setLoading(true);
    setErrors({});
    setAlert(null);
  
    // Check if there are changes in the document content
    const hasDocumentContentChanged = documentContent.trim() !== policy.content;
  
    const policyPayload = {
      policy_name: policyName,
      policy_description: policyDescription, 
      effective_date: policyStartDate,
      expiration_date: policyExpireDate, 
      content: "ContentEdited",
      updated_by: parseInt(sessionStorage.getItem("loggedinUserId")),
    };
  
    try {
      const { data: resData } = await axiosInstance.put(
        ApiUrls.POLICY_API + "/" + policyId,
        policyPayload
      );
  
      if (!resData.policy_id) {
        throw new Error("Policy update failed. No policy ID returned.");
      }
  
      // Proceed to update the file only if the content has changed
      if (hasDocumentContentChanged) {
        if (documentContent) {
          const htmlContent = documentContent;
          const pdfUrl = await convertHtmlToPdf(htmlContent);
  
          if (pdfUrl) {
            const pdfFile = await fetch(pdfUrl).then((res) => res.blob());
            const pdfFormData = new FormData();
            const fileStorageInfo = {
              type_id: selectedType,
              department_id: selectedDepartment,
              policy_id: resData.policy_id,
              file_folder: "POLICY_DOCUMENT",
            };
            pdfFormData.append(
              "FileStorageInfo",
              JSON.stringify(fileStorageInfo)
            );
            const customFileName = `${resData.policy_id}.pdf`;
            pdfFormData.append("files", pdfFile, customFileName);
  
            try {
              await axiosInstance.post(
                `${ApiUrls.FILESTORAGE_API}/upload`,
                pdfFormData
              );
            } catch (uploadError) {
              throw new Error("File upload failed");
            }
          }
        }
      }
  
      setLoading(false);
      setAlert({
        message: "Policy content updated successfully.",
        severity: "success",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        message: "Error while updating content. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    }
  };
  


  
  const handleSave = async () => {
    const validationErrors = validateForm();
    console.log("Validation Errors:", validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    setErrors({});
    setAlert(null);

    const policyPayload = {
      policy_name: policyName,
      policy_description: policyDescription,
      effective_date: policyStartDate,
      expiration_date: policyExpireDate,
      type_id: selectedType,
      updated_by: parseInt(sessionStorage.getItem("loggedinUserId")),
      content: "ContentChanged",
      policy_status:
        userRole === "Director"
          ? "Assigned to Senior Leader"
          : userRole === "Administrator"
          ? "Assigned to Director"
          : "Assigned to Director",
    };

    try {
      const { data: resData } = await axiosInstance.put(
        ApiUrls.POLICY_API + "/" + policyId,
        policyPayload
      );
      if (!resData.policy_id) {
        throw new Error("Policy Updated failed. No policy ID returned.");
      }

      if (documentContent) {
        const htmlContent = documentContent;
        const pdfUrl = await convertHtmlToPdf(htmlContent);
        if (pdfUrl) {
          const pdfFile = await fetch(pdfUrl).then((res) => res.blob());
          const pdfFormData = new FormData();
          const fileStorageInfo = {
            type_id: selectedType,
            department_id: selectedDepartment,
            policy_id: resData.policy_id,
            file_folder: "POLICY_DOCUMENT",
          };
          pdfFormData.append(
            "FileStorageInfo",
            JSON.stringify(fileStorageInfo)
          );
          const customFileName = `${resData.policy_id}.pdf`;
          pdfFormData.append("files", pdfFile, customFileName);
          try {
            const fileStorageResponse = await axiosInstance.post(
              `${ApiUrls.FILESTORAGE_API}/upload`,
              pdfFormData
            );
          } catch (uploadError) {
            throw new Error("File upload failed");
          }
        }
      }
      resetForm();
      setShowEditor(false);
      setLoading(false);
      setDialogOpen(true);
      setAlert({
        message: "Policy edited  successfully.",
        severity: "success",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        message: "Error while editing Policy. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    }
  };
  const handleDialogOk = async () => {
    setDialogOpen(false);
    await handleOk();
  };
  const handleDialogCancel = () => { 
    setDialogOpen(false);
    navigate(routers.monitorProcess); 
  };
  
  const handlecancel = () => {
    navigate(-1); 
  }; 


  const handleOk = async () => {
    setLoading(true);
    setAlert(null);

    try {
      const { data: policyData } = await axiosInstance.get(
        `${ApiUrls.POLICY_API}/${policyId}`
      );
      const questions = policyData?.assessment_info || [];
      console.log("assessment_info", policyData?.assessment_info);

      const { data: filePaths } = await axiosInstance.get(
        `${ApiUrls.FILESTORAGE_API}/policydocument/paths/${policyId}`
      );
      console.log("filePaths", filePaths);
      const [v1Link, v2Link] = filePaths.slice(-2);
      const { data: postResponse } = await axiosInstance.post(
        ApiUrls.SERVICE_URL + ApiUrls.POLICY_VERSION_API_AI,
        null,
        {
          params: { v1Link, v2Link, questions },
        }
      );
      setEditAssesmentAIEditor(true);
      setAiAssessmentData(postResponse.newQuestions);
      console.log("aiAssessmentData", postResponse.newQuestions);
      setLoading(false);
    } catch (error) {
      console.error("Error during OK action:", error);
      setLoading(false);
      setAlert({
        message: "Error while processing. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => setAlert((prev) => ({ ...prev, show: false })), 2000);
    }
  };

  const handleSubmitUpdatedAssessmentInfo = async () => {
    setLoading(true);
    setAlert(null);

    const policyPayload = {
      assessment_info: assesmentInfo,
    };

    try {
      const { data: resData } = await axiosInstance.put(
        `${ApiUrls.POLICY_API}/${policyId}`,
        policyPayload
      );
      if (!resData.policy_id) {
        throw new Error("Policy update failed. No policy ID returned.");
      }
      setLoading(false);
      setAssesmentInfo("");
      setEditAssesmentAIEditor(false);
      setAlert({
        message: "Policy saved successfully.",
        severity: "success",
        show: true,
      });
      setTimeout(() => setAlert((prev) => ({ ...prev, show: false })), 2000);
    } catch (error) {
      console.error("Error during save:", error);
      setLoading(false);
      setAlert({
        message: "Error while saving Policy. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {setAlert((prev) => ({ ...prev, show: false })); 
      navigate(routers.monitorProcess); 
    },2000);
    }
  };
  const handleContentChange = (newContent) => {
    setDocumentContent(newContent);
  };

  const convertHtmlToPdf = async (htmlContent) => {
    const convertApi = ConvertApi.auth("secret_G1U69MTab18sNFnM");
    const params = convertApi.createParams();
    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const file = new File([htmlBlob], "document.html", { type: "text/html" });

    try {
      params.add("File", file);
      const result = await convertApi.convert("html", "pdf", params);
      const pdfUrl = result.files[0].Url;
      return pdfUrl;
      // window.open(pdfUrl, "_blank");
    } catch (error) {}
  };
  const resetForm = () => {
    setPolicyName("");
    setPolicyDescription("");
    setPolicyStartDate("");
    setPolicyExpireDate("");
    setSelectedDepartment("");
    setFile(null);
    setShowEditor(false);
    setDocumentContent("");
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    if (!isNaN(date)) {
      return date.toISOString().split("T")[0];
    }
    return "";
  };

  return (
    <div>
      {/* <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px",  fontFamily: "Figtree", }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "Figtree",
            }}
          >
            Edit Policy
          </li>
        </ol>
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "30px",
            marginLeft: "-13px",
            fontSize: "32px",
            fontWeight: "bold",
            fontFamily: "figtree",
          }}
        >
          Edit Policy
        </h4>
      </div> */}
       <Breadcrumb routes= {routeData}/>
      <Paper
        sx={{
          borderRadius: "8px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        {alert?.show && (
          <Alert
            variant="filled"
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, show: false })}
            style={{
              position: "fixed",
              top: "25%",
              left: "50%",
              transform: "translateX(-50%)",
              maxWidth: "400px",
              zIndex: 1000,
            }}
          >
            {alert.message}
          </Alert>
        )}

        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <TextField
            label="Policy Name"
            variant="outlined"
            value={policyName}
            onChange={(e) => setPolicyName(e.target.value)}
            fullWidth
            required
            error={!!errors.policyName}
            helperText={errors.policyName}
            InputProps={{
              style: {
                height: "40px",
                fontSize: "0.875rem",
                fontFamily: "figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "figtree",
              },
            }}
          />
          <TextField
            label="Policy Description"
            variant="outlined"
            value={policyDescription}
            onChange={(e) => setPolicyDescription(e.target.value)}
            fullWidth
            multiline
            rows={3}
            // required
            error={!!errors.policyDescription}
            helperText={errors.policyDescription}
            InputProps={{
              style: {
                height: "80px",
                fontSize: "0.875rem",
                paddingTop: "20px",
                fontFamily: "figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "figtree",
              },
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* <TextField
            label="Policy Start Date"
            type="date"
            variant="outlined"
            value={policyStartDate || ""}
            onChange={(e) => setPolicyStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }} 
            inputProps={{
              min: new Date().toISOString().split("T")[0],
            }}
            fullWidth
            required
            error={!!errors.policyStartDate}
            helperText={errors.policyStartDate}
            sx={{ width: "48%" }}
          />
          <TextField
            label="Policy Expiration Date"
            type="date"
            variant="outlined"
            value={policyExpireDate || ""}
            onChange={(e) => setPolicyExpireDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }} 
            inputProps={{
              min: policyStartDate || new Date().toISOString().split("T")[0],
              max: policyStartDate
                ? new Date(
                    new Date(policyStartDate).setFullYear(
                      new Date(policyStartDate).getFullYear() + 3
                    )
                  )
                    .toISOString()
                    .split("T")[0]
                : undefined,
            }}
            fullWidth
            required
            error={!!errors.policyExpireDate}
            helperText={errors.policyExpireDate}
            sx={{ width: "48%" }}
          /> */}
          </div>

          <Paper
            sx={{
              borderRadius: "5px",
              padding: "20px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
              border: "1px solid #b2a8a8",
            }}
          >
            <Box
              sx={{
                display: "flex",
                  flexDirection: "row",
                  // gap: "300px",
                  fontFamily: "figtree",
                  width: "100%",
                  flexWrap: "wrap",
              }}
            >
              {/* Policy Start Date */}
              <Box sx={{ flex: "1 1 auto" }}>
                <Typography
                  variant="body2"
                  sx={{ mb: 1, color: "#817b7b", fontFamily: "figtree" }}
                >
                  Policy Start Date{" "}
                  <span style={{ color: "black", fontFamily: "figtree" }}>
                    *
                  </span>
                </Typography>
                <DatePicker
                  value={
                    policyStartDate ? format(policyStartDate, "MM/dd/yyyy") : ""
                  }
                  selected={policyStartDate}
                  onChange={(date) => {
                    setPolicyStartDate(date);
                    const error = validateDate(date);
                    setErrors((prev) => ({ ...prev, policyStartDate: error }));
                  }}
                  dateFormat="MM/dd/yyyy"
                  minDate={policyStartDate || new Date()}
                  maxDate={
                    policyStartDate
                      ? new Date(
                          new Date(policyStartDate).setFullYear(
                            new Date(policyStartDate).getFullYear() + 3
                          )
                        )
                      : undefined
                  }
                  placeholderText="MM/DD/YYYY"
                  required
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown // Enables scrolling in the year dropdown
                  yearDropdownItemNumber={50}
                  className={` custom-datepicker ${
                    errors.policyStartDate ? "input-error" : ""
                  } date-picker`}
                />

                {errors.policyStartDate && (
                  <Typography
                    variant="caption"
                    sx={{ color: "red", mt: 1, display: "block" }}
                  >
                    {errors.policyStartDate}
                  </Typography>
                )}
              </Box>

              {/* Policy Expiration Date */}
              <Box sx={{ flex: "1 1 auto" }}>
                <Typography
                  variant="body2"
                  sx={{ mb: 1, color: "#817b7b", fontFamily: "figtree" }}
                >
                  Policy Expiration Date{" "}
                  <span style={{ color: "black", fontFamily: "figtree" }}>
                    *
                  </span>
                </Typography>
                <DatePicker
                  value={
                    policyExpireDate
                      ? format(policyExpireDate, "MM/dd/yyyy")
                      : ""
                  }
                  selected={policyExpireDate}
                  onChange={(date) => {
                    setPolicyExpireDate(date);
                    const error = validateDate(date);
                    setErrors((prev) => ({ ...prev, policyExpireDate: error }));
                  }}
                  dateFormat="MM/dd/yyyy"
                  minDate={policyStartDate || new Date()}
                  maxDate={
                    policyStartDate
                      ? new Date(
                          new Date(policyStartDate).setFullYear(
                            new Date(policyStartDate).getFullYear() + 3
                          )
                        )
                      : undefined
                  }
                  placeholderText="MM/DD/YYYY"
                  required
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown // Enables scrolling in the year dropdown
                  yearDropdownItemNumber={50}
                  className={` custom-datepicker ${
                    errors.policyExpireDate ? "input-error" : ""
                  } date-picker`}
                />
                {errors.policyExpireDate && (
                  <Typography
                    variant="caption"
                    sx={{ color: "red", mt: 1, display: "block" }}
                  >
                    {errors.policyExpireDate}
                  </Typography>
                )}
              </Box>
            </Box>
          </Paper>
          <FormControl
            fullWidth
            required
            error={!!errors.type}
            sx={{ fontFamily: "figtree" }}
          >
            <InputLabel sx={{ fontSize: "12px", fontFamily: "figtree" }}>
              Select Policy Type
            </InputLabel>
            <Select
              value={selectedType}
              onChange={(e) => {
                handleTypeChange(e);
                setDepartments([]); // Clear departments initially
                setSelectedDepartment(""); // Reset the selected department
              }}
              label="Select Policy Type"
              sx={{
                fontSize: "12px",
                height: "40px",
                fontFamily: "figtree",
              }}
            >
              {types.length > 0 ? (
                types.map((type) => (
                  <MenuItem
                    key={type.policy_type_type_id}
                    value={type.policy_type_type_id}
                    sx={{ fontFamily: "figtree" }}
                  >
                    {type.policy_type_type_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  No Policy Types available for selected department.
                </MenuItem>
              )}
            </Select>
            {errors.type && (
              <Typography
                color="error"
                variant="caption"
                sx={{ fontFamily: "figtree" }}
              >
                {errors.type}
              </Typography>
            )}
          </FormControl>

          <FormControl
            fullWidth
            required
            error={!!errors.department}
            sx={{ fontFamily: "figtree" }}
          >
            <TextField
              value={
                departments.length > 0
                  ? departments.join(", ")
                  : "No Departments Available"
              }
              label="Departments"
              InputProps={{
                readOnly: true,
                style: {
                  height: "40px",
                  fontSize: "0.875rem",
                  fontFamily: "figtree",
                },
              }}
              // disabled={!selectedType || departments.length === 0} 
            />
            {errors.department && (
              <Typography color="error" variant="caption">
                {errors.department}
              </Typography>
            )}
          </FormControl>

          {!loading && showEditor && (
            <PolicyEditor
              documentContent={documentContent}
              onContentChange={handleContentChange}
              setDocumentContent={setDocumentContent}
            />
          )}
          {assesmentEditor && (
            <CreateQuestion setAssesmentEditor={setAssesmentEditor} />
          )}
        
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end",gap:"20px"}}>  
        <Button
            variant="contained"
            color="primary"
            onClick={handlecancel}
            disabled={loading}
            sx={{
              alignSelf: "flex-start",
              padding: "10px 20px",
              backgroundColor: "#5a8a9a33", color: "#1D3557",
              fontFamily: "figtree",
            }}
          >
            Cancel
          </Button>  
        <Button
            variant="contained"
            color="primary"
            onClick={handleSavefornow}
            disabled={loading}
            sx={{
              alignSelf: "flex-start",
              padding: "10px 20px",
              backgroundColor: "#3f6677",
              color: "#fff",
              fontFamily: "figtree",
            }}
          >
             save  
          </Button> 
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
            sx={{
              alignSelf: "flex-start",
              padding: "10px 20px",
              backgroundColor: "#3f6677",
              color: "#fff",
              fontFamily: "figtree",
            }}
          >
            submit
          </Button> 
          </div>
          {editAssesmentAIEditor && (
            <CreateQuestion
              setAssesmentInfo={setAssesmentInfo}
              setAssesmentEditor={setEditAssesmentAIEditor}
              aiAssessmentData={aiAssessmentData}
              aiQuestions={aiAssessmentData.length}
              isEditAi={true}
            />
          )}

          <Dialog
            open={dialogOpen}
            onClose={handleDialogCancel}
            sx={{
              "& .MuiDialogTitle-root, & .MuiDialogContent-root, & .MuiDialogActions-root":
                {
                  fontFamily: "figtree",
                },
            }}
          >
            <DialogTitle
              sx={{
                fontFamily: "figtree",
              }}
            >
              Create an Assessment
            </DialogTitle>
            <DialogContent
              sx={{
                fontFamily: "figtree",
              }}
            >
              Do you want to create an assessment with the Recent changes?
            </DialogContent>
            <DialogActions
              sx={{
                fontFamily: "figtree",
              }}
            >
              <Button
                onClick={handleDialogCancel}
                color="secondary"
                sx={{
                  fontFamily: "figtree",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDialogOk}
                color="primary"
                sx={{
                  fontFamily: "figtree",
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          {editAssesmentAIEditor && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitUpdatedAssessmentInfo}
              disabled={loading}
              sx={{ alignSelf: "flex-start", padding: "10px 20px" ,  fontFamily: "figtree",}}
            >
              Submit
            </Button>
          )}
          {loading && (
            <CircularProgress
              size={40}
              sx={{
                color: "blue",
                position: "absolute",
                top: "50%",
                left: "55%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          {loading && (
            <CircularProgress
              size={40}
              sx={{
                color: "blue",
                position: "absolute",
                top: "50%",
                left: "55%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default EditPolicies;
