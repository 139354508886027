import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { routers } from "../../Configurations/configurationUI";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import { Alert } from "@mui/material";

const EditDepartment = () => {
  useAuthHeaders();
  const [formData, setFormData] = useState({
    department_name: "",
    department_description: "",
    location_name: "",
    department_status: "",
  });

  const [departmentName, setDepartmentName] = useState("");
  const [departmentDescription, setDepartmentDescription] = useState("");
  const [locationName, setLocationName] = useState("");
  const [status, setStatus] = useState("");

  const [locations, setLocations] = useState([]);
  const [selectlocation, setSelectedLocation] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const departmentId = queryParams.get("department_id");

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.DEPARTMENT_API + "/" + departmentId)
      .then((response) => {
        const data = response.data;
        setDepartmentName(data.department_name || "");
        setDepartmentDescription(data.department_description || "");
        setLocationName(data.location_id || "");
        setStatus(data.department_status || "");
      })
      .catch((error) => {
        // error handle
      });

    axiosInstance
      .get(ApiUrls.LOCATION_API)
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        // error handle
      });
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!departmentName) newErrors.departmentName = "Name is required";
    // if (!departmentDescription)newErrors.departmentDescription = "Description is required";
    if (!locationName) newErrors.locationName = "Location is required";
    if (!status) newErrors.status = "Status is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "department_name") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "department_description") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "location") {
      setFormData({ ...formData, location_id: value });
      setSelectedLocation(value);
    }
    if (name === "department_status") {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const updatedFormData = {
      department_name: departmentName,
      department_description: departmentDescription,
      location_id: locationName,
      department_status: status,
    };

    if (validateForm()) {
      axiosInstance
        .put(ApiUrls.DEPARTMENT_API + "/" + departmentId, updatedFormData)
        .then((response) => {
          setAlert({
            show: true,
            message: "Department Updated successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.departmentlist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error Update Department",
            severity: "warning",
          });
          console.error("Error Updating Department:", error);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  return (
    <div style={{ fontFamily: "Figtree" }}>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "figtree",
            }}
          >
            <Link
              to="/home"
              style={{
                textDecoration: "none",
                color: "#1D3557",
                fontFamily: "figtree",
              }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "figtree",
            }}
          >
            /
          </li>  

          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "figtree",
            }}
          >
          
          <Link
            to="/departmentlist"
            style={{
              textDecoration: "none",
              color: "#1D3557",
              fontFamily: "figtree",
            }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
          >
            Department List
          </Link>
          </li>
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "figtree",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "figtree",
            }}
          >
            Edit Department
          </li>
        </ol>
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "30px",
            fontSize: "32px",
            // fontWeight: "bold",
            fontFamily: "figtree",
            marginLeft: "-13px",
            marginBottom: "40px",
          }}
        >
          Edit Department
        </h4>
      </div>

      <Paper
        sx={{
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            height: "100%",
            maxHeight: "100%",
            overflowY: "auto",
            paddingBottom: "20px",
            marginTop: "2px",
          }}
          className="custom-scrollbar"
        >
          <TextField
            label="Department Name"
            variant="outlined"
            name="department_name"
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
            error={!!errors.departmentName}
            helperText={errors.departmentName}
            style={{ marginTop: "15px" }}
            InputProps={{
              style: {
                height: "40px",
                fontSize: "0.875rem",
                fontFamily: "figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "figtree",
              },
            }}
          />

          <TextField
            label="Department Description"
            variant="outlined"
            name="department_description"
            value={departmentDescription}
            onChange={(e) => setDepartmentDescription(e.target.value)}
            error={!!errors.departmentDescription}
            helperText={errors.departmentDescription}
            InputProps={{
              style: {
                height: "40px",
                fontSize: "0.875rem",
                fontFamily: "figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "figtree",
              },
            }}
          />

          <FormControl variant="outlined" error={!!errors.locationName}>
            <InputLabel sx={{ fontSize: "12px", fontFamily: "figtree" }}>
              Location
            </InputLabel>
            <Select
              label="Location"
              name="location"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              sx={{
                fontSize: "12px",
                height: "40px",
                fontFamily: "figtree",
              }}
            >
              {locations.length > 0 ? (
                locations.map((loc) => (
                  <MenuItem
                    key={loc.location_id}
                    value={loc.location_id}
                    sx={{ fontFamily: "figtree" }}
                  >
                    {loc.location_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" sx={{ fontFamily: "figtree" }}>
                  No locations available
                </MenuItem>
              )}
            </Select>
          </FormControl>

          {/* <TextField
                  label="Department Status"
                  variant="outlined"
                  name="department_status"
                  value={status} 
                  onChange={(e) => setStatus(e.target.value)} 
                  error={!!errors.status}
                  helperText={errors.status}
                  InputProps={{
                    style: {
                      height: "40px", 
                      fontSize: "0.875rem",
                      fontFamily: "figtree"
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px", 
                      fontFamily: "figtree"
                    },
                  }}
                />  */}
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel
              style={{
                fontSize: "12px",
                fontFamily: "figtree",
              }}
            >
              Department Status
            </InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Department Status"
              style={{
                height: "40px",
                fontSize: "0.875rem",
                fontFamily: "figtree",
              }}
              error={!!errors.status}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
            {/* {errors.status && (
    <FormHelperText error style={{ fontFamily: "figtree" }}>
      {errors.status}
    </FormHelperText>
  )} */}
          </FormControl>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#3f6677",
                color: "#fff",
                fontFamily: "figtree",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Paper>
    </div>
  );
};

export default EditDepartment;
