import React from 'react'
import {Navigate, useLocation, useNavigate} from "react-router-dom"
import { useSelector } from "react-redux";
import store from '../Utils';
import { commonActions } from '../Utils/CommonStore';
import Header from '../Components/Header/Header';
import Navigation from '../Components/NavigationBar/Navigation';
import { useMediaQuery, useTheme, Paper } from "@mui/material";
import { routers } from '../Configurations/configurationUI';
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';


const AuthRouter = (props) => {

    let location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [data, setData] = React.useState({
        isSimpleNav: false,
        language: ""
    });

    const languages = ["English", "Bulgarian", "Hindi", "Chinese"];

    const setLanguage = (lang) => {
        setData({...data, language: lang});
    };

    const loggedinUserRole = sessionStorage.getItem('loggedinUserRole');
    const userId = sessionStorage.getItem('loggedinUserId');
    const bearerToken = sessionStorage.getItem('JWTToken');

    if (!loggedinUserRole || !userId || !bearerToken) {
        navigate('/', { state: { from: location }, replace: true });
        return null;
    }

    return (

        <div  style={styles.container}>
           <div  style={styles.header}>  
                <Header />
            </div>
            <div  style={styles.main}>
            {!isMobile && (
                    <div style={styles.sidebar}>
                        <Navigation />
                    </div>
                )}
                <Paper elevation={3} sx={styles.content}>
                    {/* <Breadcrumb/> */}
                    {props.children}
                </Paper>
            </div>
        </div>
    ) 
    // } else {
    // console.log('Insufficient permissions. Redirecting to home page.');
    // return <Navigate to="/" state={{ from: location }} replace />;
    //  }
    // } else {
    //     console.log("return login page");
    //         return <Navigate to="/login" state={{from: location}} replace/>   
    // }
};

export default AuthRouter;

const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    header: {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      backgroundColor: "#fff",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      borderBottom:  '1px solid #dee2e6',
      width: "100%",
    },
    main: {
      display: "flex",
      position:"fixed",
      top:"73px",
      bottom:0,
      width:'100%',
    },
    sidebar: {
        height: "calc(100vh - 73px)",
        backgroundColor: "white",
        borderRight: "1px solid rgb(171, 173, 176)",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },
    content: {
        flex: 1,
        padding: "40px",
        backgroundColor: "#f5f7f9",
        overflowY: "auto",
        overflowX: "auto",
        height: "calc(100vh - 73px)", 
    },
  };