import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Chip,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import UpdateIcon from "@mui/icons-material/Update";
import PolicyIcon from "@mui/icons-material/Policy";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Link } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

const StayAlert = () => { 
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`${ApiUrls.POLICY_API}/expire/alerts`)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching policies:", error);
      });
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const calculateDaysDifference = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    const differenceInTime = targetDate - today; // Difference in milliseconds
    const differenceInDays = Math.round(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  };

  return (
    <div style={{fontFamily: "Figtree"}}>  
      <div
              style={{
                backgroundColor: "#f3f6f9",
                padding: "0px 20px",
              }}
            >
              <ol
                style={{
                  display: "flex",
                  listStyle: "none",
                  padding: 0,
                  margin: "2px 0 5px -17px",
                  fontSize: "14px",
                  color: "#6c757d",
                }}
              >
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "Figtree",
                  }}
                >
                  <Link
                    to="/home"
                    style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
                    onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                    onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                  >
                    Home
                  </Link>
                </li>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "Figtree",
                  }}
                >
                  /
                </li>
                <li
                  style={{
                    display: "inline-block",
                    color: "#1D3557",
                    fontWeight: "bold",
                    fontFamily: "Figtree",
                  }}
                >
                  Stay Alert
                </li>
              </ol>
            </div>
      <Typography
        variant="h4"
        sx={{ color: "#3f6677", fontFamily: "Figtree" }}
      >
        Stay Alert - Policy & Compliance Updates
      </Typography> 

      <Paper
        sx={{ 
          marginTop:"20px",
          borderRadius: "8px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <List>
          {policies.map((policy) => {
            const daysDifference = calculateDaysDifference(policy.expiration_date);
            return (
              <React.Fragment key={policy.policy_id}>
                <ListItem
                  sx={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    mb: 2,
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                    padding: "16px",
                    fontFamily: "figtree",
                    
                  }}
                >
                  <ListItemIcon>
                    {policy.policy_status === "Expired" ? (
                      // <PolicyIcon color="error" /> 
                      <ErrorIcon color="error"/>
                    ) : (
                      // <UpdateIcon color="primary" /> 
                      <RunningWithErrorsIcon color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText
  primary={
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "figtree",
      }}
    >
      <Typography
        
        
        sx={{ color: "#1D3557" ,fontWeight: "bold", fontSize:"16px", fontFamily: "figtree",}}
      >
        {policy.policy_status === "Expired"
          ? `${policy.policy_name} Policy has been expired ${
              Math.abs(daysDifference)
            } days ago.`
          : `${policy.policy_name} Policy will expire in ${
              Math.abs(daysDifference)
            } days.`}
      </Typography>
      <Chip
        label={formatDate(policy.expiration_date)}
        size="small"
        sx={{
          backgroundColor: "#e0f7fa",
          color: "#1D3557",
          fontWeight: "bold",
          fontFamily: "figtree",
        }}
      />
    </Box>
  }
  secondary={
    <Typography
     
      sx={{  color: "#1D3557", fontSize:"14px", fontFamily: "figtree", }}
    >
      {policy.policy_status === "Expired"
        ? `Expired`
        : `About to Expire`}
    </Typography>
  }
/>

                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};

export default StayAlert;
