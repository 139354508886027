import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import { routers } from "../../Configurations/configurationUI";
import { Link, useNavigate } from "react-router-dom";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import Temp from "../../Templats/Template";
import DepartmentImport from "./DepartmentImport";

const AddDepartment = () => { 
  useAuthHeaders();
  const userId = parseInt(sessionStorage.getItem("loggedinUserId"));
  const [formData, setFormData] = useState({
    department_name: "",
    department_description: "",
    location_id: "",
    department_status: "",
    created_at: new Date(),
    last_updated_at: "",
    created_by: userId,
    last_updated_by: 0,
  });
  const [locations, setLocations] = useState([]);
  const [selectlocation, setSelectedLocation] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  useEffect(() => {
    axiosInstance
      .get(ApiUrls.LOCATION_API)
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        // error handle
      });
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.department_name)
      newErrors.department_name = "Department name is required";
    // if (!formData.department_description)
    //   newErrors.department_description = "Description is required";
    if (!formData.location_id) newErrors.location_id = "Location is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "department_name") {
      setFormData({ ...formData, [name]: value });
    }
    if (name === "department_description") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "location") {
      setFormData({ ...formData, location_id: value });
      setSelectedLocation(value);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      axiosInstance
        .post(ApiUrls.DEPARTMENT_API, formData)
        .then((response) => {
          setAlert({
            show: true,
            message: "Department added successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.departmentlist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error adding Department",
            severity: "warning",
          });
          console.error("Error adding Department:", error);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
      setLoading(false);
    }
  };

  const handleClear = () => {
    setFormData({
      department_name: "",
      department_description: "",
      location: "",
    });
    setErrors({});
    setSelectedLocation("");
  };

  return (
    <div style={{fontFamily: "Figtree"}}>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )} 
      <div
              style={{
                backgroundColor: "#f3f6f9",
                padding: "0px 20px",
              }}
            >
              <ol
                style={{
                  display: "flex",
                  listStyle: "none",
                  padding: 0,
                  margin: "2px 0 5px -13px",
                  fontSize: "14px",
                  color: "#6c757d",
                }}
              >
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  <Link
                    to="/home"
                    style={{ textDecoration: "none", color: "#1D3557",  fontFamily: "figtree", }} 
                    onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                    onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                  >
                    Home
                  </Link>
                </li> 
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  /
                </li> 
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                <Link
                    to="/departmentlist"
                    style={{ textDecoration: "none", color: "#1D3557",  fontFamily: "figtree", }} 
                    onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                    onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                  >
                    Department list
                  </Link> 
                  </li>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    fontFamily: "figtree",
                  }}
                >
                  /
                </li>
                <li
                  style={{
                    display: "inline-block",
                    color: "#3f6677",
                    fontWeight: "bold",
                    fontFamily: "figtree",
                  }}
                >
                  Add Department
                </li> 
              </ol>
              <h4
                style={{
                  color: "#3f6677",
                  marginBottom: "30px",
                  fontSize: "32px",
                  // fontWeight: "bold",
                  fontFamily: "figtree",
                  marginLeft: "-13px",
                }}
              >
                Add Department
              </h4>
            </div>
      <div
        style={{
          // backgroundColor: "#E5F2FF", 
          backgroundColor: "#5a8a9a33",
          borderRadius: "15px",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
        <h2
            style={{ fontWeight: "bold", color: "#1D3557", fontSize: "31px", fontFamily:"figtree" }}
          >
            Adding Department is Essential.
          </h2>
          <p
            style={{
              backgroundColor: "white",
              borderRadius: "50px",
              padding: "1rem 2rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              marginTop: "1rem",
              fontSize: "16px",
              lineHeight: "1.5",
              fontFamily:"figtree",
              color: "#1D3557"
            }}
          >
            Ensure to add Department to streamline the process of managing User.
            This will help in organizing resources and managing Departments and
            Users.
          </p>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img
            src="https://images.pexels.com/photos/256517/pexels-photo-256517.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Location Image"
            style={{
              width: "50%",
              height: "auto",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              objectFit: "cover",
              clipPath: "polygon(19% 0%, 100% 0%, 100% 100%, 0% 100%)",
            }}
          />
        </div>
      </div>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "20px",
          gap: "18px",
          marginTop: "60px",
        }}
      >
          <Typography  sx={{ mb: 2, fontWeight: 700 , fontSize:"18px", fontFamily:"figtree", color: "#1D3557" }}>
          <i
            className="fa fa-building"
            style={{ fontSize: "22px", color: "#1D3557", paddingRight: "15px" }}
          />
          Add Department Here
        </Typography>
        <TextField
          label="Department Name *"
          variant="outlined"
          name="department_name"
          value={formData.department_name}
          onChange={handleChange}
          error={!!errors.department_name}
          helperText={errors.department_name}
          InputProps={{
            style: {
              height: "40px", 
              fontSize: "0.875rem",
              fontFamily:"figtree",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px",
              fontFamily:"figtree", 
            },
          }}
        />
        <TextField
          label="Department Description (Optional)"
          variant="outlined"
          name="department_description"
          value={formData.department_description}
          onChange={handleChange}
          error={!!errors.department_description}
          helperText={errors.department_description} 
          InputProps={{
            style: {
              height: "40px", 
              fontSize: "0.875rem",
              fontFamily:"figtree",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px", 
              fontFamily:"figtree",
            },
          }}
        />

        <FormControl variant="outlined" error={!!errors.location_id}>
          <InputLabel sx={{fontSize: "12px",}}>Location</InputLabel>
          <Select
            label="Location *"
            name="location"
            value={selectlocation}
            onChange={handleChange}
            sx={{
              fontSize: "12px",
              height: "40px", 
              fontFamily:"figtree",
            }}
          >
            {locations.length > 0 ? (
              locations.map((loc) => (
                <MenuItem key={loc.location_id} value={loc.location_id} sx={{ fontFamily: "figtree" }}>
                  {loc.location_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" sx={{ fontFamily: "figtree" }}>No locations available</MenuItem>
            )}
          </Select>
          {errors.location_id && (
            <Typography
              color="error"
              style={{ marginLeft: "20PX" }}
              variant="caption"
            >
              {errors.location_id}
            </Typography>
          )}
        </FormControl>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            onClick={handleClear}
            sx={{ backgroundColor: "#5a8a9a33", color: "#1D3557",fontFamily: "figtree", width: "48%" }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{  backgroundColor: "#3f6677",  color: "#fff",  fontFamily: "figtree",  width: "48%",  ml: 3, }}
          >
            Submit
          </Button>
        </div>
      </Box>
      <div
        style={{
          marginTop: "60px",
          marginBottom: "10px",
          paddingBottom: "30px",
        }}
      >
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "30px",
            fontSize: "32px",
            // fontWeight: "bold",
            fontFamily: "figtree",
            marginLeft: "13px",
          }}
        >
          Import Department In Bulk
          </h4>
        <DepartmentImport />
      </div>
    </div>
  );
};

export default AddDepartment;
