import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = ({routes}) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter(Boolean); 
  console.log(routes,"data from routes")

  return (
    <div style={{ backgroundColor: "#f3f6f9", padding: "0px 20px" }}>
      <ol
        style={{
          display: "flex",
          listStyle: "none",
          padding: 0,
          margin: "2px 0 5px -13px",
          fontSize: "14px",
          color: "#6c757d",
        }}
      >
        <li style={{ display: "inline-block", marginRight: "8px", fontFamily: "figtree" }}>
          <Link
            to="/home"
            style={{ textDecoration: "none", color: "#1D3557" }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
          >
            Home
          </Link>
        </li>

        {routes?.routeName && (
          <>
            <li style={{ display: "inline-block",  marginRight: "8px", fontFamily: "figtree" }}>
              <span style={{ color: "#6c757d", fontWeight: "bold", marginRight: "8px",   }}>/</span>
            </li>
            <li
              style={{
                display: "inline-block",
                fontFamily: "figtree",
                color: "#1D3557",
                fontWeight: "normal",
                marginRight: "8px", 
              }}
            >
              <Link
                to={`/${routes.routeUrl}`}
                style={{ textDecoration: "none", color: "#1D3557" }}
                onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              >
                {routes?.routeName}
              </Link>
            </li>
            </>
        )}

            {routes?.nextRouteName && (
              <>
                <li style={{ display: "inline-block", marginRight: "8px",  fontFamily: "figtree" }}>
                  <span style={{ color: "#6c757d", fontWeight: "bold" , marginRight: "8px", }}>/</span>
                </li>
                <li
                  style={{
                    display: "inline-block",
                    fontFamily: "figtree",
                    color: "#3f6677",
                    fontWeight: "bold",
                    marginRight: "8px", 
                  }}
                >
                  {routes?.nextRouteName}
                </li>
              </>
            )}
      </ol>

      {routes?.nextRouteName && (
      <h4
        style={{
          color: "#3f6677",
          marginBottom: "8px",
          fontSize: "32px",
          fontWeight: "bold",
          fontFamily: "figtree",
          marginLeft: "-13px",
        }}
      >
        {routes?.nextRouteName}
      </h4>
      )}
    </div>
  );
};
export default Breadcrumb;
