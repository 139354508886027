import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Link, useNavigate } from "react-router-dom";
import { routers } from "../../Configurations/configurationUI";

const PolicyTypes = () => {
  useAuthHeaders();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [types, setTypes] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState(null);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_TYPE)
      .then((response) => setTypes(response.data))
      .catch((error) => console.error("Error fetching :", error));
  }, []);

  const handleAddNewType = () => {
    navigate(routers.addtype);
  };

  const handleEditType = (typeId) => {
    navigate(`/edittype?type_id=${typeId}`);
  };

  const confirmDelete = (typeId) => {
    setTypeToDelete(typeId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteType = (typeId) => {
    axiosInstance
      .delete(ApiUrls.POLICY_TYPE + "/" + typeId)
      .then(() => {
        setTypes((prevTypes) =>
          prevTypes.filter((type) => type.policy_type_type_id !== typeId)
        );
        setDeleteDialogOpen(false);
        setAlert({
          message: "Type deleted successfully.",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      })
      .catch((error) => {
        setDeleteDialogOpen(false);
        setAlert({
          message: "Error while deleting type. Please try again.",
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      });
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px 13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "8px",  fontFamily: "Figtree", }}>
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "Figtree",
            }}
          >
            Policy Types
          </li>
        </ol>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#3f6677",
            mb: 4,
            ml: 4,
            fontWeight: "bold",
            fontSize: { xs: "24px", sm: "28px", md: "32px" },
            textAlign: { xs: "center", md: "left" },
            fontFamily: "figtree",
          }}
        >
          Policy Types
        </Typography>
        <Button
          variant="contained"
          onClick={handleAddNewType}
          sx={{ backgroundColor: "#5a8a9a", height: "40px", color: "#fff",fontFamily: "figtree",mr:4 }}
        >
          Add New Type
        </Button>
      </div>
      <Box
        sx={{
          display: "grid",
          fontFamily: "figtree",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "1fr 1fr",
            md: "repeat(3, 1fr)",
          },
          gap: "20px",
          width: "100%",
          maxWidth: "1100px",
          mx: "auto",
          px: { xs: "20px", sm: "40px", md: "0px" },
        }}
      >
        {types.length > 0 ? (
          types.map((type, index) => (
            <Box
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                borderRadius: "8px",
                fontFamily: "figtree",
                backgroundColor: hoveredIndex === index ? "white" : "#5a8a9a",
                boxShadow:
                  hoveredIndex === index
                    ? "0px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "none",
                color: hoveredIndex === index ? "#3f6677" : "white",
                cursor: "pointer",
                transition:
                  "background-color 0.3s, color 0.3s, box-shadow 0.3s",
                minHeight: "150px",
                textAlign: "center",
                width: "100%",
                maxWidth: { xs: "100%", sm: "90%", md: "100%" },
                fontSize: { xs: "14px", sm: "16px" },
                position: "relative",
              }}
            >
              <Typography
               
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontFamily: "figtree",
                }}
              >
                {type.policy_type_type_name || "Unnamed Type"}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "10px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleEditType(type.policy_type_type_id)}
                  sx={{ 
                    fontFamily: "figtree",
                    color: hoveredIndex === index ? "#1976d2" : "white",
                    borderColor: hoveredIndex === index ? "#1976d2" : "white",
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => confirmDelete(type.policy_type_type_id)}
                  sx={{ 
                    fontFamily: "figtree",
                    color: hoveredIndex === index ? "red" : "white",
                    borderColor: hoveredIndex === index ? "red" : "white",
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          ))
        ) : (
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              color: "#3f6677",
              mt: 4,
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            No types available.
          </Typography>
        )}
      </Box>

      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Delete Policy Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Policy Type?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#7da1b2", color: "white" }}
            onClick={closeDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#34425a", color: "white" }}
            onClick={() => handleDeleteType(typeToDelete)}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PolicyTypes;
