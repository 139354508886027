import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography, Snackbar, Alert, ListItem, ListItemText, Chip, ClickAwayListener, IconButton,} from "@mui/material";
import { MdAssignment, MdCheckCircle, MdWarning, MdCancel, MdLocalFireDepartment, MdPublish, MdCreate,} from "react-icons/md";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";

function FlowActions() {
  useAuthHeaders();
  const [policyData, setPolicyData] = useState({});
  const [sortedActions, setSortedActions] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const location = useLocation();
  const routeData = location.state;
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policy_id");

  useEffect(() => {
    fetchPolicyDataById();
  }, []);

  const fetchPolicyDataById = () => {
    axiosInstance
      .get(`${ApiUrls.POLICY_API}/${policyId}`)
      .then((response) => {
        setPolicyData(response.data);
        let policyActions = [];
        try {
          policyActions = Array.isArray(
            JSON.parse(response.data.policy_actions)
          )
            ? JSON.parse(response.data.policy_actions)
            : [];
        } catch (error) {
          console.error("Error parsing policy actions:", error);
        }
        const sorted = policyActions.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        setSortedActions(sorted);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getTimelineIcon = (actionType) => { 

    const iconStyle = {
      backgroundColor: "#5a8a9a3", // Icon background color
             // Add padding for circular effect
      borderRadius: "50%",       // Make it circular
      display: "inline-block",   // Ensure it stays inline
    }; 

    switch (actionType) {
      case "Created":
        return <div style={iconStyle}><MdCreate style={{  color: "#1d3557", fontWeight: "bold", fontFamily: "figtree", fontSize:"16px" }} /></div>;
      case "Assigned to Director":
      case "Assigned to Senior Leader":
      case "Assigned to Quality Director":
        return <div style={iconStyle}><MdAssignment style={{ color: "#1D3557" }} /></div>;
      case "Approved":
      case "Approved by Director":
      case "Approved by Senior Leader":
      case "Approved by Quality Director":
        return <div style={iconStyle}><MdCheckCircle style={{ color: "#1D3557" }} /></div>;
      case "Published":
        return <div style={iconStyle}><MdPublish style={{ color: "#1D3557" }} /></div>;
      case "Revision Requested":
        return <div style={iconStyle}><MdWarning style={{ color: "#1D3557" }} /></div>;
      case "Rejected":
        return <div style={iconStyle}><MdCancel style={{ color: "#1D3557" }} /></div>;
      case "Expired":
        return <div style={iconStyle}><MdLocalFireDepartment style={{ color: "#1D3557" }} /></div>;
      default:
        return null;
    }
  };

  return (
    <div>
     {/* <div
                       style={{
                         backgroundColor: "#f3f6f9",
                         padding: "0px 20px",
                       }}
                     >
                       <ol
                         style={{
                           display: "flex",
                           listStyle: "none",
                           padding: 0,
                           margin: "2px 0 5px -13px",
                           fontSize: "14px",
                           color: "#6c757d",
                         }}
                       >
                         <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree", cursor: "pointer", marginTop:"-10px" }} 
                               onClick={() => navigate(-1)}
                                >
                               <IconButton  style={{ color: "#1D3557" }}>
                                  <ArrowBackIcon />
                                </IconButton>
                                <span  style={{ color: "#1D3557" }}  onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                         onMouseOut={(e) => (e.target.style.textDecoration = "none")} >Back</span>
                               </li>
                         <li style={{ display: "inline-block", marginRight: "8px",fontFamily:"figtree" }}>/</li>
                         <li
                           style={{
                             display: "inline-block",
                             color: "#3f6677",
                             fontWeight: "bold",
                             fontFamily:"figtree"
                           }}
                         >
                             Approval Flow
                         </li>
                       </ol>
                       <h4
                   style={{
                     color: "#3f6677",
                     marginBottom: "8px",
                     fontSize: "32px",
                     fontWeight: "bold",
                     fontFamily: "figtree",
                     marginLeft:"-13px"
                   }}
                 >
                   Approval Flow
                 </h4>
     </div> */}
     <Breadcrumb routes= {routeData}/>
    <div className="page-content" style={{ backgroundColor: "#5a8a9a33", padding: "20px" }}>
      <div  style={{ maxWidth: "800px", margin: "0 auto" }}>
        
        <ClickAwayListener onClickAway={() => {}}>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            style={{ position: "fixed", top: "15%", left: "50%", transform: "translate(-50%, -50%)" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severity}
              style={{ width: "300px", fontSize: "14px" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </ClickAwayListener>
        <div id="main-wrapper">
          <div className="row">
            <div className="col-md-12">
              <section id="cd-timeline" className="cd-container">
                {sortedActions.map((action, index) => (
                  <div key={index} className="cd-timeline-block" style={{ marginBottom: "20px" }}>
                    <div className="cd-timeline-img" style={{ color: "#1D3557" }}>
                      {getTimelineIcon(action.policy_status)}
                    </div>
                    <div
                      className="cd-timeline-content"
                      style={{ backgroundColor: "#FFFFFF", padding: "15px", borderRadius: "8px", boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", marginRight:"-10px", marginLeft:"-10px" }}
                    >
                      <Typography variant="h6" style={{ color: "#1D3557", fontWeight: "bold" }}>
                      {(() => {
                      const statusMapping = {
                                              "Assigned to Quality Director": "Approved and Assigned to Quality Director",
                                              "Assigned to Senior Leader": "Approved and Assigned to Senior Leader",
                                              "Approved by Quality Director": "Approved and Set to Publish",
                                             
                                            };  

                        return statusMapping[action.policy_status] || action.policy_status;
                      })()}
                      </Typography>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              style={{  color: "#1d3557", fontWeight: "bold", fontFamily: "figtree", fontSize:"14px" }}
                            >
                              Action by: {`${action.created_by_name} - ${action?.role_name || ""}`}
                            </Typography>
                          }
                          secondary={
                            <Typography
                            style={{  color: "#1d3557", fontFamily: "figtree", fontSize:"12px" }}
                            >
                              Comment: {action.comments}
                            </Typography>
                          }
                        />
                      </ListItem> 
                      <span className="cd-date"> 
                      <p>
                      <Chip
                        label={new Date(action.created_at).toLocaleString()}
                        size="small"
                        sx={{ backgroundColor: "#1D3557", color: "#E5F2FF",fontFamily: "figtree", fontSize:"12px", fontWeight: "bold", marginLeft:"20px", marginRight:"20px",marginTop:"-20px" }}
                      /> 
                      </p>
                      </span> 
                      
                      
                    </div>
                  </div>
                ))}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default FlowActions;





// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useParams } from "react-router-dom";
// import { Button, Menu, MenuItem, Box, ListItem, ListItemIcon, ListItemText, Typography, Chip } from "@mui/material"
// import CircularProgress from '@mui/material/CircularProgress';
// import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
// import { FaDownload, FaTrash } from 'react-icons/fa';
// import { FaEdit } from 'react-icons/fa';
// import { MdAssignment, MdPeople, MdWarning, MdNotificationImportant, MdEvent, MdMessage, MdDownload, MdLocalFireDepartment, MdMedicalServices, MdMedicalInformation, MdLocalHospital, MdLocalActivity, MdEmergency, MdPolicy, MdCancel, MdConfirmationNumber, MdPublish, MdCreate, MdClose, MdCheckCircle } from "react-icons/md";
// import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
// import { Alert, Select, Snackbar, ClickAwayListener } from "@mui/material";
// import BorderColorIcon from '@mui/icons-material/BorderColor';
// import PolicyIcon from "@mui/icons-material/Policy";
// import { axiosInstance } from "../../Utils/axiosInstance";


// function FlowActions() {
//     const [policyData, setPolicyData] = useState({})
//     const [actions, setActions] = useState([]);
//     const [sortedActions,setSortedActions] = useState([]);
//     const [nextActions, setNextActions] = useState([]);
//     const [commentOpen, setCommentOpen] = useState(false);
//     const [commentText, setCommentText] = useState("");
//     const [editMode, setEditMode] = useState(false);
//     const [commentValid, setCommentValid] = useState(false);
//     const [doctorValid, setDoctorValid] = useState(false);
//     const [data, setData] = useState([]);
//     const [openDialog, setOpenDialog] = useState(false);
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
//     const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

//     const location = useLocation();
//     const searchParams = new URLSearchParams(location.search);
//     const policyId = searchParams.get("policy_id");

//     useEffect(() => {
//         fetchPolicyDataById();
//     }, []);

//     const fetchPolicyDataById = () => {
//         axiosInstance.get(ApiUrls.POLICY_API + "/" + policyId, {
//         })
//             .then(response => {
//                 setPolicyData(response.data);
//                 let policyActions = [];
//                 try {
//                     policyActions = Array.isArray(JSON.parse(response.data.policy_actions))
//                         ? JSON.parse(response.data.policy_actions) 
//                         : [];
//                 } catch (error) {
//                     console.error("Error parsing policy actions:", error);
//                 }
//                 setActions(policyActions);
//                 const sorted = policyActions.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
//                 setSortedActions(sorted);
//             })
//             .catch(error => {
//                 console.error("Error fetching data:", error);
//             });
//     };


//     console.log(sortedActions,"sortedActions")

//     const handleAssign = () => {

//     };

//     const handleChangeStatus = () => {

//     };

//     const handleComment = () => {

//     };

//     const handleSnackbarClose = () => {
//         setSnackbar({ ...snackbar, open: false });
//     };

//     const handleCommentOpen = () => {
//         setCommentOpen(true);
//         setCommentValid(false);
//     };

//     const handleCommentClose = () => {
//         setCommentOpen(false);
//         setCommentText("");
//     };

//     const openDeleteConfirmation = () => {
//         setDeleteConfirmationOpen(true);
//     };

//     const cancelDeleteFlow = () => {
//         setDeleteConfirmationOpen(false);
//     };

//     const handleCancelEdit = () => {
//         setEditMode(false);
//     };


//     const getTimelineIcon = (actionType) => {
//         switch (actionType) {
//             case 'Created':
//                 return <MdCreate style={{ backgroundColor: "#a3e1a3" }} />;
//             case 'Assigned to Director':
//                 return <MdAssignment style={{ backgroundColor: "#a3e1a3" }} />;
//             case 'Assigned to Senior Leader':
//                 return <MdAssignment style={{ backgroundColor: "blue" }} />;
//             case 'Assigned to Quality Director':
//                 return <MdPolicy style={{ backgroundColor: "blue" }} />;
//             case 'Approved':
//                 return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
//             case 'Published':
//                 return <MdPublish style={{ backgroundColor: "green" }} />;
//             case 'Revision Requested':
//                 return <MdWarning style={{ backgroundColor: "yellow" }} />;
//             case 'Rejected':
//                 return <MdCancel style={{ backgroundColor: "red" }} />;
//             case 'Expired':
//                 return <MdLocalFireDepartment style={{ backgroundColor: "gray" }} />;
//             case 'Approved by Director':
//                 return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
//             case 'Approved by Senior Leader':
//                 return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
//             case 'Approved by Quality Director':
//                 return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
//             default:
//                 return null;
//         }
//     };


//     return (
//         <div className="page-content" >
            
//             <div className="page-inner">
//             <Typography variant="h5" sx={{display:"flex", justifyContent:"center", color: "#3f6677" }}>
//                      Approval Flow
//              </Typography>
//                 <ClickAwayListener onClickAway={() => { }}>
//                     <Snackbar
//                         open={snackbar.open}
//                         autoHideDuration={2000}
//                         onClose={() => setSnackbar({ ...snackbar, open: false })}
//                         style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
//                     >
//                         <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '55px', fontSize: '12px' }}>
//                             {snackbar.message}
//                         </Alert>
//                     </Snackbar>
//                 </ClickAwayListener>
//                 <div id="main-wrapper">
//                     <div className="row">
//                         <div className="col-md-12">

//                             <section id="cd-timeline" className="cd-container">
//                                 {sortedActions.map((action, index) => (
//                                     <div key={index} className="cd-timeline-block">
//                                         <div className="cd-timeline-img">
//                                         {getTimelineIcon(action.policy_status)}
//                                         </div>
//                                         <div className="cd-timeline-content">
//                                             <h3>{action.policy_status}</h3>

//                                             <ListItem
//                                                 sx={{
//                                                     backgroundColor: "#f9f9f9",
//                                                     borderRadius: "10px",
//                                                     mb: 2,
//                                                     boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
//                                                     padding: "16px",
//                                                 }}
//                                             >
//                                                 {/* <ListItemIcon>
//                                                     <PolicyIcon color="primary" />
//                                                 </ListItemIcon> */}
//                                                 <ListItemText
//                                                     primary={
//                                                         <Box sx={{ display: "flex", alignItems: "center" }}>
//                                                             <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
//                                                                 Action by: {action.created_by_name}
//                                                             </Typography>
//                                                         </Box>
//                                                     }
//                                                     secondary={
//                                                         <>
//                                                             <div style={{
//                                                                         ml: 0,
//                                                                         backgroundColor: "#eceacc",
//                                                                         color: "rgb(115 164 158)",
//                                                                         fontWeight: "bold",
//                                                                         borderRadius:"10px"
//                                                                     }}>
//                                                                     {`comment- ${action.comments}`}
                                                                   
                                                                   
//                                                             </div>
//                                                         </>
//                                                     }
//                                                 />
//                                             </ListItem>
//                                             <span className="cd-date">
//                                                 <p>
//                                                     <Chip
//                                                         label={new Date(action.created_at).toLocaleString()}
//                                                         size="small"
//                                                         sx={{
//                                                             ml: 2,
//                                                             backgroundColor: "#e0f7fa",
//                                                             color: "#00796b",
//                                                             fontWeight: "bold",
//                                                         }}
//                                                     />
//                                                 </p>
//                                             </span>
//                                         </div>
//                                     </div>
//                                 ))}

                                

//                             </section>


                            
//                         </div>
//                     </div>
//                 </div >
//             </div >
//         </div >
//     )
// }

// export default FlowActions;