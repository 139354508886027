import React, { useEffect, useState } from "react";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import Settings from "../Settings/MySettings";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ProfileScreen = () => { 
  useAuthHeaders();
  const [user, setUser] = useState({}); 

  const userId = sessionStorage.getItem("loggedinUserId");
  const userrole = sessionStorage.getItem("loggedinUserRole");

  useEffect(() => {
    axiosInstance.get(ApiUrls.USER_API + "/" + userId)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {});
  }, [userId]);

  const name =(user.first_name?.charAt(0).toUpperCase() || "") + (user.last_name?.charAt(0).toUpperCase() || "");

  return ( 
    <div> 
      <div
             style={{
               backgroundColor: "#f3f6f9",
               padding: "0px 20px",
             }}
           >
             <ol
               style={{
                 display: "flex",
                 listStyle: "none",
                 padding: 0,
                 margin: "2px 0 5px -13px",
                 fontSize: "14px",
                 color: "#6c757d",
               }}
             >
               <li style={{ display: "inline-block", marginRight: "8px", fontFamily:"figtree" }}>
                 <Link
                   to="/home"
                   style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
                   onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                   onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                 >
                   Home
                 </Link>
               </li>
               <li style={{ display: "inline-block", marginRight: "8px",fontFamily:"figtree" }}>/</li>
               <li
                 style={{
                   display: "inline-block",
                   color: "#3f6677",
                   fontWeight: "bold",
                   fontFamily:"figtree"
                 }}
               >
                 Profile
               </li>
             </ol>
             <h4
         style={{
           color: "#3f6677",
           marginBottom: "8px",
           fontSize: "32px",
           fontWeight: "bold",
           fontFamily: "figtree",
           marginLeft:"-13px"
         }}
       >
         Profile
       </h4>
           </div>
    <div
      style={{
        width: "100%",
        // maxWidth: "1000px",
        margin: "20px auto",
        padding: "20px",
        backgroundColor: "#5a8a9a33",
        color: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", 
        fontFamily:"figtree"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "50px",
          marginTop:"15px",
          marginBottom:"15px",
          
        }}
      >
        {/* Profile Picture */}
        <div
          style={{
            width: "150px",
            height: "150px",
            backgroundColor: "#3f6677",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "28px",
            fontWeight: "bold",
            color: "white",
            marginLeft:"100px",
          }}
        > 
        <h1>{name}</h1>
          
        </div>

        {/* Profile Details */}
        <div
          style={{
            flex: 1,
            minWidth: "300px",
          }}
        >
          <h2
            style={{
              margin: 0,
              fontSize: "24px",
              fontWeight: "bold",
              color: "#1d3557",
            }}
          >
            {user.first_name + " " + user.last_name}
          </h2>
          <p
            style={{
              margin: "8px 0",
              fontSize: "16px",
              color: "#1d3557",
            }}
          >
            {user.primary_email}
          </p> 
            
             {/* user role*/}
          <h4
            style={{
              margin: "8px 0",
              fontSize: "16px",
              color: "#1d3557",
              fontWeight: "bold",
            }}
          >
            {userrole}
          </h4> 

          {/* Quote for user */}
          {/* <p
            style={{
              margin: "8px 0",
              fontSize: "14px",
              color: "#e3f2fd",
            }}
          >
            Outstanding! Your dedication to learning is inspirational!
          </p>  */}

          {/* Department and Location */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          marginTop: "30px",
        //   flexWrap: "wrap",
          gap: "20px", 
        }}
      >
        <div
          style={{
            textAlign: "start",
            flex: "1 1 200px",
          }}
        >
          <h4 style={{ margin: "10px 0", color: "#1d3557" }}>Department</h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <i
              className="fa fa-building"
              style={{
                fontSize: "22px",
                color: "#1d3557",
              }}
            />
            <h6 style={{ margin: 0, color: "#1d3557", }}>{user.department_name || "N/A"}</h6>
          </div>
        </div>
        <div
          style={{
            textAlign: "start",
            flex: "1 1 200px",
          }}
        >
          <h4 style={{ margin: "10px 0", color: "#1d3557", }}>Location</h4>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <i
              className="fa fa-map-marker-alt"
              style={{
                fontSize: "22px",
                color: "#1d3557",
              }}
            />
            <h6 style={{ margin: 0, color:"#1d3557" }}>{user.location_name || "N/A"}</h6>
          </div>
        </div>
      </div>
        </div>
      </div>

      
    
    </div>  
    <Settings/>
     
    </div>
  );
};

export default ProfileScreen;
