import { NavLink } from "react-router-dom";
import React, { useState } from "react";

export default function NavItem(props) {
  const [isHovered, setIsHovered] = useState(false);

  const toggleSubmenu = () => {
    props.setOpenIndex(!props.open);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const menuLinkStyle = {
  display: "flex",
  alignItems: "center",
  backgroundColor: isHovered ? "#2e6f8e" : "transparent", 
  cursor: "pointer",
  color: isHovered ? "#ffffff" : "#1D3557", 
  padding: "10px 15px",
  transition: "background-color 0.3s, color 0.3s",
  fontSize:"15px",
  // fontWeight: "bold",
  fontWeight:600,
  fontFamily:"figtree",
  borderRadius: "24px",
  textDecoration: "none",
  textAlign: "left",
  width: "100%",
  boxSizing: "border-box",
  "@media (maxwidth: 768px)": {
      padding: "10px 15px",
      fontSize: "13px",
    },
  ":hover": {
    backgroundColor: "black", 
  }
  };

  const submenuStyle = {
    display: props.open ? "block" : "none",
    color: "white",
    textAlign: "left",
    padding: "10px 20px",
    fontSize:"13px",
    fontFamily:"figtree",
    fontWeight:500,
    "@media (maxwidth: 768px)": {
      padding: "8px 15px",
      fontSize: "14px",
    },
  };
  
  const submenuLinkStyle = {
    display: "block",
    padding: "5px 10px",
    color: "#707070",
    fontFamily:"figtree",
    textDecoration: "none",
    transition: "background-color 0.3s, color 0.3s",
    "@media (maxwidth: 768px)": {
      padding: "5px 8px",
      fontSize: "13px",
    },
    ":hover": {
      backgroundColor: "#e0e0e0",
      color: "#000000",
    },
  }

  return (
    <div className="menu-item"  style={{ marginBottom: "10px" }}>
      <NavLink
        to={props.mainTab}
        className="menu-link"
        onClick={toggleSubmenu}
        style={menuLinkStyle}
       onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="menu-icon">
        <i className={props.icon} style={{ fontSize: "14px", color:  isHovered ? "white" : "#1D3557", paddingRight: "14px", }} />
        </span>
        <span className="menu-text" style={{ flexGrow: 1 }}>
          {props.title}
          {props.tabs && Object.keys(props.tabs).length > 0 && (
            <i className={`fa fa-angle-${props.open ? "down" : "right"}`}  style={{ marginLeft: "10px", fontSize: "14px" }}></i>
          )}
        </span>
      </NavLink>

      {props.tabs && Object.keys(props.tabs).length > 0 && props.open && (
        <div className="submenu" style={submenuStyle}>
          {Object.keys(props.tabs).map((key, index) => (
            <NavLink
              key={index}
              to={props.tabs[key]}
              className="submenu-link"
              activeClassName="active"
              style={submenuLinkStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => props.setOpenIndex(false)}
            >
              {key}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}


