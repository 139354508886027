import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
} from "@mui/material";

const CreateQuestion = ({
  setAssesmentInfo,
  setAssesmentEditor,
  aiAssessmentData = { data: [] },
  isAI,
  isEditAi,
}) => {
  const [question, setQuestion] = useState('');
  const [displayFormat, setDisplayFormat] = useState('Radio Buttons (1 Column)');
  const [questionType, setQuestionType] = useState('Multiple Choice (Only One Answer)');
  const [choices, setAnswerChoices] = useState(['', '', '']);
  const [answer, setCorrectAnswer] = useState(null);
  const [otherAnswerChoice, setOtherAnswerChoice] = useState(false);
  const [commentTextBox, setCommentTextBox] = useState(false);
  const [requireAnswer, setRequireAnswer] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false); 

  useEffect(() => {
    if (isAI && aiAssessmentData?.data?.length > 0) {
      const formattedQuestions = aiAssessmentData.data.map((item) => ({
        question: item.question || '',
        displayFormat: item.displayFormat || 'Radio Buttons (1 Column)',
        questionType: item.questionType || 'Multiple Choice (Only One Answer)',
        choices: item.choices || ['', '', ''],
        answer: item.answer || null,
        otherAnswerChoice: item.otherAnswerChoice || false,
        commentTextBox: item.commentTextBox || false,
        requireAnswer: item.requireAnswer || false,
      }));
      setQuestions(formattedQuestions);
      setAssesmentInfo([...formattedQuestions, questions]);
    }
  }, [isAI, aiAssessmentData]);

  useEffect(() => {
    if (isEditAi && aiAssessmentData?.length > 0) {
      const formattedQuestions = aiAssessmentData?.map((item) => ({
        question: item.question || '',
        displayFormat: item.displayFormat || 'Radio Buttons (1 Column)',
        questionType: item.questionType || 'Multiple Choice (Only One Answer)',
        choices: item.choices || ['', '', ''],
        answer: item.answer || null,
        otherAnswerChoice: item.otherAnswerChoice || false,
        commentTextBox: item.commentTextBox || false,
        requireAnswer: item.requireAnswer || false,
      }));
      setQuestions(formattedQuestions);
      setAssesmentInfo([...formattedQuestions, questions]);
    }
  }, [isEditAi, aiAssessmentData]);

  const handleChoiceChange = (qIndex, index, value) => {
    const updatedQuestions = [...questions];
    if (updatedQuestions[qIndex]?.choices) {
      updatedQuestions[qIndex].choices[index] = value;
      setQuestions(updatedQuestions);
    }
  };
  
  const handleRemoveChoice = (qIndex, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].choices = updatedQuestions[qIndex].choices.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleAddChoice = (qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex]?.choices?.push(''); // Add an empty string as a new choice
    setQuestions(updatedQuestions);
  };

  const validateQuestionsForm = (qIndex) => {
    const question = questions[qIndex];

    if (!question) {
      setError('Invalid question data.');
      return false;
    }

    if (!question.question.trim()) {
      setError('Question text is required.');
      return false;
    }

    if (question.choices.some(choice => !choice.trim())) {
      setError('All answer choices must be filled.');
      return false;
    }

    if (question.answer === null) {
      setError('Please select a correct answer.');
      return false;
    }

    setError('');
    return true;
  };

  const handleCancel = () => {
    setQuestions([]);
    setAssesmentEditor(false);
    setShowButtons(false); // Hide the buttons
  };

  const handleAddQuestion = () => {
    validateQuestionsForm()
    const newQuestion = {
      question: '',
      displayFormat: 'Radio Buttons (1 Column)',
      questionType: 'mcq', 
      choices: ['', '', ''],
      answer: null,
      otherAnswerChoice: false,
      commentTextBox: false,
      requireAnswer: false,
    };
  
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions, newQuestion];
      // Ensure this is updated for both isAI true and false
      setAssesmentInfo(updatedQuestions);
      return updatedQuestions;
    });
  
    setIsAddingQuestion(true);
  };
  
  const handleDeleteQuestion = (qIndex) => {
    const updatedQuestions = questions.filter((_, index) => index !== qIndex);
    setQuestions(updatedQuestions);
    setAssesmentInfo(updatedQuestions);
  };
  
  const handleCorrectAnswerChange = (qIndex, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answer = index;
    setQuestions(updatedQuestions);
  };
  return (
    <div
      style={{
        width: '95%',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#f5f7f9',
        border: '1px solid #d0d5db',
        borderRadius: '8px', 
        fontFamily:"figtree"
      }}
    >
      {isAI === false && (
        <div>
          {questions.map((questionData, qIndex) => (
            <div key={qIndex} style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ marginBottom: '10px' , color: "#1D3557",fontFamily: "figtree",}}>Question {qIndex + 1}</h3>
                <button
                  type="button"
                  onClick={() => handleDeleteQuestion(qIndex)}
                  style={{

                    color: '#1D3557',
                    // borderRadius: '50%',
                    border: 'none',
                    backgroundColor: 'transparent',
                    justifyContent: "flex-end",
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
              <input
                type="text"
                placeholder="Enter Question Text"
                value={questionData.question}
                onChange={(e) => {
                  const updatedQuestions = [...questions];
                  updatedQuestions[qIndex].question = e.target.value;
                  setQuestions(updatedQuestions);
                }}
                style={{
                  width: '100%',
                  padding: '8px',
                  border: '1px solid #d0d5db',
                  borderRadius: '4px',
                  marginTop: '5px',
                  marginBottom: '10px'
                }}
              />

              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold',color: "#1D3557",fontFamily: "figtree", marginBottom: '5px' }}>
                  Display Format
                </label>
                <select
                  value={questionData.displayFormat}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].displayFormat = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',
                    color: "#1D3557",fontFamily: "figtree",
                  }}
                >
                  <option>Radio Buttons (1 Column)</option>
                  <option>Radio Buttons (2 Columns)</option>
                  <option>Radio Buttons (3 Columns)</option>
                  <option>Radio Buttons (4 Columns)</option>
                  <option>Radio Buttons (Horizontal)</option>
                  <option>Drop-Down Menu</option>
                  <option>Searchable Drop-Down Menu</option>
                </select>
              </div>

              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px', color: "#1D3557",fontFamily: "figtree", }}>
                  Question Type
                </label>
                <select
                  value={questionData.questionType}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].questionType = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',
                    color: "#1D3557",fontFamily: "figtree",
                  }}
                >
                  <option>Multiple Choice (Only One Answer)</option>
                  <option>Multiple Choice (Multiple Answers)</option>
                  <option>Text</option>
                  <option>Rating</option>
                </select>
              </div>

              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' ,color: "#1D3557",fontFamily: "figtree",}}>
                  Answer Choices
                </label>
                {questionData.choices.map((choice, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                    <input
                      type="text"
                      placeholder="Enter Answer Choice"
                      value={choice}
                      onChange={(e) => handleChoiceChange(qIndex, index, e.target.value)}
                      style={{ flexGrow: 1, padding: '8px', marginRight: '5px' ,color: "#1D3557",fontFamily: "figtree",}}
                    />
                    <input
                      type="radio"
                      name={`answer${qIndex}`}
                      checked={questionData.answer === index}
                      onChange={() => handleCorrectAnswerChange(qIndex, index)}
                    /> 
                    
                    <button
                      type="button"
                      onClick={() => handleRemoveChoice(qIndex, index)}
                      style={{ marginLeft: '5px' }}
                    >
                      -
                    </button>
                  </div>
                ))}
                 <Button
                  variant="contained" onClick={() => handleAddChoice(qIndex)} style={{ marginTop: '10px', backgroundColor: "#3f6677",  color: "#fff",  fontFamily: "figtree",}}>
                  Add Choice
                </Button>
              </div>

              <div style={{ marginBottom: '10px',color: "#1D3557",  fontFamily: "figtree", }}>
                <label>
                  <input
                    type="checkbox"
                    checked={questionData.otherAnswerChoice}
                    onChange={() => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[qIndex].otherAnswerChoice = !updatedQuestions[qIndex].otherAnswerChoice;
                      setQuestions(updatedQuestions);
                    }}
                  />{' '}
                  Add 'Other' Answer Choice
                </label>
              </div>

              <div style={{ marginBottom: '10px',color: "#1D3557",  fontFamily: "figtree", }}>
                <label>
                  <input
                    type="checkbox"
                    checked={questionData.commentTextBox}
                    onChange={() => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[qIndex].commentTextBox = !updatedQuestions[qIndex].commentTextBox;
                      setQuestions(updatedQuestions);
                    }}
                  />{' '}
                  Add 'Comment' Text Box
                </label>
              </div>

              <div style={{ marginBottom: '20px',color: "#1D3557",  fontFamily: "figtree", }}>
                <label>
                  <input
                    type="checkbox"
                    checked={questionData.requireAnswer}
                    onChange={() => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[qIndex].requireAnswer = !updatedQuestions[qIndex].requireAnswer;
                      setQuestions(updatedQuestions);
                    }}
                  />{' '}
                  Require an Answer to this Question
                </label>
              </div>
            </div>
          ))}
        </div>
      )}

      {
        isAI === true &&
        questions?.map((questionData, qIndex) => (
          <div key={qIndex} style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 style={{ marginBottom: '10px',color: "#1D3557",fontFamily: "figtree", }}>Question {qIndex + 1}</h3>
              <button
                type="button"
                onClick={() => handleDeleteQuestion(qIndex)}
                style={{
                  color: '#1D3557',
                  border: 'none',
                  backgroundColor: 'transparent',
                  justifyContent: "flex-end",
                }}
              >
                <DeleteIcon />
              </button>
            </div>
            {isAddingQuestion && qIndex === questions.length - 1 ? (
              <input
                type="text"
                placeholder="Enter Question Text"
                value={questionData.question}
                onChange={(e) => {
                  const updatedQuestions = [...questions];
                  updatedQuestions[qIndex].question = e.target.value;
                  setQuestions(updatedQuestions);
                }}
                style={{
                  width: '100%',
                  padding: '8px',
                  border: '1px solid #d0d5db',
                  borderRadius: '4px',
                  marginTop: '5px',
                }}
              />
            ) : (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' ,color: "#1D3557",fontFamily: "figtree",}}>Question</label>
                <input
                  type="text"
                  placeholder="Enter Question Text"
                  value={questionData?.question || ''}
                  onChange={(e) => handleChoiceChange(qIndex, e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',
                    marginTop: '5px'
                  }}
                />
              </div>
            )}
            {isAddingQuestion && qIndex === questions.length - 1 ? (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>
                  Display Format
                </label>
                <select
                  value={questionData.displayFormat}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].displayFormat = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',color: "#1D3557",fontFamily: "figtree",
                  }}
                >
                  <option>Radio Buttons (1 Column)</option>
                  <option>Radio Buttons (2 Columns)</option>
                  <option>Radio Buttons (3 Columns)</option>
                  <option>Radio Buttons (4 Columns)</option>
                  <option>Radio Buttons (Horizontal)</option>
                  <option>Drop-Down Menu</option>
                  <option>Searchable Drop-Down Menu</option>
                </select>
              </div>
            ) : (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px',color: "#1D3557",fontFamily: "figtree", }}>Display Format</label>
                <input
                  value={questionData.displayFormat}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].displayFormat = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',color: "#1D3557",fontFamily: "figtree",
                  }}
                >
                </input>
              </div>
            )}

            {isAddingQuestion && qIndex === questions.length - 1 ? (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px', color: "#1D3557",fontFamily: "figtree", }}>
                  Select Question Type
                </label>
                <select
                  value={questionData.questionType}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].questionType = e.target.value;
                    setQuestions(updatedQuestions);
                    setIsAddingQuestion(false); // Exit "Add Question" mode
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',color: "#1D3557",fontFamily: "figtree",
                  }}
                >
                  <option value="mcq">Multiple Choice (Only One Answer)</option>
                  <option value="Text">Text</option>
                  <option value="Rating">Rating</option>
                </select>
              </div>
            ) : (
              // Regular display when not adding a new question
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px',color: "#1D3557",fontFamily: "figtree", }}>
                  Question Type
                </label>
                <input
                  type="text"
                  value={questionData.questionType || ''}
                  readOnly
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',color: "#1D3557",fontFamily: "figtree",
                  }}
                />
              </div>
            )}


            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px',color: "#1D3557",fontFamily: "figtree", }}>Answer Choices</label>
              {questionData?.choices?.map((choice, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px',color: "#1D3557",fontFamily: "figtree", }}>
                  <input
                    type="text"
                    placeholder="Enter Answer Choice"
                    value={choice} // Bind the specific choice at the current index
                    onChange={(e) => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[qIndex].choices[index] = e.target.value; // Update the specific choice
                      setQuestions(updatedQuestions);
                    }}
                    style={{ flexGrow: 1, padding: '8px', marginRight: '5px', color: "#1D3557",fontFamily: "figtree", }}
                  />
                  <input
                    type="radio"
                    name={`answer${qIndex}`}
                    checked={questionData.answer === choice} // Compare with the actual choice text
                    onChange={() => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[qIndex].answer = choice; // Set the correct answer
                      setQuestions(updatedQuestions);
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveChoice(qIndex, index)} // Remove the specific choice
                    style={{ marginLeft: '5px' }}
                  >
                    -
                  </button>
                </div>
              ))}
              <Button
                variant="contained"
                onClick={() => handleAddChoice(qIndex)} // Add a new choice
                style={{ marginTop: '10px', backgroundColor: "#3f6677",  color: "#fff",  fontFamily: "figtree",}}>
                Add Choice
                </Button>
            </div>


            <div style={{ marginBottom: '10px' }}>
              <label>
                <input
                  type="checkbox"
                  checked={questionData.commentTextBox}
                  onChange={() => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].commentTextBox = !updatedQuestions[qIndex].commentTextBox;
                    setQuestions(updatedQuestions);
                  }}
                /> Add 'Comment' Text Box
              </label>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <label>
                <input
                  type="checkbox"
                  checked={questionData.requireAnswer}
                  onChange={() => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].requireAnswer = !updatedQuestions[qIndex].requireAnswer;
                    setQuestions(updatedQuestions);
                  }}
                /> Require an answer
              </label>
            </div>
          </div>
        ))
      }
      {
        isEditAi === true &&
        questions?.map((questionData, qIndex) => (
          <div key={qIndex} style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 style={{ marginBottom: '10px' }}>Question {qIndex + 1}</h3>
              <button
                type="button"
                onClick={() => handleDeleteQuestion(qIndex)}
                style={{
                  color: '#1D3557',
                  border: 'none',
                  backgroundColor: 'transparent',
                  justifyContent: "flex-end",
                }}
              >
                <DeleteIcon />
              </button>
            </div>
            {isAddingQuestion && qIndex === questions.length - 1 ? (
              <input
                type="text"
                placeholder="Enter Question Text"
                value={questionData.question}
                onChange={(e) => {
                  const updatedQuestions = [...questions];
                  updatedQuestions[qIndex].question = e.target.value;
                  setQuestions(updatedQuestions);
                }}
                style={{
                  width: '100%',
                  padding: '8px',
                  border: '1px solid #d0d5db',
                  borderRadius: '4px',
                  marginTop: '5px',
                  color: "#1D3557",
                  fontFamily: "figtree",
                }}
              />
            ) : (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Question</label>
                <input
                  type="text"
                  placeholder="Enter Question Text"
                  value={questionData?.question || ''}
                  onChange={(e) => handleChoiceChange(qIndex, e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',
                    marginTop: '5px'
                  }}
                />
              </div>
            )}
            {isAddingQuestion && qIndex === questions.length - 1 ? (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold',color: "#1D3557",fontFamily: "figtree", marginBottom: '5px' }}>
                  Display Format
                </label>
                <select
                  value={questionData.displayFormat}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].displayFormat = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',
                    color: "#1D3557",
                    fontFamily: "figtree",
                  }}
                >
                  <option>Radio Buttons (1 Column)</option>
                  <option>Radio Buttons (2 Columns)</option>
                  <option>Radio Buttons (3 Columns)</option>
                  <option>Radio Buttons (4 Columns)</option>
                  <option>Radio Buttons (Horizontal)</option>
                  <option>Drop-Down Menu</option>
                  <option>Searchable Drop-Down Menu</option>
                </select>
              </div>
            ) : (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Display Format</label>
                <input
                  value={questionData.displayFormat}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].displayFormat = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px'
                  }}
                >
                </input>
              </div>
            )}

            {isAddingQuestion && qIndex === questions.length - 1 ? (
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold',color: "#1D3557", marginBottom: '5px' }}>
                  Select Question Type
                </label>
                <select
                  value={questionData.questionType}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].questionType = e.target.value;
                    setQuestions(updatedQuestions);
                    setIsAddingQuestion(false); // Exit "Add Question" mode
                  }}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',
                  }}
                >
                  <option value="mcq">Multiple Choice (Only One Answer)</option>
                  <option value="Text">Text</option>
                  <option value="Rating">Rating</option>
                </select>
              </div>
            ) : (
              // Regular display when not adding a new question
              <div style={{ marginBottom: '15px' }}>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px',color: "#1D3557", }}>
                  Question Type
                </label>
                <input
                  type="text"
                  value={questionData.questionType || ''}
                  readOnly
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #d0d5db',
                    borderRadius: '4px',
                    color: "#1D3557",
                  }}
                />
              </div>
            )}


            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Answer Choices</label>
              {questionData?.choices?.map((choice, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <input
                    type="text"
                    placeholder="Enter Answer Choice"
                    value={choice} // Bind the specific choice at the current index
                    onChange={(e) => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[qIndex].choices[index] = e.target.value; // Update the specific choice
                      setQuestions(updatedQuestions);
                    }}
                    style={{ flexGrow: 1, padding: '8px', marginRight: '5px' }}
                  />
                  <input
                    type="radio"
                    name={`answer${qIndex}`}
                    checked={questionData.answer === choice} // Compare with the actual choice text
                    onChange={() => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[qIndex].answer = choice; // Set the correct answer
                      setQuestions(updatedQuestions);
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveChoice(qIndex, index)} // Remove the specific choice
                    style={{ marginLeft: '5px' }}
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddChoice(qIndex)} // Add a new choice
                style={{ marginTop: '10px' }}
              >
                Add Choice
              </button>
            </div>


            <div style={{ marginBottom: '10px',color: "#1D3557",fontFamily: "figtree", }}>
              <label>
                <input
                  type="checkbox"
                  checked={questionData.commentTextBox}
                  onChange={() => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].commentTextBox = !updatedQuestions[qIndex].commentTextBox;
                    setQuestions(updatedQuestions);
                  }} 
                  style={{
                    color: "#1D3557",fontFamily: "figtree",
                  }}
                /> Add 'Comment' Text Box
              </label>
            </div>

            <div style={{ marginBottom: '20px',color: "#1D3557",fontFamily: "figtree", }}>
              <label style={{ color: "#1D3557", fontFamily: "figtree" }}>
                <input
                  type="checkbox"
                  checked={questionData.requireAnswer}
                  onChange={() => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].requireAnswer = !updatedQuestions[qIndex].requireAnswer;
                    setQuestions(updatedQuestions);
                  }} 
                  style={{
                    // Checkboxes do not use these styles directly
                    appearance: "none", // Use this if applying custom styling
                  }}
                /> Require an answer
              </label>
            </div>
          </div>
        ))
      }
      {
        showButtons && (
          <div >
          
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            {/* {isAI == true && (
              <button
                type="button"
                onClick={() => regenerate()} 
                style={{
                  padding: '8px 16px',
                  backgroundColor: '#27ae60',
                  color: 'white',
                }}
              >
                Regenerate
              </button>
            )} */}
          
            
             <Button
            variant="contained "
              onClick={handleCancel}
              style={{
                fontFamily: "figtree",
                backgroundColor: '#5a8a9a33',
                color: '#1D3557',
              }}
            >
              Cancel
              </Button>
            <Button
            variant="contained "
              onClick={handleAddQuestion}
              style={{
                fontFamily: "figtree",
                backgroundColor: '#3f6677',
                color: '#fff',
              }}
            >
              Add Question
              </Button>
          </div>
          </div>
        )
      }
    </div >
  );
};

export default CreateQuestion;