import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { axiosInstance, useAuthHeaders } from '../../Utils/axiosInstance';
import ApiUrls from '../../Configurations/ConfigurationsApiUrls';
import { useNavigate } from 'react-router-dom';

const ViewQuestions = ({ questions, lastAttemptedAssessmentInfo, loading, assignmentId, setAssignment }) => {
    const [answers, setAnswers] = useState([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [submittedAnswers, setSubmittedAnswers] = useState(Array.isArray(lastAttemptedAssessmentInfo) ? lastAttemptedAssessmentInfo : []);
    const [notAttemptedCount, setNotAttemptedCount] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(submittedAnswers)) {
            const notAttempted = questions.filter(q => {
                const submittedAnswer = submittedAnswers?.find(sa => sa.question === q.question);
                return !submittedAnswer?.userAnswer && submittedAnswer?.isCorrect === false;
            }).length;
            setNotAttemptedCount(notAttempted);
        }
    }, [questions, submittedAnswers]);


    const handleAnswerChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value;
        setAnswers(updatedAnswers);

        if (isSubmitted) {
            setIsSubmitted(false);
        }
    };

    const isQuestionDisabled = (question) => {
        const submittedAnswer = submittedAnswers?.find(sa => sa.question === question.question);
        return submittedAnswer?.isCorrect === true;
    };

    const getChoiceStyle = (question, choice) => {
        const submittedAnswer = submittedAnswers?.find(sa => sa.question === question.question);

        if (!submittedAnswer) return 'inherit';

        // For previously submitted correct answers (always show green)
        if (submittedAnswer.isCorrect && choice === submittedAnswer.userAnswer) {
            return 'green';
        }

        // Only show red if this specific question was attempted and got wrong
        if (isSubmitted &&
            submittedAnswer.userAnswer && // Has an answer
            submittedAnswer.userAnswer === choice && // This choice was selected
            !submittedAnswer.isCorrect && // Answer was wrong
            answers[questions.findIndex(q => q.question === question.question)] !== undefined // Question was attempted in current submission
        ) {
            return 'red';
        }

        return 'inherit';
    };

    const handleSubmit = () => {
        const questionsAndAnswers = questions.map((q, index) => {

            const previousAnswer = Array.isArray(lastAttemptedAssessmentInfo)
                ? lastAttemptedAssessmentInfo.find(sa => sa.question === q.question)
                : null;
            const correctAnswer = answers[index] !== undefined
                ? (Array.isArray(answers[index]) ? answers[index].join(', ') : answers[index])
                : previousAnswer?.userAnswer || '';

            return {
                question: q.question,
                correctAnswer,
            };
        });

        const requestBody = { questionsAndAnswers };

        axiosInstance.post(ApiUrls.ASSIGNMENT_API + "/status/" + assignmentId, requestBody)
            .then((response) => {
                setIsSubmitted(true);
                if (response.data.message === "Assessment completed") {
                    axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
                        {
                            last_attempted_info: response.data.result,
                            assessment_taken: true,
                            assessment_status: "Completed"
                        })
                    setSubmittedAnswers(response.data.result);
                    setDialogMessage("Your assessment was completed successfully. Do you want to mark this policy as complete?");
                    setConfirmDialogOpen(true);

                } else if (response.data.message === "Assessment Not completed") {
                    axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
                        {
                            last_attempted_info: response.data.result,
                            assessment_taken: true,
                            assessment_status: "Attempted"
                        })
                    setSubmittedAnswers(response.data.result);
                    setDialogMessage(`The assessment is incomplete. Kindly try answering the questions again.`)
                    setMessageDialogOpen(true);
                }
            })
            .catch(() => {
                setDialogMessage("An error occurred while submitting your answers. Please try again.");
                setMessageDialogOpen(true);
            })

    };

    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    const closeMessageDialog = () => {
        setMessageDialogOpen(false);
    };

    const handleConfirmComplete = () => {
        setConfirmDialogOpen(false);
        axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
            {
                assignment_status: "Completed",
                completed_date: new Date(),
                assessment_taken: true,
                assessment_status: "Completed"
            }
        )
            .then(() => {
                setAssignment((prevAssignment) => ({
                    ...prevAssignment,
                    assignment_status: "Completed",
                }));
                setDialogMessage("Your assignment was marked completed successfully.");
                setMessageDialogOpen(true);
                setTimeout(() => {
                    navigate('/home');
                }, 2000);
            })
            .catch(() => {
                setDialogMessage("An error occurred while changing status of your assignment. Please try again.");
                setMessageDialogOpen(true);
            })
    }

    if (questions.length === 0) {
        return (
            <p style={{ textAlign: 'center', color: 'gray', marginTop: '20px' }}>
                No assessment for this policy.
            </p>
        );
    }


    return (
        <div style={{ width: '95%', margin: '0 auto', padding: '20px', backgroundColor: '#f5f7f9', border: '1px solid #d0d5db', borderRadius: '8px' }}>
            {loading ? (
                <p>Loading questions...</p>
            ) : (
                <>
                    <h2 style={{ color: 'rgb(118 139 161)', alignItems: "center", justifyContent: "center" }}>Answer the Questions</h2>
                    {questions.map((q, index) => {
                        const submittedAnswer = Array.isArray(submittedAnswers)
                            ? submittedAnswers.find(sa => sa.question === q.question)
                            : null;

                        const isCorrect = submittedAnswer?.isCorrect;
                        const userAnswer = submittedAnswer?.userAnswer;

                        return (
                            <div key={index} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
                                <h4>{q.question}</h4>
                                {q.questionType === 'mcq' && (
                                    <p> Multiple Choice (Only One Answer)</p>
                                )}

                                {q?.questionType === 'mcq' && (
                                    <div>
                                        {q?.choices.map((choice, i) => (
                                            <div key={i}
                                                style={{ marginBottom: '8px', }}>
                                                <input
                                                    type="radio"
                                                    id={`q${index}-choice${i}`}
                                                    name={`q${index}`}
                                                    value={choice}
                                                    checked={
                                                        answers[index] === choice ||
                                                        (submittedAnswers?.find(sa =>
                                                            sa.question === q.question)?.userAnswer === choice &&
                                                            submittedAnswers?.find(sa =>
                                                                sa.question === q.question)?.isCorrect)
                                                    }
                                                    onChange={() => handleAnswerChange(index, choice)}
                                                    disabled={isQuestionDisabled(q)}
                                                />
                                                <label
                                                    htmlFor={`q${index}-choice${i}`}
                                                    style={{
                                                        marginLeft: '5px',
                                                        color: getChoiceStyle(q, choice)
                                                    }}
                                                >
                                                    {choice}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {q.questionType === 'Multiple Choice (Multiple Answers)' && (
                                    <div>
                                        {q.answerChoices.map((choice, i) => (
                                            <div key={i} style={{ marginBottom: '8px', color: q.answer.includes(choice) && isCorrect ? 'green' : 'inherit', }}>
                                                <input
                                                    type="checkbox"
                                                    id={`q${index}-choice${i}`}
                                                    value={choice}
                                                    checked={
                                                        (userAnswer && userAnswer.includes(choice)) ||
                                                        (q.correctAnswer.includes(choice) && isCorrect)
                                                    }
                                                    onChange={(e) => {
                                                        const updatedAnswers = [...answers];
                                                        if (e.target.checked) {
                                                            updatedAnswers[index] = [...(updatedAnswers[index] || []), choice];
                                                        } else {
                                                            updatedAnswers[index] = updatedAnswers[index].filter((answer) => answer !== choice);
                                                        }
                                                        setAnswers(updatedAnswers);
                                                    }}
                                                    disabled={isCorrect === true}

                                                />
                                                <label htmlFor={`q${index}-choice${i}`} style={{ marginLeft: '5px', color: q.correctAnswer.includes(choice) && isCorrect ? 'green' : 'inherit', }}>{choice}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {q.questionType === 'Text' && (
                                    <div style={{ marginTop: '10px' }}>
                                        <textarea
                                            value={answers[index] || ''}
                                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                                            placeholder="Your Answer"
                                            style={{
                                                width: '100%',
                                                height: '100px',
                                                padding: '8px',
                                                border: '1px solid #d0d5db',
                                                borderRadius: '4px',
                                            }}
                                            disabled={isCorrect !== undefined}
                                        />
                                    </div>
                                )}

                                {q.questionType === 'Rating' && (
                                    <div>
                                        <label>Rating:</label>
                                        <select
                                            value={answers[index] || ''}
                                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                                            style={{ padding: '8px', borderRadius: '4px', border: '1px solid #d0d5db', width: '100px' }}
                                            disabled={isCorrect !== undefined}
                                        >
                                            <option value="">Select Rating</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                )}

                                {q.otherAnswerChoice && (
                                    <div style={{ marginTop: '10px' }}>
                                        <input
                                            type="text"
                                            value={answers[index]?.other || ''}
                                            onChange={(e) => {
                                                const updatedAnswers = [...answers];
                                                updatedAnswers[index] = { ...updatedAnswers[index], other: e.target.value };
                                                setAnswers(updatedAnswers);
                                            }}
                                            placeholder="Other Answer (Optional)"
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                border: '1px solid #d0d5db',
                                                borderRadius: '4px',
                                            }}
                                            disabled={isCorrect !== undefined}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {assignmentId && (
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                            <Button variant="contained" onClick={handleSubmit} color="primary">
                                Submit
                            </Button>
                        </div>
                    )}
                </>
            )}

            <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
                <DialogTitle>Assessment Completed</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#7da1b2", color: "white" }} onClick={closeConfirmDialog}>
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: "#34425a", color: "white" }}
                        onClick={handleConfirmComplete}
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={messageDialogOpen} onClose={closeMessageDialog}>
                <DialogContent>
                    <DialogContentText
                        dangerouslySetInnerHTML={{ __html: dialogMessage }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#7da1b2", color: "white" }} onClick={closeMessageDialog}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );

};

export default ViewQuestions;
