import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Box,
  Autocomplete,
  FormControl,
  Select,
} from "@mui/material";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Settings = () => {
  useAuthHeaders();
  const [user, setUser] = useState({});
  const [config, setConfig] = useState({
    notificationsEnabled: false,
    emailNotifications: false,
    darkMode: true,
    Substitute: false,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [conformPassword, setConformPassword] = useState(false);
  const [error, setError] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [userList, setUserList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  const userEmail = sessionStorage.getItem("loggedinUserEmail");
  const userId = sessionStorage.getItem("loggedinUserId");
  const loggedinUser = JSON.parse(sessionStorage.getItem("loggedinUser"));
  const roleId = loggedinUser.role_id;
  const departmentId = loggedinUser.department_id;
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setPasswordForm({
      otp: "",
      newPassword: "",
      confirmPassword: "",
    });
    setError("");
    setOtpMessage("");
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(ApiUrls.USER_API + "/" + userId)
      .then((response) => {
        const userData = response.data;
        setUser(userData);
        setConfig({
          notificationsEnabled: userData.app_notification === 1,
          emailNotifications: userData.email_notification === 1,
          Substitute: userData.is_substitute === 1,
        });
        setSelectedUser(userData.substitute_user_id || null);
        setStartDate(
          userData.substitute_from_date
            ? new Date(userData.substitute_from_date)
            : null
        );
        setEndDate(
          userData.substitute_to_date
            ? new Date(userData.substitute_to_date)
            : null
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
        setAlert({
          message: "Failed to fetch user data. Please try again later.",
          severity: "warning",
          show: true,
        });
      });
  }, [userId]);

  useEffect(() => {
    handleSubstitute();
  }, []);

  const handleSubstitute = () => {
    setLoading(true);
    axiosInstance
      .get(
        ApiUrls.USER_API +
          "/byrole/substitute/beforeroles/" +
          roleId +
          "/department/" +
          departmentId
      )
      .then((response) => {
        setUserList(response.data);
        setLoading(false);
      })
      .catch(() => {
        setError("Error while fetching user details. Please try again.");
        setLoading(false);
      });
  };

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget); // Set the dropdown anchor
    setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
  };

  const handleClose = () => {
    setDropdownOpen(false); // Close dropdown
  };

  const handleFormChange = (field) => (event) => {
    setPasswordForm({ ...passwordForm, [field]: event.target.value });
  };

  const handleOtpChange = (field) => (event) => {
    const numericText = event.target.value.replace(/[^0-9]/g, "");
    setPasswordForm({ ...passwordForm, [field]: numericText });
  };

  const handleGenerateOtp = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        ApiUrls.USER_API +
          `/generate/otp?email=${encodeURIComponent(userEmail)}`
      );
      if (response) {
        setOtpMessage("OTP sent successfully to your email.");
      } else {
        const message =
          typeof response.data.message === "string"
            ? response.data.message
            : "Failed to generate OTP.";
        setOtpMessage(message);
      }
    } catch (err) {
      console.error("Error generating OTP:", err);
      const message =
        typeof err.response?.data?.message === "string"
          ? err.response.data.message
          : "An error occurred while generating the OTP.";
      setOtpMessage(message);
      setAlert({
        message,
        severity: "error",
        show: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async () => {
    const { otp, newPassword, confirmPassword } = passwordForm;

    if (!otp || !newPassword || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters.");
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.put(
        ApiUrls.USER_API + "/withotp/email/updatepassword",
        {
          primary_email: userEmail,
          otp: otp,
          new_password: newPassword,
        }
      );
      if (response) {
        axiosInstance.put(ApiUrls.USER_API + "/" + userId, {
          password_lastupdated_at: new Date(),
        });
        setOpenDialog(false);
        setAlert({
          message: "Password changed successfully!",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 3000);
      } else {
        const message =
          typeof response.data.message === "string"
            ? response.data.message
            : "Failed to change password.";
        setError(message);
        setAlert({
          message,
          severity: "warning",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 3000);
      }
    } catch (err) {
      console.error("Error changing password:", err);
      const message =
        typeof err.response?.data?.message === "string"
          ? err.response.data.message
          : "An error occurred while changing the password.";
      setError(message);
      setAlert({
        message,
        severity: "warning",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchChange = (field) => async (event) => {
    const updatedValue = event.target.checked;

    // Update the local state
    setConfig((prevConfig) => ({ ...prevConfig, [field]: updatedValue }));

    let payload = {};

    if (field === "Substitute") {
      setIsChanged(true);
      setDropdownOpen(updatedValue);

      if (updatedValue) {
        // When Substitute is enabled
        payload = {
          is_substitute: true,
          substitute_user_id: selectedUser?.user_id,
          substitute_from_date: startDate,
          substitute_to_date: endDate,
        };
      } else {
        // When Substitute is disabled
        setSelectedUser(null);
        setStartDate(null);
        setEndDate(null);

        payload = {
          is_substitute: false,
          substitute_user_id: -1,
          substitute_from_date: null,
          substitute_to_date: null,
        };
      }
    } else {
      payload = {
        ...(field === "notificationsEnabled" && {
          app_notification: updatedValue,
        }),
        ...(field === "emailNotifications" && {
          email_notification: updatedValue,
        }),
      };
    }
    try {
      const response = await axiosInstance.put(
        `${ApiUrls.USER_API}/${userId}`,
        payload
      );
      setUser({ ...user, is_substitute: 1 });
      setAlert({
        message: "Settings updated successfully!",
        severity: "success",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } catch (error) {
      console.error("Error updating toggle:", error);

      setConfig((prevConfig) => ({ ...prevConfig, [field]: !updatedValue }));
      setAlert({
        message: "Failed to update setting. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConformPasswordVisibility = () => {
    setConformPassword((prevState) => !prevState);
  };

  const formatDate = (date) => new Date(date).toLocaleString();

  const handleSave = async () => {
    if (config.Substitute && (!selectedUser || !startDate || !endDate)) {
      setAlert({
        message: "Please fill out all fields.",
        severity: "warning",
        show: true,
      });
      setTimeout(() => setAlert((alert) => ({ ...alert, show: false })), 2000);
      return;
    }

    const payload = {
      is_substitute: true,
      substitute_user_id: selectedUser?.user_id,
      substitute_from_date: startDate,
      substitute_to_date: endDate,
    };

    if (!config.Substitute) {
      setSelectedUser(null);
      setStartDate(null);
      setEndDate(null);
    }

    try {
      await axiosInstance.put(`${ApiUrls.USER_API}/${userId}`, payload);
      setAlert({
        message: "Substitute added successfully.",
        severity: "success",
        show: true,
      });
      setIsChanged(false);
      setTimeout(() => setAlert((alert) => ({ ...alert, show: false })), 3000);
    } catch (error) {
      setAlert({
        message: "Failed to update settings. Please try again.",
        severity: "warning",
        show: true,
      });
      setTimeout(() => setAlert((alert) => ({ ...alert, show: false })), 2000);
    }
  };

  const handleDateChange = (setter) => (date) => {
    setter(date);
    setIsChanged(true);
  };

  const validateDate = (date) => {
    if (!date) return "Date is required.";
    return "";
  };

  return (
    <div>
      {loading && (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <CircularProgress />
        </div>
      )}
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}

      <Paper
        sx={{
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          fontFamily: "figtree",
        }}
      >
        <div style={{ marginTop: "10px" }}>
          <Typography
            sx={{
              color: "#1d3557",
              fontWeight: "bold",
              fontFamily: "figtree",
              fontSize: "16px",
            }}
          >
            Password
          </Typography>
          <Typography
            sx={{
              color: "#1d3557",
              mt: 1,
              fontFamily: "figtree",
              fontSize: "14px",
            }}
          >
            Your password was last updated on{" "}
            {formatDate(user.password_lastupdated_at) || "N/A"}
          </Typography>
          <Button
            variant="text"
            sx={{
              mt: 1,
              fontFamily: "figtree",
              color: "#1d3557",
              fontSize: "14px",
            }}
            onClick={handleDialogOpen}
          >
            Change password
          </Button>
        </div>

        <hr style={{ border: "1px solid #ddd", margin: "20px 0" }} />

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px",
                fontFamily: "figtree",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleGenerateOtp}
                sx={{ width: 250, height: 56, fontFamily: "figtree" }}
              >
                Generate OTP
              </Button>

              <TextField
                label="OTP"
                keyboardType="numeric"
                autoCapitalize="none"
                returnKeyType="done"
                autoCorrect={false}
                type="password"
                variant="outlined"
                margin="normal"
                value={passwordForm.otp}
                onChange={handleOtpChange("otp")}
                sx={{ width: 280, fontFamily: "figtree" }}
                InputProps={{ style: { height: 56 } }}
                inputProps={{ maxLength: 6 }}
              />
            </div>
            {otpMessage && (
              <Typography color="primary" style={{ marginLeft: "16px" }}>
                {otpMessage}
              </Typography>
            )}
            <TextField
              fullWidth
              label="New Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              value={passwordForm.newPassword}
              onChange={handleFormChange("newPassword")}
              disabled={!passwordForm.otp || passwordForm.otp.length === 0}
              sx={{ fontFamily: "figtree" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Confirm New Password"
              type={conformPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              value={passwordForm.confirmPassword}
              onChange={handleFormChange("confirmPassword")}
              disabled={!passwordForm.otp || passwordForm.otp.length === 0}
              sx={{ fontFamily: "figtree" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleConformPasswordVisibility}
                      edge="end"
                    >
                      {conformPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && <Typography color="warning">{error}</Typography>}
          </DialogContent>
          <DialogActions>
            <Button sx={{ fontFamily: "figtree" }} onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button
              sx={{ fontFamily: "figtree" }}
              onClick={handlePasswordSubmit}
              color="primary"
            >
              Change Password
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ marginTop: "10px" }}>
          <Typography
            sx={{
              color: "#1d3557",
              fontWeight: "bold",
              fontFamily: "figtree",
              fontSize: "16px",
            }}
          >
            Email Address
          </Typography>
          <Typography
            sx={{
              color: "#1d3557",
              mt: 1,
              fontFamily: "figtree",
              fontSize: "14px",
            }}
          >
            This is your email, to receive assignments and alerts about
            policies, and important system messages.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#1d3557", mt: 1, fontFamily: "figtree" }}
          >
            {user.primary_email}
          </Typography>
        </div>

        <hr style={{ border: "1px solid #ddd", margin: "20px 0" }} />

        <div style={{ marginTop: "10px" }}>
          <Typography
            sx={{
              color: "#1d3557",
              fontWeight: "bold",
              fontFamily: "figtree",
              fontSize: "16px",
            }}
          >
            Notifications
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={config.notificationsEnabled}
                onChange={handleSwitchChange("notificationsEnabled")}
                color="primary"
              />
            }
            label="App Notifications"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#1d3557",
                fontSize: "14px",
              },
            }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={config.emailNotifications}
                onChange={handleSwitchChange("emailNotifications")}
                color="primary"
              />
            }
            label="Email Notifications"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#1d3557",
                fontSize: "14px",
              },
            }}
          />
        </div>

        {roleId !== 1 &&  roleId !== 5 && (
              <>
                <hr style={{ border: "1px solid #ddd", margin: "20px 0" }} />

                <div style={{ marginTop: "10px" }}>
                  <Typography
                    sx={{
                      color: "#1d3557",
                      fontWeight: "bold",
                      fontFamily: "figtree",
                      fontSize: "16px",
                    }}
                  >
                    Substitute
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={config.Substitute}
                        onChange={async (e) => {
                          const isChecked = e.target.checked;

                          // Update config state
                          setConfig((prevConfig) => ({
                            ...prevConfig,
                            Substitute: isChecked,
                          }));

                          // Reset data and send payload when toggled off
                          if (!isChecked) {
                            setSelectedUser(null);
                            setStartDate(null);
                            setEndDate(null);

                            // Send the payload when Substitute is disabled
                            const payload = {
                              is_substitute: false,
                              substitute_user_id: -1,
                              substitute_from_date: null,
                              substitute_to_date: null,
                            };

                            try {
                              await axiosInstance.put(
                                ApiUrls.USER_API + "/" + userId,
                                payload
                              );

                              // Show success alert
                              setAlert({
                                message: "Substitute disabled successfully.",
                                severity: "success",
                                show: true,
                              });

                              // Auto-hide the alert after 2 seconds
                              setTimeout(() => {
                                setAlert((alert) => ({
                                  ...alert,
                                  show: false,
                                }));
                              }, 2000);
                            } catch (error) {
                              // Handle errors and show a warning alert
                              setAlert({
                                message:
                                  "Failed to disable substitute. Please try again.",
                                severity: "warning",
                                show: true,
                              });

                              // Auto-hide the alert after 2 seconds
                              setTimeout(() => {
                                setAlert((alert) => ({
                                  ...alert,
                                  show: false,
                                }));
                              }, 2000);
                            }
                          }
                        }}
                        color="primary"
                      />
                    }
                    label={
                      config.Substitute
                        ? "Disable Substitute"
                        : "Enable Substitute"
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#1d3557",
                        fontSize: "14px",
                      },
                    }}
                  />

                  {error && <Typography color="error">{error}</Typography>}

                  {config.Substitute && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        gap: "15px",
                        marginTop: 2,
                        alignItems: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {/* User List */}
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "figtree",
                            fontSize: "14px",
                            color: "#1d3557",
                          }}
                        >
                          Select a User
                        </Typography>
                        <Button
                          sx={{
                            width: "200px",
                            height: "40px",
                            fontFamily: "figtree",
                          }}
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          variant="outlined"
                        >
                          {selectedUser
                            ? user.substitute_first_name
                            : "Select User"}
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                          PaperProps={{
                            style: {
                              maxHeight: 250,
                              width: "25%",
                              fontFamily: "figtree",
                            },
                          }}
                        >
                          {loading ? (
                            <MenuItem>Loading...</MenuItem>
                          ) : (
                            userList.map((user, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  setSelectedUser(user);
                                  setUser((prevUser) => ({
                                    ...prevUser,
                                    substitute_first_name: user.first_name,
                                  }));
                                  setAnchorEl(null); // Close the menu
                                  setIsChanged(true);
                                }}
                              >
                                  {`${user?.first_name} ${user?.last_name} - ${user?.role_name} (${user?.department_name})`}
                              </MenuItem>
                            ))
                          )}
                        </Menu>
                      </Box>

                      {/* From Date */}
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          sx={{
                            fontFamily: "figtree",
                            fontSize: "14px",
                            color: "#1d3557",
                          }}
                        >
                          From Date
                        </Typography>
                        <DatePicker
                          selected={startDate}
                          // onChange={(date) => {
                          //   setStartDate(date);
                          //   setIsChanged(true);
                          //   const error = validateDate(date);
                          //   setErrors((prev) => ({
                          //     ...prev,
                          //     startDate: error,
                          //   }));
                          // }}  
                          onChange={(date) => {
                            if (date) {
                              // Setting a default time of 10:00 AM if the date is changed
                              date.setHours(0, 1);
                              setStartDate(date);
                              setIsChanged(true);
                              const error = validateDate(date);
                              setErrors((prev) => ({
                                ...prev,
                                startDate: error,
                              }));
                            }
                          }}
                          dateFormat="MM/dd/yyyy HH:mm" // Corrected format
                          minDate={new Date()}
                          placeholderText="MM/DD/YYYY HH:mm"
                          required
                          showYearDropdown
                          showMonthDropdown
                          // showTimeSelect 
                          // timeFormat="HH:mm" 
                          // timeIntervals={15}
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          style={{ width: "250%" }}
                          className="datepicker"
                        />
                      </Box>

                      {/* To Date */}
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          sx={{
                            fontFamily: "figtree",
                            fontSize: "14px",
                            color: "#1d3557",
                          }}
                        >
                          To Date & Time
                        </Typography>
                        <DatePicker
                          selected={endDate}
                          // onChange={(date) => {
                          //   setEndDate(date);
                          //   setIsChanged(true);
                          //   const error = validateDate(date);
                          //   setErrors((prev) => ({
                          //     ...prev,
                          //     endDate: error,
                          //   }));
                          // }} 
                          onChange={(date) => {
                            if (date) {
                              // Setting a default time of 11:59 PM if the date is changed
                              date.setHours(23, 59);
                              setEndDate(date);
                              setIsChanged(true);
                              const error = validateDate(date);
                              setErrors((prev) => ({
                                ...prev,
                                endDate: error,
                              }));
                            }
                          }}
                          dateFormat="MM/dd/yyyy HH:mm" // Corrected format
                          minDate={startDate || new Date()}
                          placeholderText="MM/DD/YYYY HH:mm"
                          required
                          showYearDropdown
                          showMonthDropdown
                          // showTimeSelect
                          // timeFormat="HH:MM"
                          // timeIntervals={15}
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          style={{ width: "250%", marginTop: "50px" }}
                          className="datepicker"
                        />
                      </Box>

                      {isChanged && (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            marginTop: "30px",
                            fontSize: "14px",
                            marginRight: "9px",
                            height: "40px",
                            width: "200px",
                          }}
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  )}
                </div>
              </>
            )}
      </Paper>

      <style jsx>{`
        .datepicker {
          margintop: 10px;
          padding: 10px;
          font-size: 12px;
          height: "50px";
        }

        .datepicker input {
          width: 100%;
          height: 100%;
          padding: 0;
          font-size: inherit;
          border: none;
          outline: none;
          margintop: 10px;
        }
      `}</style>
    </div>
  );
};

export default Settings;
