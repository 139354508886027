import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Autocomplete, IconButton, Divider, Menu, MenuItem as DropdownMenuItem, useMediaQuery, useTheme, Drawer, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Settings } from "@mui/icons-material";
import AdvanceFilterDrawer from "../AdvanceFilterDrawer.js/FilterDrawer";
import NavItem from "../NavigationBar/NavItem";
import { routers, tabs } from "../../Configurations/configurationUI";
import MenuIcon from "@mui/icons-material/Menu";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import NotificationBox from "../NotificationBox/NotificationBox";
import { getnotificationNavigation } from "../../Utils/NotificationTemp";
import Box from '@mui/material/Box';

const Header = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(Array(10).fill(false));
  const [activeItems, setActiveItems] = React.useState(Array(7).fill(false));
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const [logoUrl, setLogoUrl] = useState(null);
  const [error, setError] = useState("");
  const [keyword, setKeyword] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationError, setNotificationError] = useState("");
  const [iconLetters, setIconLetters] = useState("");  
  const [policies, setPolicies] = useState([]); 
  const [searchOptions, setSearchOptions] = useState([]);

  let location = useLocation();
  const isHomepage = location.pathname === '/home';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem('loggedinUser'));
  const userRole =  sessionStorage.getItem('loggedinUserRole');  

  useEffect(() => { 
    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/By/userId" )
      .then((response) => {
        setPolicies(response.data);
        const policyNames = response.data.map(policy => policy.policy_name);
        setSearchOptions(policyNames);
      })
      .catch((error) => console.error("Error fetching Policies:", error)); 
  }, []);


  useEffect(() => {
    const organisationId = 1;
    axiosInstance.get(ApiUrls.FILESTORAGE_API + "/organization/paths/" + organisationId)
      .then((response) => {
        setLogoUrl(response.data[0]);
      })
      .catch((error) => {
        setError("Error loading image");
      })
  }, []);

  useEffect(() => {
    const fetchNotifications = () => {
    axiosInstance.get(ApiUrls.NOTIFICATION_API + "/ByUser/Id/" + user.user_id)
      .then((response) => {
        setNotifications(response.data)
        const count = response.data.length
        setUnreadCount(count)
      })
      .catch((err) => {
        setNotificationError("Error while fetching Notifications. Please try again")
      });
    };

    fetchNotifications();

    const intervalId = setInterval(fetchNotifications, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [user.user_id]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("loggedinUser"));
    const userLetters = userData.first_name.charAt(0).toUpperCase() + userData.last_name.charAt(0).toUpperCase() || "N/A"
    setIconLetters(userLetters);
  }, []);

  const handleNotificationPress = async (notification, closeNotificationBox) => {
    try {
      await axiosInstance.put(`${ApiUrls.NOTIFICATION_API}/update/viewstatus?nfid=${notification.nfid}`);
      const [route, params] = getnotificationNavigation(notification);
      const queryString = new URLSearchParams(params).toString();
      navigate(`${route}?${queryString}`);
      if (closeNotificationBox) {
        closeNotificationBox();
      }
      setUnreadCount((prevCount) => Math.max(0, prevCount - 1));
      setTimeout(() => {
        axiosInstance.delete(`${ApiUrls.NOTIFICATION_API}/delete/notification?nfid=${notification.nfid}`)
          .then(() => {
            setNotifications((prevNotifications) =>
              prevNotifications.filter((n) => n.nfid !== notification.nfid)
            );
          })
          .catch((error) => {
            console.error('Error deleting notification:', error);
          });
      }, 1000);
    } catch (error) {
      console.error('Error handling notification:', error);
    }
  };

  const handleDeleteAlleNotifications = (notification) => {
    const nfidList = notifications.map(notification => notification.nfid).join(',');
    axiosInstance.delete(`${ApiUrls.NOTIFICATION_API}/delete/notification`, {
      params: { nfid: nfidList }
    })
      .then(() => {
        setNotifications([]);
        setUnreadCount(null);
      })
      .catch((error) => {
        console.error('Error deleting notification:', error);
      });
  }

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const setIndex = (index, stateOpen) => {
    const newOpened = [...opened];
    newOpened[index] = stateOpen;
    setOpened(newOpened);
  };

  const handleItemClick = (index) => {
    const newActiveItems = Array(10).fill(false);
    newActiveItems[index] = true;
    setActiveItems(newActiveItems);
  };

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const toggleFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleNavigateSettings = () => {
    navigate(routers.mysettings);
  };

  const handleLogout = () => {
    axiosInstance.post(ApiUrls.USER_API + "/logout")
      .then(() => {
        sessionStorage.removeItem("loggedinUser");
        sessionStorage.removeItem("loggedinUserEmail");
        sessionStorage.removeItem("loggedinUserId");
        sessionStorage.removeItem("loggedinUserRole");
        sessionStorage.removeItem("JWTToken");
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };
  
  const handleNaviagateHome = () => {
    navigate(routers.home);
  }

  // const searchOptions = [
  //   "Global Manual",
  //   "Hospital Manual",
  //   "Ambulatory Care",
  //   "Skilled Nursing Unit",
  //   "Long Term Acute Care",
  //   "Inpatient Rehabilitation Unit",
  //   "Home Health",
  // ];

  const handleSearch = () => {
    if (keyword.trim()) {
      navigate(`/searchresults?q=${encodeURIComponent(keyword.trim())}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          paddingLeft: isMobile ? "20px" : "100px",
          paddingRight: isMobile ? "20px" : "100px",
          backgroundColor: "white",
          color: "black", 
          height:"70px"
          
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }} onClick={handleNaviagateHome}>
          {/* {photo && ( */}
          <img
            src={logoUrl}
            alt="Logo"
            style={{
              height: "50px",
              // marginRight: "10px",
            }}
          />
          {/* )} */}
          {/* <div style={{ fontSize: "2em" }}>Policy Manager</div> */}
        </div> 
        {!isMobile ? (
          <nav style={{ display: "flex", alignItems: "center", justifyContent:"space-between" , gap:"6px" }}>
            {!isHomepage && (
              <Autocomplete
                freeSolo
                options={searchOptions}
                inputValue={keyword}
                onInputChange={(event, newValue) => setKeyword(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search..."
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <IconButton onClick={handleSearch}>
                            <SearchIcon />
                          </IconButton>
                          {/* <IconButton onClick={() => toggleFilterDrawer(true)}>
                            <FilterListIcon />
                          </IconButton> */}
                        </>
                      ),
                      style: {
                        height: "50px",
                        width: "300px",
                        backgroundColor: "#fff",
                        borderRadius: "30px", 
                        fontFamily:"figtree"
                      },
                    }}
                  />
                )}
              />
            )} 
            {/* <IconButton onClick={handleNavigateSettings} color="inherit">
              <Settings sx={{ fontSize: 30, color: theme.palette.info.main }} />
            </IconButton> */}
            <NotificationBox
              notifications={notifications}
              unreadCount={unreadCount}
              onNotificationClick={handleNotificationPress}
              handleClearAll={handleDeleteAlleNotifications}
              error={notificationError}
            />    
            
            <div onClick={handleProfileMenu} color="inherit">
              <div >
                <Box
                  sx={{
                    width: 38,
                    height: 38,
                    borderRadius: '50%',
                    bgcolor: '#3f6677',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 20,
                    '&:hover': {
                      bgcolor: '#5a8a9a33',
                    },
                  }}
                >
                  {iconLetters}
                </Box>
              </div>
            </div>
          </nav>
        ) : (
          <IconButton onClick={handleMobileMenuToggle}>
            <MenuIcon fontSize="large" />
          </IconButton>
        )}
      </div>

      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={handleMobileMenuToggle}
      >
        <div style={{ width: "280px", padding: "20px" }}>
          <Autocomplete
            freeSolo
            options={searchOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Search..."
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                      {/* <IconButton onClick={() => toggleFilterDrawer(true)}>
                        <FilterListIcon />
                      </IconButton> */}
                    </>
                  ),
                  style: {
                    height: "50px",
                    width: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "30px",
                  },
                }}
              />
            )}
          />
          <Divider />

          <div style={{display:"flex", flexDirection:"row"}}>
          <IconButton onClick={handleNavigateSettings} color="inherit">
            <Settings sx={{ fontSize: 35, color: theme.palette.info.main }} />
          </IconButton>
          <NotificationBox
            notifications={notifications}
            unreadCount={unreadCount}
            onNotificationClick={handleNotificationPress}
            handleClearAll={handleDeleteAlleNotifications}
            error={notificationError}
          />
          
          <div onClick={handleProfileMenu} color="inherit">
            <div >
              <Box
                sx={{
                  position:"relative",
                  width: 30,
                  height: 30,
                  top:10,
                  borderRadius: '50%',
                  bgcolor: '#007FFF',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '8px',

                  fontSize: 10,
                  '&:hover': {
                    bgcolor: '#0066CC',
                  },
                }}
              >
                {iconLetters}
              </Box>
            </div>
          </div>
          </div>

          <div>
            
            <NavItem
              title="Home"
              mainTab={routers.home}
              icon="fa fa-home"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(0, stateOpen)}
              open={opened[0]}
              isSimpleNav={false}
              onClick={() => handleItemClick(0)}
              active={opened[0]}
            />
            <NavItem
              title="Browse Manuals"
              mainTab={routers.browseManuals}
              icon="fa fa-book"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(1, stateOpen)}
              open={opened[1]}
              isSimpleNav={false}
              onClick={() => handleItemClick(1)}
              active={opened[1]}
            />
            <NavItem
              title="favourites"
              mainTab={routers.favourites}
              icon="fa fa-heart"
              tabs={tabs.Forms}
              setOpenIndex={(stateOpen) => setIndex(2, stateOpen)}
              open={opened[2]}
              onClick={() => handleItemClick(2)}
              active={opened[2]}
            />
            <NavItem
              title="Reports"
              mainTab="/reports"
              icon="fa fa-chart-line"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(3, stateOpen)}
              open={opened[3]}
              onClick={() => handleItemClick(3)}
              active={opened[3]}
            />
            
            <NavItem
              title="Competences"
              mainTab="/competencies"
              icon="fa fa-briefcase"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(5, stateOpen)}
              open={opened[5]}
              isSimpleNav={false}
              onClick={() => handleItemClick(5)}
              active={opened[5]}
            />

            {/* {only for Administrator} */}
            {userRole === 'Administrator' && (
              <div>
                <div>
                  <span style={{ fontSize: "16px", fontWeight: 200, textAlign: "center" }}> Administration</span>
                </div>

                <NavItem
                  title="User Management"
                  mainTab="/userlist"
                  icon="fa fa-users"
                  tabs={{ }}
                  setOpenIndex={(stateOpen) => setIndex(6, stateOpen)}
                  open={opened[6]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(6)}
                  active={opened[6]}
                />
                <NavItem
                  title="Location Management"
                  mainTab={{}}
                  icon="fa fa-map-marker-alt"
                  tabs={{
                    "Locations": routers.locationslist,
                    "Add Locations": routers.addlocations,
                  }}
                  setOpenIndex={(stateOpen) => setIndex(7, stateOpen)}
                  open={opened[7]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(7)}
                  active={opened[7]}
                />
                <NavItem
                  title="Department Management"
                  mainTab={{}}
                  icon="fa fa-building"
                  tabs={{
                    "Departments": routers.departmentlist,
                    "Add Departments": routers.adddepartment,
                  }}
                  setOpenIndex={(stateOpen) => setIndex(8, stateOpen)}
                  open={opened[8]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(8)}
                  active={opened[8]}
                />


                <NavItem
                  title="Policy Management"
                  mainTab={{}}
                  icon="fa fa-bookmark"
                  tabs={{
                    "Policy Library": routers.policyLibrary,
                    "Add Policy": routers.addPolicies,
                    // "Edit Policy":routers.editPolicies,
                    "Policy Types": routers.policytypes,
                    "Add Policy Type": routers.addtype,
                  }}
                  setOpenIndex={(stateOpen) => setIndex(9, stateOpen)}
                  open={opened[9]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(9)}
                  active={opened[9]}
                />
                <NavItem
                  title="Assign Competencies"
                  mainTab={routers.assignCompetencies}
                  icon="fa fa-podcast"
                  tabs={{}}
                  setOpenIndex={(stateOpen) => setIndex(13, stateOpen)}
                  open={opened[13]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(13)}
                  active={opened[13]}
                />
                <NavItem
                  title="Edit Configuration"
                  mainTab={routers.editConfiguration}
                  icon="fa fa-edit"
                  tabs={{}}
                  setOpenIndex={(stateOpen) => setIndex(10, stateOpen)}
                  open={opened[10]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(10)}
                  active={opened[10]}
                />
                <NavItem
                  title="Stay Alert"
                  mainTab={routers.stayAlert}
                  icon="fa fa-exclamation-triangle"
                  tabs={{}}
                  setOpenIndex={(stateOpen) => setIndex(14, stateOpen)}
                  open={opened[14]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(14)}
                  active={opened[14]}
                />
                <NavItem
                  title="Audit"
                  mainTab={routers.audit}
                  icon="fa fa-search-plus"
                  tabs={{}}
                  setOpenIndex={(stateOpen) => setIndex(15, stateOpen)}
                  open={opened[15]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(15)}
                  active={opened[15]}
                />
                <NavItem
                  title="Monitor Process"
                  mainTab={routers.monitorProcess}
                  icon="fa fa-eye"
                  tabs={{}}
                  setOpenIndex={(stateOpen) => setIndex(12, stateOpen)}
                  open={opened[12]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(12)}
                  active={opened[12]}
                />
              </div>
            )}

            {/* {only for Administrator,Director, Senior Leader, Quality Director} */}
            {(userRole === 'Administrator' || userRole === 'Director' || userRole === 'Senior Leader' || userRole === 'Quality Director') && (
              <>
                <div>
                  <span style={{ fontSize: "16px", fontWeight: 200, textAlign: "center" }}> Policy Process</span>
                </div>
                <NavItem
                  title="Approval & Review"
                  mainTab={routers.approvalReview}
                  icon="fa fa-file-pdf"
                  tabs={{}}
                  setOpenIndex={(stateOpen) => setIndex(11, stateOpen)}
                  open={opened[11]}
                  isSimpleNav={false}
                  onClick={() => handleItemClick(11)}
                  active={opened[11]}
                />

              </>)}

          </div>
        </div>
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleCloseProfileMenu}
      >
        <DropdownMenuItem onClick={() => navigate("/profilescreen")}>
          Profile
        </DropdownMenuItem>
        <Divider />
        <DropdownMenuItem onClick={handleLogout}>
          Logout
          </DropdownMenuItem>
      </Menu>

      <AdvanceFilterDrawer filterOpen={filterOpen} setFilterOpen={setFilterOpen} />


    </div>
  );
};

export default Header;
