import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert,
  CircularProgress,
  Tooltip,
  DialogActions,
  ListItemText,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import mammoth from "mammoth";
import ConvertApi from "convertapi-js";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import PolicyEditor from "../../Components/TinyMceEditor/TinyMceEditor";
import CreateQuestion from "../../Components/CustomQandA/CustomQandA";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import { routers } from "../../Configurations/configurationUI";
// import "./addpolicy.css";

const AddPolicies = () => {
  useAuthHeaders();
  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [policyStartDate, setPolicyStartDate] = useState("");
  const [policyExpireDate, setPolicyExpireDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [assesmentInfo, setAssesmentInfo] = useState({});
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [file, setFile] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [assesmentEditor, setAssesmentEditor] = useState(false);
  const [assesmentAIEditor, setAssesmentAIEditor] = useState(false);
  const [documentContent, setDocumentContent] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [aiAssessmentloading, setAiAssessmentLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  const [aiAssessmentData, setAiAssessmentData] = useState([]);  
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log(selectedUsers, "selectedUsers from departments")
  const [userList, setUserList] = useState([]);
  const [openUserDialog, setOpenUserDialog] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_TYPE)
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_TYPE + "/" + selectedType)
      .then((response) => {
        setDepartments(response.data.departments || []);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
        setDepartments([]);
      });
  }, [selectedType]);

  useEffect(() => {
    if (selectedType) {
      axiosInstance.get(ApiUrls.USER_API)
        .then((response) => {
          const processedUserList = response.data.map(user => ({
            ...user,
            defaultSelected: departments.some(dept => dept.department_id === user.department_id)
          })) || [];
          
          setUserList(processedUserList);
          
          const defaultSelectedUsers = processedUserList
            .filter(user => user.defaultSelected)
            .map(user => user.user_id);
          
          setSelectedUsers(defaultSelectedUsers);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
          setUserList([]);
        });
    }
  }, [departments]);


  const handleOpenUserDialog = () => {
    setOpenUserDialog(true);
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    setSelectedUsers([]);
  };

  const handleConfirmUsers = () => {
    setOpenUserDialog(false);
  }


  const handleUserSelect = (userId) => {
    setSelectedUsers((prev) => 
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!policyName) newErrors.policyName = "Policy name is required";
    if (!policyStartDate) newErrors.policyStartDate = "Start date is required";
    if (!policyExpireDate)
      newErrors.policyExpireDate = "Expiration date is required";
    if (!selectedType) newErrors.type = "Please select a Policy Type";
    if (!selectedDepartment)
      newErrors.department = "Please select Primary Department";
    if (!file) newErrors.file = "Please choose a file before submit";
    return newErrors;
  };

  const resetForm = () => {
    setPolicyName("");
    setPolicyDescription("");
    setPolicyStartDate("");
    setPolicyExpireDate("");
    setSelectedDepartment("");
    setSelectedType("");
    setFile(null);
    setShowEditor(false);
    setDocumentContent("");
    setAssesmentEditor(false);
    setAssesmentInfo({});
    setAssesmentAIEditor(false);
    setAiAssessmentLoading(false);
    setSelectedUsers([]);
  };

  const resetaiquestion = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    setAssesmentInfo({});
    setAssesmentAIEditor(false);
    setAiAssessmentLoading(false);
  };

  const handleFileUpload = async (file) => {
    setFile(file);
    setLoading(true);
    const fileExtension = file.name?.split(".").pop().toLowerCase();
    try {
      if (fileExtension === "docx") {
        handleDocxUpload(file);
      } else if (fileExtension === "pdf") {
        handlePdfUpload(file);
      } else if (fileExtension === "txt") {
        handleTxtUpload(file);
      } else {
        setAlert({
          message:
            "Unsupported file format. Please upload a .docx, .pdf, or .txt file.",
          severity: "error",
          show: true,
        });
        setTimeout(
          () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
          3000
        );
      }
    } catch (error) {
      setAlert({
        message:
          "An error occurred while processing the file. Please try again.",
        severity: "error",
        show: true,
      });
      console.error("File processing error:", error);
    } finally {
      setLoading(false);
      setShowEditor(true);
    }
  };

  const handleDocxUpload = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    mammoth
      .convertToHtml({ arrayBuffer })
      .then((result) => {
        const htmlContent = result.value;
        setDocumentContent(htmlContent);
        setShowEditor(true);
      })
      .catch((err) => {
        setAlert({
          message: "Failed to process the word file. Please try another file.",
          severity: "error",
          show: true,
        });
        setTimeout(
          () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
          2000
        );
      });
  };

  const controllerRef = useRef(null);
  const handleAiAssessmentFileUpload = async (file) => {
    setAiAssessmentLoading(true);
    const fileExtension = file.name?.split(".").pop().toLowerCase();

    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      if (
        fileExtension === "docx" ||
        fileExtension === "pdf" ||
        fileExtension === "txt"
      ) {
        // Handle file upload
        setFile(file);

        // Prepare the FormData for the POST request
        const formData = new FormData();
        formData.append("file", file);

        // Make POST request to the AI assessment API endpoint
        const response = await axiosInstance.post(ApiUrls.POLICY_AI, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal: controller.signal,
        });

        console.log("AI assessment response:", response.data);
        setAiAssessmentData(response.data);
        console.log("AI assessment data:", aiAssessmentData);
        setAssesmentAIEditor(true);
        setAlert({
          message: "AI assessment created successfully.",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      } else {
        setAlert({
          message:
            "Unsupported file format. Please upload a .docx, .pdf, or .txt file.",
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      }
    } catch (error) {
      setAlert({
        message:
          "An error occurred while processing the AI assessment. Please try again.",
        severity: "warning",
        show: true,
      });
    } finally {
      setAiAssessmentLoading(false);
    }
  };

  const handlePdfUpload = async (file) => {
    let convertApi = ConvertApi.auth("secret_G1U69MTab18sNFnM");
    let params = convertApi.createParams();
    try {
      params.add("File", file);
      const wordResult = await convertApi.convert("pdf", "docx", params);
      const wordFileUrl = wordResult.files[0].Url;
      const response = await fetch(wordFileUrl);
      const wordBlob = await response.blob();
      const wordFile = new File([wordBlob], "converted.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      console.log(wordFile, "wordFile response is");
      const arrayBuffer = await wordFile.arrayBuffer();
      mammoth
        .convertToHtml({ arrayBuffer })
        .then((result) => {
          const htmlContent = result.value;
          setDocumentContent(htmlContent);
          setShowEditor(true);
        })
        .catch((err) => console.error("Error converting .docx file:", err));
    } catch (error) {
      setAlert({
        message: "Failed to process the PDF file. Please try another file.",
        severity: "error",
        show: true,
      });
      setTimeout(
        () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
        2000
      );
    }
  };

  const handleTxtUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const textContent = reader.result;
      const htmlContent = <p>${textContent.replace(/\n/g, "<br>")}</p>;
      setDocumentContent(htmlContent);
      setShowEditor(true);
    };
    reader.readAsText(file);
  };

  const handleContentChange = (newContent) => {
    setDocumentContent(newContent);
  };

  const convertHtmlToPdf = async (htmlContent) => {
    const convertApi = ConvertApi.auth("secret_G1U69MTab18sNFnM");
    const params = convertApi.createParams();
    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const file = new File([htmlBlob], "document.html", { type: "text/html" });
    try {
      params.add("File", file);
      const result = await convertApi.convert("html", "pdf", params);
      const pdfUrl = result.files[0].Url;
      return pdfUrl;
    } catch (error) {
      console.error("HTML to PDF conversion error:", error);
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    setErrors({});
    setAlert(null);

    const policyPayload = {
      policy_name: policyName,
      policy_description: policyDescription,
      effective_date: policyStartDate,
      expiration_date: policyExpireDate,
      department_id: selectedDepartment,
      type_id: selectedType,
      policy_status: "created",
      created_by: parseInt(sessionStorage.getItem("loggedinUserId")),
      assessment_info: assesmentInfo,
      content: "ContentChanged",
      included_users: JSON.stringify(selectedUsers),
    };
    try {
      const { data: resData } = await axiosInstance.post(
        ApiUrls.POLICY_API,
        policyPayload
      );
      if (!resData.policy_id) {
        throw new Error("Policy creation failed. No policy ID returned.");
      }
      if (documentContent) {
        const htmlContent = documentContent;
        const pdfUrl = await convertHtmlToPdf(htmlContent);
        if (pdfUrl) {
          const pdfFile = await fetch(pdfUrl).then((res) => res.blob());
          const pdfFormData = new FormData();
          const fileStorageInfo = {
            type_id: selectedType,
            department_id: selectedDepartment,
            policy_id: resData.policy_id,
            file_folder: "POLICY_DOCUMENT",
          };
          pdfFormData.append(
            "FileStorageInfo",
            JSON.stringify(fileStorageInfo)
          );
          const customFileName = `${resData.policy_id}.pdf`;
          pdfFormData.append("files", pdfFile, customFileName);
          try {
            const fileStorageResponse = await axiosInstance.post(
              `${ApiUrls.FILESTORAGE_API}/upload`,
              pdfFormData
            );
          } catch (uploadError) {
            console.error("File upload failed:", uploadError);
            throw new Error("File upload failed");
          }
        }
      }
      resetForm();
      setShowEditor(false);
      setAssesmentAIEditor(false);
      setLoading(false);
      setAssesmentInfo({});
      setAlert({
        message: "Policy added successfully.",
        severity: "success",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false })); 
        navigate(routers.monitorProcess);
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        message: "Error while adding Policy. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    }
  };

  const validateDate = (date) => {
    if (!date) {
      return "This field is required";
    }
    return "";
  };

  const styles = {
    container: "mb-6 w-full",
    label: "block text-sm font-medium text-gray-700 mb-1",
    required: "text-red-500 ml-1",
    input: `
      w-full
      px-3
      py-2
      bg-white
      border
      border-gray-300
      rounded-md
      text-sm
      focus:outline-none
      focus:ring-2
      focus:ring-blue-500
      focus:border-blue-500
      disabled:bg-gray-50
      disabled:text-gray-500
      placeholder:text-gray-400
    `,
    error: "mt-1 text-sm text-red-500",
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f6f9",
          padding: "0px 20px",
        }}
      >
        <ol
          style={{
            display: "flex",
            listStyle: "none",
            padding: 0,
            margin: "2px 0 5px -13px",
            fontSize: "14px",
            color: "#6c757d",
          }}
        >
          <li
            style={{
              display: "inline-block",
              marginRight: "8px",
              fontFamily: "Figtree",
            }}
          >
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "#1D3557" }} // Blue color for links
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </Link>
          </li>
          <li style={{ display: "inline-block", marginRight: "8px" }}>/</li>
          <li
            style={{
              display: "inline-block",
              color: "#3f6677",
              fontWeight: "bold",
              fontFamily: "Figtree",
            }}
          >
            Add New Policy
          </li>
        </ol>
        <h4
          style={{
            color: "#3f6677",
            marginBottom: "30px",
            marginLeft: "-13px",
            fontSize: "32px",
            fontWeight: "bold",
            fontFamily: "figtree",
          }}
        >
          Add New Policy
        </h4>
      </div>
      {/* <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: "bold", color: "#333" }}
      >
        {" "}
        Add New Policy{" "}
      </Typography> */}
      <Paper
        sx={{
          borderRadius: "8px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        {alert?.show && (
          <Alert
            variant="filled"
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, show: false })}
            style={{
              position: "fixed",
              top: "25%",
              left: "50%",
              transform: "translateX(-50%)",
              maxWidth: "400px",
              zIndex: 1000,
            }}
          >
            {alert.message}
          </Alert>
        )}
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <TextField
            label="Policy Name"
            variant="outlined"
            value={policyName}
            onChange={(e) => setPolicyName(e.target.value)}
            fullWidth
            required
            error={!!errors.policyName}
            helperText={errors.policyName}
            InputProps={{
              style: {
                height: "40px",
                fontSize: "0.875rem",
                fontFamily: "figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "figtree",
              },
            }}
          />
          <TextField
            label="Policy Description"
            variant="outlined"
            value={policyDescription}
            onChange={(e) => setPolicyDescription(e.target.value)}
            fullWidth
            multiline
            rows={3}
            error={!!errors.policyDescription}
            helperText={errors.policyDescription}
            InputProps={{
              style: {
                height: "80px",
                fontSize: "0.875rem",
                paddingTop: "20px",
                fontFamily: "figtree",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "figtree",
              },
            }}
          />

          <div>
            <Paper
              sx={{
                borderRadius: "5px",
                padding: "20px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                border: "1px solid #b2a8a8",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // gap: "300px",
                  fontFamily: "figtree",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {/* Policy Start Date */}
                <Box sx={{ flex: "1 1 auto" }}>
                  <Typography
                    variant="body2"
                    sx={{ mb: 1, color: "#817b7b", fontFamily: "figtree" }}
                  >
                    Policy Start Date{" "}
                    <span style={{ color: "black", fontFamily: "figtree" }}>
                      *
                    </span>
                  </Typography>
                  <DatePicker
                    selected={policyStartDate}
                    onChange={(date) => {
                      setPolicyStartDate(date);
                      const error = validateDate(date);
                      setErrors((prev) => ({
                        ...prev,
                        policyStartDate: error,
                      }));
                    }}
                    dateFormat="MM/dd/yyyy"
                    minDate={new Date()}
                    maxDate={
                      policyStartDate
                        ? new Date(
                            new Date(policyStartDate).setFullYear(
                              new Date(policyStartDate).getFullYear() + 3
                            )
                          )
                        : undefined
                    }
                    placeholderText="MM/DD/YYYY"
                    required
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    className={`custom-datepicker ${
                      errors.policyStartDate ? "input-error" : ""
                    } date-picker`}
                  />
                  {errors.policyStartDate && (
                    <Typography
                      variant="caption"
                      sx={{ color: "red", mt: 1, display: "block" }}
                    >
                      {errors.policyStartDate}
                    </Typography>
                  )}
                </Box>

                {/* Policy Expiration Date */}
                <Box sx={{ flex: "1 1 auto" }}>
                  <Typography
                    variant="body2"
                    sx={{ mb: 1, color: "#817b7b", fontFamily: "figtree" }}
                  >
                    Policy Expiration Date{" "}
                    <span style={{ color: "black", fontFamily: "figtree" }}>
                      *
                    </span>
                  </Typography>
                  <DatePicker
                    selected={policyExpireDate}
                    onChange={(date) => {
                      setPolicyExpireDate(date);
                      const error = validateDate(date);
                      setErrors((prev) => ({
                        ...prev,
                        policyExpireDate: error,
                      }));
                    }}
                    dateFormat="MM/dd/yyyy"
                    minDate={policyStartDate || new Date()}
                    maxDate={
                      policyStartDate
                        ? new Date(
                            new Date(policyStartDate).setFullYear(
                              new Date(policyStartDate).getFullYear() + 3
                            )
                          )
                        : undefined
                    }
                    placeholderText="MM/DD/YYYY"
                    required
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    className={`custom-datepicker ${
                      errors.policyExpireDate ? "input-error" : ""
                    } date-picker`}
                  />
                  {errors.policyExpireDate && (
                    <Typography
                      variant="caption"
                      sx={{ color: "red", mt: 1, display: "block" }}
                    >
                      {errors.policyExpireDate}
                    </Typography>
                  )}
                </Box>
              </div>
            </Paper>
          </div>

          <FormControl
            fullWidth
            required
            error={!!errors.type}
            sx={{ fontFamily: "figtree" }}
          >
            <InputLabel sx={{ fontSize: "12px", fontFamily: "figtree" }}>
              Select Policy Type
            </InputLabel>
            <Select
              value={selectedType}
              onChange={(e) => {
                handleTypeChange(e);
                setDepartments([]);
                setSelectedDepartment("");
              }}
              label="Select Policy Type"
              sx={{
                fontSize: "12px",
                height: "40px",
                fontFamily: "figtree",
              }}
            >
              {types?.length > 0 ? (
                types?.map((type) => (
                  <MenuItem
                    key={type.policy_type_type_id}
                    value={type.policy_type_type_id}
                    sx={{ fontFamily: "figtree" }}
                  >
                    {type.policy_type_type_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Policy Types available.</MenuItem>
              )}
            </Select>
            {errors.type && (
              <Typography
                color="error"
                variant="caption"
                sx={{ fontFamily: "figtree" }}
              >
                {errors.type}
              </Typography>
            )}
          </FormControl>

          <FormControl
            fullWidth
            required
            error={!!errors.department}
            sx={{ fontFamily: "figtree" }}
          >
            <TextField
              value={
                departments.length > 0
                  ? departments
                      .map((department) => department.department_name)
                      .join(", ")
                  : "No Departments Available "
              }
              label="Departments"
              InputProps={{
                readOnly: true,
                style: {
                  height: "40px",
                  fontSize: "0.875rem",
                  fontFamily: "figtree",
                },
              }}
              // disabled={!selectedType || departments.length === 0}
            />
            {errors.department && (
              <Typography color="error" variant="caption">
                {errors.department}
              </Typography>
            )}

            {selectedType && (
              <div
                style={{ color: "blue", textDecoration: "underline", alignSelf: "flex-end", cursor: "pointer" }}
                onClick={handleOpenUserDialog}
                sx={{
                  padding: "10px 20px",
                  color: "#3f6677",
                  fontFamily: "figtree",
                  borderColor: "#3f6677",
                }}
              >
              Customize Assignment
              </div>
            )}

          </FormControl>

          <FormControl
            fullWidth
            required
            error={!!errors.department}
            sx={{ fontFamily: "figtree" }}
          >
            <InputLabel sx={{ fontSize: "12px", fontFamily: "figtree" }}>
              Primary Department
            </InputLabel>
            <Select
              value={selectedDepartment}
              onChange={(e) => {
                handleDepartmentChange(e);
              }}
              label="Select Primary Department"
              sx={{
                fontSize: "12px",
                height: "40px",
                fontFamily: "figtree",
              }}
            >
              {departments?.length > 0 ? (
                departments?.map((department) => (
                  <MenuItem
                    key={department.department_id}
                    value={department.department_id}
                    sx={{ fontFamily: "figtree" }}
                  >
                    {department.department_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Departments available.</MenuItem>
              )}
            </Select>
            {errors.department && (
              <Typography color="error" variant="caption">
                {errors.department}
              </Typography>
            )}
          
          </FormControl>

          <div style={{ display: "flex", gap: 15 }}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
              sx={{
                alignSelf: "flex-start",
                padding: "10px 40px",
                color: "#3f6677",
                fontFamily: "figtree",
                borderColor: "#3f6677", // Custom border color
                "&:hover": {
                  borderColor: "#3f6677", // Ensure hover state matches
                  backgroundColor: "rgba(63, 102, 119, 0.04)", // Optional hover background
                },
              }}
            >
              <input
                type="file"
                accept=".docx,.pdf,.txt"
                onChange={(e) => handleFileUpload(e.target.files[0])}
                hidden
              />
              Choose a file
            </Button>
            <Button
              variant="outlined"
              component="label"
              startIcon={<NoteAltIcon />}
              sx={{
                alignSelf: "flex-start",
                padding: "10px 40px",
                color: "#3f6677",
                fontFamily: "figtree",
                borderColor: "#3f6677", // Custom border color
                "&:hover": {
                  borderColor: "#3f6677", // Ensure hover state matches
                  backgroundColor: "rgba(63, 102, 119, 0.04)", // Optional hover background
                },
              }}
              // onClick={() => setAssesmentEditor(true)}

              onClick={() => {
                if (file) {
                  setAssesmentEditor(file); // Trigger the upload and handle the response
                } else {
                  setAlert({
                    message: "Please upload a file to Create Assessment.",
                    severity: "warning",
                    show: true,
                  });
                }
              }}
            >
              Create an assessment
            </Button>
            {/* <Button
              variant="outlined"
              component="label"
              startIcon={<SmartToyIcon />}
              sx={{ alignSelf: "flex-start", padding: "10px 40px" }}
              onClick={handleOpenModal}
            >
              Create an AI assessment
            </Button> */}
            <Button
              variant="outlined"
              component="label"
              startIcon={<SmartToyIcon />}
              sx={{
                alignSelf: "flex-start",
                padding: "10px 40px",
                color: "#3f6677",
                fontFamily: "figtree",
                borderColor: "#3f6677",
                "&:hover": {
                  borderColor: "#3f6677",
                  backgroundColor: "rgba(63, 102, 119, 0.04)",
                },
              }}
              onClick={() => {
                // You may want to prompt the user to select a file before proceeding
                if (file) {
                  handleAiAssessmentFileUpload(file); // Trigger the upload and handle the response
                } else {
                  setAlert({
                    message: "Please upload a file to Create AI Assessment.",
                    severity: "warning",
                    show: true,
                  });
                }
              }}
            >
              AI assessment
            </Button>
          </div>
          {errors.file && (
            <Typography variant="caption" color="error">
              {errors.file}
            </Typography>
          )}
          {file && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {" "}
              Selected File: {file.name}{" "}
            </Typography>
          )}
          {!loading && showEditor && (
            <PolicyEditor
              documentContent={documentContent}
              onContentChange={handleContentChange}
              setDocumentContent={setDocumentContent}
            />
          )}
          {assesmentEditor && (
            <CreateQuestion
              setAssesmentInfo={setAssesmentInfo}
              setAssesmentEditor={setAssesmentEditor}
              isAI={false}
            />
          )}

          {assesmentAIEditor && (
            <CreateQuestion
              setAssesmentInfo={setAssesmentInfo}
              setAssesmentEditor={setAssesmentAIEditor}
              aiAssessmentData={aiAssessmentData}
              aiQuestions={aiAssessmentData.length}
              isAI={true}
              regenerate={() => handleAiAssessmentFileUpload(file)}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={resetForm}
              disabled={loading}
              sx={{
                padding: "10px 20px",
                backgroundColor: "#5a8a9a33",
                color: "#1D3557",
                fontFamily: "figtree",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading || aiAssessmentloading}
              sx={{
                padding: "10px 20px",
                backgroundColor: loading || aiAssessmentloading ? "#9e9e9e" : "#3f6677",
                color: "#fff",
                fontFamily: "figtree",
              }}
            >
              Submit
            </Button>
          </div>
          {loading && (
            <CircularProgress
              size={40}
              sx={{
                color: "blue",
                position: "absolute",
                top: "50%",
                left: "55%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          {aiAssessmentloading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "60%",
                transform: "translate(-50%, -50%)",
                width: "240px",
                height: "100px",
                backgroundColor: "white",
                boxShadow: "0 8px 18px rgba(0, 0, 0, 0.1)",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                textAlign: "center",
                padding: "10px",
                // position: "relative",
              }}
            >
              <dotlottie-player
                src="https://lottie.host/89890697-be0e-4b0f-b7cd-3ae63c74b5f9/dUUzuWdOGs.lottie"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></dotlottie-player>
              <Typography
                sx={{
                  fontSize: "14px",
                  marginTop: "-20px",
                  color: "#1D3557",
                  fontFamily: "figtree",
                }}
              >
                AI crafting an Assessment...
              </Typography> 
              <Tooltip title="Cancel AI Assessment">
              <Button
                variant="contained"
                color="primary"
                onClick={resetaiquestion}
                disabled={loading}
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  minWidth: "auto", 
                  padding: "5px",
                  color: "#1D3557",
                  fontSize: "16px",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                }}
              >
                X
              </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Dialog
           open={openUserDialog} 
           onClose={handleCloseUserDialog}
           maxWidth="md"
           fullWidth
         >
           <DialogTitle>Select Users in Department</DialogTitle>
           <DialogContent>
             <List>
               {userList.map((user) => (
                 <ListItem 
                   key={user.user_id} 
                   onClick={() => handleUserSelect(user.user_id)}
                   button
                 >
                   <ListItemIcon>
                     <Checkbox
                       edge="start"
                       checked={selectedUsers.includes(user.user_id)}
                       tabIndex={-1}
                       disableRipple
                     />
                   </ListItemIcon>
                   <ListItemText 
                     primary={`${user.first_name} ${user.last_name}`} 
                     secondary={
                     <>
                      <div>{user.department_name}</div>
                      <div>{user.primary_email}</div>
                    </>
                    }
                   />
                 </ListItem>
               ))}
             </List>
           </DialogContent>
           <DialogActions>
             <Button 
             onClick={handleCloseUserDialog}
              color="primary">
               Cancel
             </Button>
             <Button 
             onClick={handleConfirmUsers}
              color="primary">
               Confirm
             </Button>
           </DialogActions>
         </Dialog>
      </Paper>
      <style jsx>{`
        .custom-datepicker {
          height: 40px;
          padding: 10px; /* Adjusted padding */
          font-size: 12px; /* Improved readability */
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
          width: 360px !important;
        }

        .custom-datepicker input {
          height: 100%;
          padding: 0;
          font-size: inherit;
          border: none;
          outline: none;
        }
      `}</style>
    </div>
  );
};

export default AddPolicies;
