class ApiUrls {

    // static HOST = 'service.rittmans.us'
    static HOST = '65.2.55.122'  
    // static HOST = '192.168.29.39'
    static SERVICE_URL = 'http://'+ this.HOST +':8075'

    static USER_API = "/user" 
    static LOCATION_API = "/location" 
    static DEPARTMENT_API = "/Department"
    static CREATE_USER = "/user/createNew"  
    static ROLE_API = "/role"
    static POLICY_API = "/Policy"
    static FILESTORAGE_API = "/FileStorage" 
    static FILEDOWNLOAD_API = "FileStorage/download/file/"
    static POLICY_TYPE = "/PolicyType" 
    static FAVOURITE_API = "/Favourite" 
    static SEARCH_API = "/Assignment/advance/search" 
    static ASSIGNMENT_API = "/Assignment"
    static NOTIFICATION_API = "/notify"
    static AUDIT_API = "/audit"
    static Organization_API = "/organization" 
    static TYPE_API = "/type" 
    static POLICY_AI= "/policy-ai/upload-policy"
    static POLICY_VERSION_API_AI= "/policy-ai/version/upload/ai/questions"
    
} 
export default ApiUrls;