import React, { useEffect } from "react";
import { routers, tabs } from "../../Configurations/configurationUI";
import NavItem from "../NavigationBar/NavItem";
import { useNavigate, useLocation } from "react-router-dom";


export default function Navigation(props) {
  const [opened, setOpened] = React.useState(Array(10).fill(false));
  const [activeItems, setActiveItems] = React.useState(Array(7).fill(false));
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  

  const userRole = sessionStorage.getItem("loggedinUserRole");
  const navigate = useNavigate();
  const location = useLocation();


  const setIndex = (index, stateOpen) => {
    const newOpened = [...opened];
    newOpened[index] = stateOpen;
    setOpened(newOpened);
  };

  const handleItemClick = (index) => {
    const newActiveItems = Array(10).fill(false);
    newActiveItems[index] = true;
    setActiveItems(newActiveItems);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleBack = () => {
    navigate(-1);
    setIsSidebarOpen(true);
  };

  const styles = {
    sidebar: {
      width: isSidebarOpen ? "285px" : "25px",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between", 
      padding: "5px ", 
      paddingLeft: "90px",
      // paddingright:"0px",
      fontSize: "15px",
      // fontWeight:"bold",
      color: "#1D3557",
      fontFamily:"figtree",
      borderBottom: isSidebarOpen ? "1px solid #dee2e6" : 0,
      borderRight:  isSidebarOpen ? "1px solid #dee2e6" :0,
    }, 
    
    admin: {  
      alignItems: "center",
      padding: "10px 5px 10px 5px",
      color: "#1D3557",
      width:"256px",
      fontSize: "13px",
      fontFamily:"figtree",
 
    },
    closeButton: {
      position: "relative",
      right: isSidebarOpen ? "-28px" : "82px",
      width: isSidebarOpen ? "40px" : "260px",
      height: isSidebarOpen ? "40px" : "40px",
      borderRadius: isSidebarOpen ? "50%" : "0%",
      backgroundColor:  isSidebarOpen ? "#ffffff": "white",
      boxShadow: isSidebarOpen ? "0 0 5px rgba(0,0,0,0.2)" : "",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      border:  isSidebarOpen ? "1px solid #dee2e6": "2px solid #dee2e6",
      zIndex: 1,
    },

    menu: {
      display: isSidebarOpen ? "flex" : "none",
      flexDirection: "column",
      justifyContent:"space-between",
      padding: "6px",
      overflowY: "auto",
      maxHeight: "calc(100vh - 125px)",  
    },
  };

  return (
    <div style={styles.sidebar}>
      <div style={styles.header}>
       
        {isSidebarOpen && <span>Main Menu</span>}

        <div style={styles.closeButton} onClick={toggleSidebar}>
        {isSidebarOpen ? (
            <i className="fa fa-chevron-left" />
          ) : (
            <i className="fa fa-chevron-right" />
          )}
        </div>
      </div>

      <div style={styles.menu}>
        {/* {common section} */}
        <NavItem   
          icon="fa fa-home"
          title="Home"
          mainTab={routers.home}
          tabs={{}}
          setOpenIndex={(stateOpen) => setIndex(0, stateOpen)}
          open={opened[0]}
          isSimpleNav={false}
          onClick={() => handleItemClick(0)}
          active={opened[0]}
        />
        <NavItem
          title="Browse Manuals"
          mainTab={routers.browseManuals}
          icon="fa fa-book"
          tabs={{}}
          setOpenIndex={(stateOpen) => setIndex(1, stateOpen)}
          open={opened[1]}
          isSimpleNav={false}
          onClick={() => handleItemClick(1)}
          active={opened[1]}
        />
        <NavItem
          title="Favourites"
          mainTab={routers.favourites}
          icon="fa fa-heart"
          tabs={tabs.Forms}
          setOpenIndex={(stateOpen) => setIndex(2, stateOpen)}
          open={opened[2]}
          onClick={() => handleItemClick(2)}
          active={opened[2]}
        />
        <NavItem
          title="Competences"
          mainTab="/competencies"
          icon="fa fa-briefcase"
          tabs={{}}
          setOpenIndex={(stateOpen) => setIndex(3, stateOpen)}
          open={opened[3]}
          isSimpleNav={false}
          onClick={() => handleItemClick(3)}
          active={opened[3]}
        />

        {/* {only for Administrator} */}
        {userRole === "Administrator" && (
          <div>
            <div style={styles.admin}>
              <span
                style={{
                  fontSize: "13px",

                }}
              >
                {" "}
              
                ADMINISTRATION
              </span>
            </div>

            <NavItem
              title="Policy Management"
              mainTab={{}}
              icon="fa fa-bookmark"
              tabs={{
                "Policy Library": routers.policyLibrary,
                "Add Policy": routers.addPolicies,
                // "Edit Policy":routers.editPolicies,
                "Policy Types": routers.policytypes,
                "Add Policy Type": routers.addtype,
              }}
              setOpenIndex={(stateOpen) => setIndex(4, stateOpen)}
              open={opened[4]}
              isSimpleNav={false}
              onClick={() => handleItemClick(4)}
              active={opened[4]}
            />

            <NavItem
              title="Assign Policies"
              mainTab={routers.assignCompetencies}
              icon="fa fa-podcast"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(5, stateOpen)}
              open={opened[5]}
              isSimpleNav={false}
              onClick={() => handleItemClick(5)}
              active={opened[5]}
            />

            <NavItem
              title="Reports"
              mainTab="/reports"
              icon="fa fa-chart-line"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(6, stateOpen)}
              open={opened[6]}
              onClick={() => handleItemClick(6)}
              active={opened[6]}
            />

           

            <NavItem
              title="User Management"
              mainTab="/userlist"
              icon="fa fa-users"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(8, stateOpen)}
              open={opened[8]}
              isSimpleNav={false}
              onClick={() => handleItemClick(8)}
              active={opened[8]}
            />
            <NavItem
              title="Location Management"
              mainTab="/locationslist"
              icon="fa fa-map-marker-alt"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(9, stateOpen)}
              open={opened[9]}
              isSimpleNav={false}
              onClick={() => handleItemClick(9)}
              active={opened[9]}
            />
            <NavItem
              title="Department Management"
              mainTab="/departmentlist"
              icon="fa fa-building"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(10, stateOpen)}
              open={opened[10]}
              isSimpleNav={false}
              onClick={() => handleItemClick(10)}
              active={opened[10]}
            />

            <NavItem
              title="Manage Organization"
              mainTab={routers.editConfiguration}
              icon="fa fa-edit"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(11, stateOpen)}
              open={opened[11]}
              isSimpleNav={false}
              onClick={() => handleItemClick(11)}
              active={opened[11]}
            />
            <NavItem
              title="Stay Alert"
              mainTab={routers.stayAlert}
              icon="fa fa-exclamation-triangle"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(12, stateOpen)}
              open={opened[12]}
              isSimpleNav={false}
              onClick={() => handleItemClick(12)}
              active={opened[12]}
            />
            <NavItem
              title="Audit"
              mainTab={routers.audit}
              icon="fa fa-search-plus"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(13, stateOpen)}
              open={opened[13]}
              isSimpleNav={false}
              onClick={() => handleItemClick(13)}
              active={opened[13]}
            />
          </div>
        )}

     
        
        {(userRole === "Administrator" ||
          userRole === "Director" ||
          userRole === "Senior Leader" ||
          userRole === "Quality Director") && (
          <>
            <div style={styles.admin}>
              <span
                style={{
                  fontSize: "13px",
                }}
              >
                {" "}
                POLICY PROCESS
              </span>
            </div>
            <NavItem
              title="Review & Approval"
              mainTab={routers.approvalReview}
              icon="fa fa-file-pdf"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(14, stateOpen)}
              open={opened[14]}
              isSimpleNav={false}
              onClick={() => handleItemClick(14)}
              active={opened[14]}
            /> 
            <NavItem
              title="Monitor Process"
              mainTab={routers.monitorProcess}
              icon="fa fa-eye"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(7, stateOpen)}
              open={opened[7]}
              isSimpleNav={false}
              onClick={() => handleItemClick(7)}
              active={opened[7]}
            />
          </>
        )} 
        
      </div>
    </div>
  );
}
